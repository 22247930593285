import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { LayoutStructure } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private isSmallDevice = false;
  private isMediumDevice = false;
  constructor(private breakpointObserver: BreakpointObserver) {}

  /**
   * observes the current windowWidth and sets dynamicly the current layoutType
   * @param small  600px  ~ 959.99px  -> portraitmode on Ipad
   * @param medium 960px  ~ 1279.99px -> Ipad Landscape && Ipad Portrait
   */
  setCurrentLayout(small: string, medium: string): void {
    this.breakpointObserver.observe([small, medium]).subscribe((state: BreakpointState) => {
      this.isSmallDevice = state.breakpoints[small];
      this.isMediumDevice = state.breakpoints[medium];
    });
  }

  /**
   *
   * gets dynamic the deviceMode depending on the current window width
   * @returns {string}
   */
  getCurrentLayout = (): LayoutStructure =>
    this.isSmallDevice
      ? LayoutStructure.Small
      : this.isMediumDevice
      ? LayoutStructure.Medium
      : LayoutStructure.Large;
}
