import { Injectable } from '@angular/core';
import { Box3, Vector3 } from 'three';
import { Edge } from '../models/edge';
import { Mark } from '../models/mark';
import { EdgeService } from './edge.service';
import { MarkService } from './mark.service';
import { SceneControlService } from './scene-control.service';
import { calcDirectionVector } from './service-helper/mark.helper';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class AutoLogicService {
  spa = this.markService.marks[4];
  spp = this.markService.marks[5];
  n = this.markService.marks[3];
  me = this.markService.marks[9];
  sp = this.markService.marks[6];
  ar = this.markService.marks[8];
  pog = this.markService.marks[10];
  tgp = this.markService.marks[12];
  tga = this.markService.marks[13];
  go = this.markService.marks[14];
  goa = this.markService.marks[15];
  helper = this.markService.marks[26];
  vpocp = this.markService.marks[20];
  lnok1 = this.markService.marks[16];
  lnuk1 = this.markService.marks[18];
  autoMarks = [this.sp, this.go, this.vpocp];

  spFamily = [this.spp, this.spa, this.n, this.me, this.sp];
  goFamily = [this.me, this.tga, this.tgp, this.ar, this.go, this.helper];
  vpocpFamily = [this.vpocp, this.lnok1, this.lnuk1];

  constructor(
    public edgeService: EdgeService,
    public markService: MarkService,
    public sceneControlService: SceneControlService,
    public sharedService: SharedService,
  ) {}

  /**
   * checks conditions for setting auto mark 'sp' and sets it
   */
  runSpRoutine(): void {
    // remove previously set automark sp
    this.markService.unsetMarkInMarks(this.sp);
    this.edgeService.unsetMarkInEdges(this.sp);

    // set sp
    if (this.spa.set && this.spp.set && this.n.set && this.me.set) {
      const spPos = this.edgeService.getIntersectionOfEdges(
        this.edgeService.edges[10],
        this.edgeService.edges[3],
      );
      this.drawAutoMark(this.sp, spPos);
    }
  }

  /**
   * checks conditions for setting auto mark 'go' and sets it
   */
  runGoRoutine(): void {
    // remove previously set automark go and helper
    this.markService.unsetMarkInMarks(this.go);
    this.edgeService.unsetMarkInEdges(this.go);
    this.markService.unsetMarkInMarks(this.helper);
    this.edgeService.unsetMarkInEdges(this.helper);

    // set go
    if (this.ar.set && this.tgp.set && this.me.set && this.tga.set) {
      const arTgp = new Edge('ar-tgp', null, this.ar, this.tgp);
      const meTga = new Edge('me-tga', null, this.me, this.tga);
      const goPos = this.edgeService.getIntersectionOfEdges(arTgp, meTga);
      this.drawAutoMark(this.go, goPos);
    }

    // set helper mark for bisector angle
    if (this.go.set) {
      const directionVector = this.calcBisectorVector();
      const scaledVector = this.scaleVectorToSprite(directionVector);
      this.drawAutoMark(this.helper, scaledVector);
    }
  }

  /**
   * checks conditions for setting auto mark 'vpocp' and sets it
   */
  runVpocpRoutine(): void {
    // remove previously set automark vpocp
    this.markService.unsetMarkInMarks(this.vpocp);
    this.edgeService.unsetMarkInEdges(this.vpocp);

    // set vpocp
    if (this.lnuk1.set && this.lnok1.set) {
      const pos = new Vector3(
        (this.lnok1.position.x + this.lnuk1.position.x) / 2,
        (this.lnok1.position.y + this.lnuk1.position.y) / 2,
        0,
      );
      this.drawAutoMark(this.vpocp, pos);
    }
  }

  /**
   *
   * @param mark the auto mark to be drawn
   * @param position the position of the auto mark
   */
  private drawAutoMark(mark: Mark, position: Vector3) {
    const labelVisible = this.sharedService.currentMarkEvent.getValue() == mark; // show label of the automark if it is the selected mark

    // draw the mark and set it
    this.markService.drawMark(mark, position, true, labelVisible);
    // update mark in the array
    const newMark = this.markService.setMarkInMarks(mark, position);
    // set the mark in the edge array
    this.edgeService.setMarkInEdges(newMark);
    // find the second mark to draw the edge
    const partnerMarks = this.edgeService.findPartnerMarks(newMark);

    if (partnerMarks != undefined) {
      partnerMarks.forEach((partnerMark) => {
        // get the corresponding edge
        const edge = this.edgeService.getEdge(newMark, partnerMark);
        // draw the edge
        this.edgeService.drawEdge(edge);
        // set the edge in the edge array
        this.edgeService.setEdgeInEdges(edge);
      });
    }
  }

  /**
   * checks if the given mark is related to the auto mark 'sp'
   * @param mark the mark to be checked
   */
  isRelatedToSp(mark: Mark): boolean {
    return this.spFamily.some((m) => mark.id == m.id);
  }

  /**
   * checks if the given mark is related to the auto mark 'go'
   * @param mark the mark to be checked
   */
  isRelatedToGo(mark: Mark): boolean {
    return this.goFamily.some((m) => mark.id == m.id);
  }

  /**
   * checks if the given mark is related to the auto mark 'vpocp'
   * @param mark the mark to be checked
   */
  isRelatedToVpocp(mark: Mark): boolean {
    return this.vpocpFamily.some((m) => mark.id == m.id);
  }

  /**
   * checks if the given mark is an auto mark (a mark that is set automatically)
   * @param mark the mark to be checked
   */
  isAutoMark(mark: Mark): boolean {
    return this.autoMarks.some((m) => mark.id == m.id);
  }

  /**
   * calculates the vector for the bisector angle
   * @returns {Vector3} the vector
   */
  calcBisectorVector(): Vector3 {
    if (this.go.position === null || this.tgp.position === null || this.tga.position === null)
      throw new Error('One or more of the required mark positions is null');
    // get the x and y coordinates from the 3 marks (location vectors)
    const intersectionMarkX = this.go.position.x;
    const intersectionMarkY = this.go.position.y;

    // first, calculate the direction vectors
    const goTgpDirVec = calcDirectionVector(this.go, this.tgp);
    const goTgaDirVec = calcDirectionVector(this.go, this.tga);

    const directionVector1X = goTgpDirVec.x;
    const directionVector1Y = goTgpDirVec.y;
    const directionVector2X = goTgaDirVec.x;
    const directionVector2Y = goTgaDirVec.y;

    // calculate the unit vectors (the amount of a vector)
    const unitVector1 = Math.sqrt(
      directionVector1X * directionVector1X + directionVector1Y * directionVector1Y,
    );
    const unitVector2 = Math.sqrt(
      directionVector2X * directionVector2X + directionVector2Y * directionVector2Y,
    );

    // calculate the new direction vector, which represent the bisector angle
    const newDirectionVectorX =
      (1 / unitVector1) * directionVector1X + (1 / unitVector2) * directionVector2X;
    const newDirectionVectorY =
      (1 / unitVector1) * directionVector1Y + (1 / unitVector2) * directionVector2Y;

    // converted direction vector formular to calculate the location vector
    const newVectorX = newDirectionVectorX + intersectionMarkX;
    const newVectorY = newDirectionVectorY + intersectionMarkY;

    return new Vector3(newVectorX, newVectorY, 0);
  }

  /**
   * scale a vector to fit the sprite
   * @param {Vector3} vec the vector which should be scaled
   * @returns {Vector3} the scaled vector
   */
  scaleVectorToSprite(vec: Vector3): Vector3 {
    // get the sprite from scene to idenify the maximum value on the x-axis
    const sprite = this.sceneControlService.threeObjects.getValue().scene.getObjectByName('sprite');
    const boundingBox = new Box3().setFromObject(sprite);

    // calculate the slope for the bisector angle
    const slope = this.markService.getSlopeOfTwoVectors(this.go, vec);

    // scale the x and y coordinates
    const x = boundingBox.max.x / 3;
    const y = vec.y - slope * (vec.x - x);

    return new Vector3(x, y, 0);
  }

  calculateAngleHelperGoaPog(): number {
    if (this.helper.set && this.goa.set && this.pog.set) {
      return this.markService.getAngleBetweenVectors(this.helper, this.pog, this.goa);
    }
  }
}
