import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-waiting-spinner',
  templateUrl: './waiting-spinner.component.html',
  styleUrls: ['./waiting-spinner.component.sass'],
})
export class WaitingSpinnerComponent implements OnChanges, OnInit {
  @Input()
  public showWaitingSpinner = false;
  @Input()
  public overlayFullPage = true;

  @HostBinding('style.display')
  display = 'none';
  @HostBinding('style.position')
  position = 'inherit';
  @HostBinding('style.width')
  width = 'inherit';
  @HostBinding('style.height')
  height = 'inherit';

  constructor() {}

  ngOnInit() {
    if (this.overlayFullPage) {
      this.position = 'inherit';
      this.width = 'inherit';
      this.height = 'inherit';
    } else {
      this.position = 'absolute';
      this.width = '100%';
      this.height = '100%';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showWaitingSpinner && changes.showWaitingSpinner.currentValue) {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }
}
