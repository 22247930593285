import { Store } from 'src/app/core';
import { ValueGroups } from './ValueGroups';

export interface DisciplineEdge {
  _id: string;
  technicalId: string;
  disciplineLandmarks: [string, string];
  group: ValueGroups;
  description?: string;
  length?: number;
  nameAttachement?: string;
  pdfName?: string;
}

export function isDisciplineEdge(arg: any): arg is DisciplineEdge {
  return arg.disciplineLandmarks !== undefined;
}

export class DisciplineGroup {
  technicalId: ValueGroups;
  expanded: boolean;
  extendable: boolean;
  parent: DisciplineGroup;
  associatedEdges?: Store<string>;
  count?: number;

  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

export function isDisciplineGroup(arg: any): arg is DisciplineGroup {
  return arg.extendable !== undefined;
}

export type DisplayedEdge = DisciplineGroup | DisciplineEdge;

/**
 * Reads an unique identifier from a DisciplineEdge
 * @param edge
 */
export const readNameId = (edge: DisciplineEdge): string => {
  return edge.nameAttachement === undefined
    ? edge.technicalId
    : `${edge.technicalId} ${edge.nameAttachement}`;
};
