import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { AnalysisRoutingModule } from './analysis-routing.module';
import { Analyzer2dModule } from '../analyzer2d';
import { Analyzer3dModule } from '../analyzer3d';
import { ToolTabsComponent } from './components';
import { AnalysisComponent } from './analysis.component';
import { OptionButtonGroupComponent } from './components/option-button-group/option-button-group.component';

@NgModule({
  declarations: [AnalysisComponent, ToolTabsComponent, OptionButtonGroupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    AnalysisRoutingModule,
    Analyzer2dModule,
    Analyzer3dModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonToggleModule,
    TranslateModule,
  ],
  exports: [],
})
export class AnalysisModule {}
