import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  newFileOption,
  saveOption,
  evaluateOption,
  landmarks,
  levels,
  upperJaw,
  showBoth,
  lowerJaw,
  calculations,
} from '../models';

@Injectable({ providedIn: 'root' })
export class ButtonService {
  private evaluationSource = new Subject<string>();
  private saveSource = new Subject<string>();
  private newFilesource = new Subject<string>();
  private landmarksSource = new Subject<string>();
  private levelsSource = new Subject<string>();
  private calculationsSource = new Subject<string>();
  private upperJawSource = new Subject<string>();
  private lowerJawSource = new Subject<string>();
  private showBothSource = new Subject<string>();

  analysisState = new Subject<string>();

  // testing assumes that only observable names start with on
  // and that each id corresponds to another observable;
  onEvaluation = this.evaluationSource.asObservable();
  onSave = this.saveSource.asObservable();
  onNewFile = this.newFilesource.asObservable();
  onLandmark = this.landmarksSource.asObservable();
  onLevels = this.levelsSource.asObservable();
  onCalculations = this.calculationsSource.asObservable();
  onUpperJaw = this.upperJawSource.asObservable();
  onlowerJaw = this.lowerJawSource.asObservable();
  onShowBoth = this.showBothSource.asObservable();

  /**
   * calls corresponding .next for the Subjects
   * @param key key to identify correct Subject
   */
  nextClick(key: string) {
    switch (key) {
      case newFileOption.key:
        this.newFilesource.next();
        break;
      case saveOption.key:
        this.saveSource.next();
        break;
      case evaluateOption.key:
        this.evaluationSource.next();
        break;
      case landmarks.key:
        this.landmarksSource.next(key);
        break;
      case levels.key:
        this.levelsSource.next(key);
        break;
      case calculations.key:
        this.calculationsSource.next(key);
        break;
      case upperJaw.key:
        this.upperJawSource.next();
        break;
      case lowerJaw.key:
        this.lowerJawSource.next();
        break;
      case showBoth.key:
        this.showBothSource.next();
        break;
    }
  }
}
