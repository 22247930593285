import { Injectable } from '@angular/core';
import { PDFNameMap, PdfWriteService } from 'src/app/shared/service/pdf-write.service';
import { Calculation, Gender } from '../models/calculation';
import { frsOptions } from '../../../shared/service/pdf-write.service';
import { TranslateService } from '@ngx-translate/core';
import { fieldNameMap, formatDate } from './service-helper/calculation.helper';
import { StaticDataService } from './static-data.service';
import { MarkService } from './mark.service';
import { EdgeService } from './edge.service';
import { Mark } from '../models/mark';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * the whole functionality for calculations
 * @Injectable in root
 */
@Injectable({
  providedIn: 'root',
})
export class CalculationService {
  calculations: Array<Calculation> = null;
  calibration0 = this.markService.marks[0];
  calibration1 = this.markService.marks[1];
  s = this.markService.marks[2];
  n = this.markService.marks[3];
  spa = this.markService.marks[4];
  spp = this.markService.marks[5];
  sp = this.markService.marks[6];
  a = this.markService.marks[7];
  ar = this.markService.marks[8];
  me = this.markService.marks[9];
  pog = this.markService.marks[10];
  b = this.markService.marks[11];
  tgp = this.markService.marks[12];
  tga = this.markService.marks[13];
  go = this.markService.marks[14];
  goa = this.markService.marks[15];
  inOK1 = this.markService.marks[16];
  apOK1 = this.markService.marks[17];
  inUK1 = this.markService.marks[18];
  apUK1 = this.markService.marks[19];
  vPOcP = this.markService.marks[20];
  hPOcP = this.markService.marks[21];
  ls = this.markService.marks[22];
  li = this.markService.marks[23];
  proN = this.markService.marks[24];
  softPog = this.markService.marks[25];

  /**
   * constructor
   * @param staticDataService the service object to get all the inital calculationd (angles)
   * @param pdfWriteService creates the pdf on button click
   * @param translateService
   * @param snackBar
   * @param markService
   * @param edgeService
   */
  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly pdfWriteService: PdfWriteService,
    private readonly translateService: TranslateService,
    private snackBar: MatSnackBar,
    public markService: MarkService,
    public edgeService: EdgeService,
  ) {
    this.calculations = staticDataService.getCalculation();
  }

  /**
   * evaluates the angles and distances for the pdf and the calculation tab of the toolbar
   */
  evaluate() {
    if (!this.calibration0.set && !this.calibration1.set) {
      let errorMessageTranslated = '';
      this.translateService.get('EVALUATION.SNACKBAR').subscribe((text: string) => {
        errorMessageTranslated = text;
      });
      this.snackBar.open(errorMessageTranslated, '', {
        duration: 10000,
        panelClass: 'dent-snackBar',
      });
    }
    // Vertikale Strukturmerkmale
    // angleSN_SpP
    const angleSN_SpP = this.calculateAngleBetweenTwoIntersectionLines(
      this.s,
      this.n,
      this.spp,
      this.spa,
      true,
      7,
    );
    // angleSpP_MeGo
    const angleSpP_MeGo = this.calculateAngleBetweenTwoIntersectionLines(
      this.spp,
      this.spa,
      this.me,
      this.go,
      true,
      8,
    );
    // angleSN_MeGO
    const angleSN_MeGO = this.calcSnMeGo(angleSN_SpP, angleSpP_MeGo);
    // quotientSGo_NMe
    this.calculateQuotientBetween(this.s, this.go, this.n, this.me, 9);
    // angleArGo_Me
    this.calculateAngleBetweenTwoIntersectionLines(this.ar, this.go, this.me, null, false, 10);
    // quotientNSp_SpMe
    this.calculateQuotientBetween(this.n, this.sp, this.sp, this.me, 11);

    // Sagitale Strukturmerkmale
    // angleSNA
    const angleSNA = this.calculateAngleBetweenTwoIntersectionLines(
      this.s,
      this.n,
      this.a,
      null,
      false,
      0,
    );
    // angleSNB
    this.calculateAngleBetweenTwoIntersectionLines(this.s, this.n, this.b, null, false, 1);
    // angleSNPog
    this.calculateAngleBetweenTwoIntersectionLines(this.s, this.n, this.pog, null, false, 2);
    // angleANB
    const angleANB = this.calculateAngleBetweenTwoIntersectionLines(
      this.a,
      this.n,
      this.b,
      null,
      true,
      3,
    );
    // angleANBindividuel
    this.setANBindividual(angleSNA, angleSN_MeGO, angleANB);
    // Wits
    this.calcWits(this.a, this.b, this.hPOcP, this.vPOcP);

    // Denitition
    // angleOK1_SN
    this.calculateAngleBetweenTwoIntersectionLines(
      this.inOK1,
      this.apOK1,
      this.s,
      this.n,
      true,
      12,
    );
    // angleOK1_SpP
    this.calculateAngleBetweenTwoIntersectionLines(
      this.inOK1,
      this.apOK1,
      this.spp,
      this.spa,
      false,
      13,
    );
    // angleUK1_MeGo
    this.calculateAngleBetweenTwoIntersectionLines(
      this.inUK1,
      this.apUK1,
      this.me,
      this.go,
      false,
      14,
    );
    // angleInterincisalw
    this.calculateAngleBetweenTwoIntersectionLines(
      this.inOK1,
      this.apOK1,
      this.inUK1,
      this.apUK1,
      true,
      15,
    );
    // disOK1_NA
    this.calculateDistanceToStraith(this.inOK1, this.n, this.a, 16);
    // disUK1_NB
    this.calculateDistanceToStraith(this.inUK1, this.n, this.b, 17);

    // längenrelationen Kieferbasen
    // quotientSppA_SN
    this.calculateQuotientBetween(this.spp, this.a, this.s, this.n, 18);
    // quotientGoaPog_SN
    this.calculateQuotientBetween(this.goa, this.pog, this.s, this.n, 19);
    // quotientSppA_GoaPog
    this.calculateQuotientBetween(this.spp, this.a, this.goa, this.pog, 20);

    // Profil - esthetic line
    // disLs_SoftpogPron
    this.calculateDistanceToStraith(this.ls, this.softPog, this.proN, 21);
    // disLi_SoftpogPron
    this.calculateDistanceToStraith(this.li, this.softPog, this.proN, 22);
  }

  private calcSnMeGo(angleSN_SpP: number, angleSpP_MeGo: number) {
    if (angleSN_SpP && angleSpP_MeGo) {
      const sum = angleSN_SpP + angleSpP_MeGo;
      this.setActualValueofCalculation(6, sum);
      return sum;
    }
  }

  private calcWits(a: Mark, b: Mark, hPOcP: Mark, vPOcP: Mark) {
    if (a.set && b.set && hPOcP.set && vPOcP.set) {
      const disWits = this.getIntersectionPointOfLine(a, hPOcP, vPOcP).distanceTo(
        this.getIntersectionPointOfLine(b, hPOcP, vPOcP),
      );
      this.setActualValueofCalculation(5, disWits);
    }
  }

  private setANBindividual(angleSNA: number, angleSN_MeGO: number, actualValue: number) {
    let angleANBindividuel = -35.16 + 0.4 * angleSNA + +0.2 * angleSN_MeGO;
    this.setActualValueofCalculation(4, actualValue);
    this.setTargetValueofCalculation(4, angleANBindividuel);
    return angleANBindividuel;
  }

  private setActualValueofCalculation(calculationPosistion: number, actualValue: number) {
    if (actualValue) {
      this.calculations[calculationPosistion].actualValue = actualValue;
    }
  }

  private setTargetValueofCalculation(calculationPosistion: number, targetValue: number) {
    if (targetValue) {
      this.calculations[calculationPosistion].targetValue = targetValue;
    }
  }

  /**
   * calculates the angle between 3 or 4 points
   * @param pointa
   * @param pointb
   * @param pointc
   * @param pointd
   * @param left
   * @param calculationPosistion
   */
  calculateAngleBetweenTwoIntersectionLines(
    pointa: Mark,
    pointb: Mark,
    pointc: Mark,
    pointd: Mark,
    left: boolean,
    calculationPosistion: number,
  ) {
    let angle: number;
    if (pointa.set && pointb.set && pointc.set && pointd == null) {
      angle = this.markService.getAngleBetweenVectors(pointa, pointc, pointb);
    } else if (pointa.set && pointb.set && pointc.set && pointd.set && !left) {
      const intersection = this.markService.getIntersectionOfMarks(pointa, pointb, pointc, pointd);
      angle = this.markService.getAngleBetweenVectors(pointb, pointd, intersection);
    } else if (pointa.set && pointb.set && pointc.set && pointd.set && left) {
      const intersection = this.markService.getIntersectionOfMarks(pointa, pointb, pointc, pointd);
      angle = this.markService.getAngleBetweenVectors(pointb, pointc, intersection);
    }
    this.setActualValueofCalculation(calculationPosistion, angle);
    return angle;
  }

  /**
   * calculates the quotient of two distances
   * @param pointa
   * @param pointb
   * @param pointc
   * @param pointd
   * @param calculationPosition
   */
  calculateQuotientBetween(
    pointa: Mark,
    pointb: Mark,
    pointc: Mark,
    pointd: Mark,
    calculationPosition: number,
  ) {
    let quotient: number;
    if (pointa.set && pointb.set && pointc.set && pointd.set) {
      quotient =
        (this.edgeService.getDistanceBetweenInMm(pointa, pointb) /
          this.edgeService.getDistanceBetweenInMm(pointc, pointd)) *
        100;
    }
    this.setActualValueofCalculation(calculationPosition, quotient);
    return quotient;
  }

  /**
   * calculates the distance to straith
   * @param point: point with shortest distance to line
   * @param linePointa: first point of line
   * @param linePointb: second point of line
   * @param calculationPosition
   */
  calculateDistanceToStraith(
    point: Mark,
    linePointa: Mark,
    linePointb: Mark,
    calculationPosition: number,
  ) {
    let dis: number;

    let intersectionOfMarks = this.getIntersectionPointOfLine(linePointa, linePointb, point);
    if (intersectionOfMarks != undefined && point.position != undefined) {
      dis = this.edgeService.calibrateDistanceFromPixelToMm(
        intersectionOfMarks.distanceTo(point.position),
      );
    }

    this.setActualValueofCalculation(calculationPosition, dis);
    return dis;
  }

  private getIntersectionPointOfLine(linePointa: Mark, linePointb: Mark, point: Mark) {
    if (point.set && linePointa.set && linePointb.set) {
      let orthogonalslope = this.markService.getOrthogonalSlope(linePointa, linePointb);
      let vector3 = this.markService.calcOrthogonalPartnerMark(
        point,
        orthogonalslope,
        linePointb.position.x,
      );
      return this.markService.getIntersectionOfMarks(point, vector3, linePointa, linePointb);
    }
  }

  /**
   * prints the pdf and fills the fields of the pdf automatically
   */
  async printPdf(): Promise<void> {
    // collection for printing data
    const pdfEntires = new Array<PDFNameMap>();

    // get the date for printing
    pdfEntires.push({
      identifier: 'date',
      fieldName: 'Auswertung FRS vom',
      text: formatDate(),
    });

    // TODO: get handler, get birthdate, get patient

    for (const calc of this.calculations) {
      if (!calc.actualValue) continue;

      const identifierActualValue = `${calc.id}-A`;
      const identifierTargetValue = `${calc.id}-T`;
      const identifierInterpretation = `${calc.id}-I`;

      const correctInterpretation = this.getCorrectInterpretation(calc);

      const { interpretation, actualValue, targetValue } = fieldNameMap.get(calc.id);

      pdfEntires.push({
        identifier: identifierActualValue,
        fieldName: actualValue,
        text: `${calc.actualValue.toFixed(1)}${calc.unit}`,
      });
      pdfEntires.push({
        identifier: identifierInterpretation,
        fieldName: interpretation,
        text: correctInterpretation,
      });

      if (targetValue) {
        pdfEntires.push({
          identifier: identifierTargetValue,
          fieldName: targetValue,
          text:
            `${(calc.targetValue as number).toFixed(1)}` +
            `${calc.unit} ± ${calc.deviation}${calc.unit}`,
        });
      }
    }

    await this.pdfWriteService.write({ names: pdfEntires }, frsOptions);
  }

  /**
   * determines the correct interpretation based on the current measured value
   * @param {Calculation} calc the calculation object
   * @returns {string} the interpretation
   */
  getCorrectInterpretation(calc: Calculation): string {
    if (!calc.actualValue)
      throw new Error('The calculation was not measured (has no actualValue)!');

    // TODO: ask the user for gender!
    const targetValue =
      calc.targetValue instanceof Map
        ? (calc.targetValue as Map<Gender, number>).get(Gender.M)
        : (calc.targetValue as number);

    if (
      calc.actualValue >= targetValue - calc.deviation &&
      calc.actualValue <= targetValue + calc.deviation
    ) {
      let optimalInterpretation: string;
      this.translateService
        .get('CALCULATION.' + calc.id + '.OPTIMALINTERPREATION')
        .subscribe((name: string) => {
          optimalInterpretation = name;
        });
      return optimalInterpretation;
    } else if (calc.actualValue > targetValue + calc.deviation) {
      let largerInterpretation: string;
      this.translateService
        .get('CALCULATION.' + calc.id + '.LARGERINTERPREATION')
        .subscribe((name: string) => {
          largerInterpretation = name;
        });
      return largerInterpretation;
    } else if (calc.actualValue < targetValue - calc.deviation) {
      let smallerInterpretation: string;
      this.translateService
        .get('CALCULATION.' + calc.id + '.SMALLERINTERPREATION')
        .subscribe((name: string) => {
          smallerInterpretation = name;
        });
      return smallerInterpretation;
    }
  }

  /**
   * Clears the already set calculations
   * @returns {void}
   */
  clearCalibrations() {
    this.calculations.forEach((calc) => {
      calc.actualValue = undefined;
      if (calc.id.endsWith('5')) calc.targetValue = undefined;
    });
  }
}
