import { Component, OnInit, AfterViewInit, Input, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { SceneControlService } from '../../services/scene-control.service';
import { LoadingService, DisciplineInfoService } from '../../services';
import {
  DisciplineEdge,
  DisciplineGroup,
  DisplayedEdge,
  isDisciplineEdge,
  isDisciplineGroup,
  ValueGroups,
} from '../../types';

const freeEdgesLimit = 20;
@Component({
  selector: 'app-model-sidebar',
  templateUrl: './model-sidebar.component.html',
  styleUrls: ['./model-sidebar.component.sass'],
})
export class ModelSidebarComponent implements OnInit, AfterViewInit {
  @Input() scrollContainerRef: string;
  private scrollContainer: HTMLElement;

  displayedColumns = ['distance', 'name', 'action'];
  public dataSource = new MatTableDataSource<DisplayedEdge>();

  customEdgeName = '';
  footerVisible = false;

  selectedRowRef: string;
  editMode = false;
  firstEdgeId: string;

  constructor(
    readonly infoService: DisciplineInfoService,
    readonly loader: LoadingService,
    private sceneControlService: SceneControlService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    this.infoService.groupedEdgeUpdate.subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
      loader.fileState.subscribe((state) => (this.editMode = state));
    });
    this.sceneControlService.firstEdgeSet.subscribe((edgeId) => (this.firstEdgeId = edgeId));
  }

  ngOnInit(): void {
    this.refreshFiltering();
    this.scrollContainer = document.getElementById(this.scrollContainerRef);
  }

  ngAfterViewInit(): void {}

  /**
   * calls the custom filterpredicate when the passed data is visisble
   * @param data
   * @param filter unused, but has to have certain signature
   */
  customFilterPredicate(data: Array<DisplayedEdge>, filter: string): boolean {
    return isDisciplineGroup(data) ? data.visible : this.getDataRowVisible(data);
  }

  /**
   * custom filterpredicate which handels the expanded state of certain groups
   * @param data
   */
  getDataRowVisible(data: Array<DisplayedEdge>): boolean {
    const groupByColumn = 'group';
    const groupRows = this.dataSource.data.filter((row) => {
      if (isDisciplineEdge(row)) return false;
      return row.technicalId && data[groupByColumn] && row.technicalId === data[groupByColumn];
    });

    if (groupRows.length === 0) return true;
    if (groupRows.length > 1) throw 'Data row is in more than one group!';
    const parent = <DisciplineGroup>groupRows[0];

    return parent.visible && parent.expanded;
  }

  /**
   * sets the expanded state of group and triggers rerender with new filter
   * @param row
   * @public
   */
  groupHeaderClick(row: DisciplineGroup): void {
    row.expanded = !row.expanded;
    this.refreshFiltering();
    if (row.technicalId === ValueGroups.FREEFIELD) {
      this.footerVisible = row.expanded;
    }
  }
  /**
   * sends the id of the clicked DisciplineEdge
   * @param element DisciplineEdge represented by the Cell
   */
  onEdgeCellClick(element: DisciplineEdge): void {
    this.selectedRowRef = element._id;
    this.infoService.nextSelectionById(element._id);
    this.infoService.focusEventSource.next('focus');
  }

  /**
   * sends the id of the DisciplineEdge
   * @param element DisciplineEdge correlating to the delete Button
   */
  onEdgeDeleteClick(element: DisciplineEdge): void {
    if (this.isCustomEdge(element) && element.length === undefined) {
      console.log(`field with id=${element._id} is fully removed`);
      this.infoService.removeDisciplineEdge(element._id);
      this.infoService.update();
      return;
    }
    this.infoService.nextDeleteEdge(element._id);
  }

  /**
   * adds a new field to the infoDictonary instance if conditions are valid
   * @public
   */
  onAddCustomEdgeClick(): void {
    if (this.hasMaximumItems(ValueGroups.FREEFIELD) || !this.customEdgeName.length) {
      const key = this.hasMaximumItems(ValueGroups.FREEFIELD)
        ? 'SIDEBAR.ERR_MSG_MAXEDGES'
        : 'SIDEBAR.ERR_MSG_ADDBUTTON';
      let snackBarText = '';
      this.translateService.get(key, { limit: freeEdgesLimit }).subscribe((translation: string) => {
        snackBarText = translation;
      });
      this.snackBar.open(snackBarText, '', {
        duration: 4000,
        panelClass: 'dent-snackBar',
      });
      return;
    }
    this.infoService.addFreeFieldEdge(this.customEdgeName);
    this.infoService.update();
    this.customEdgeName = '';
    this.scrollToBottom();
  }

  isEdgeDeleteable(element: DisciplineEdge) {
    return element._id === this.firstEdgeId || element.length;
  }

  /**
   * when statement in template; checks wether row is a Group or Edge
   * @param index unused but has to have certain signature
   * @param item the actual row
   */
  isGroup(index, item): boolean {
    return isDisciplineGroup(item);
  }

  /**
   * checks wether the passed row blongs to FreeField group or not
   * @param row
   */
  isCustomEdge(row: DisciplineEdge): boolean {
    return row.group === ValueGroups.FREEFIELD;
  }

  private hasMaximumItems(valueGroup: ValueGroups): boolean {
    let disciplineGroup: DisciplineGroup = this.dataSource.data.find(
      (group) => group.technicalId === valueGroup,
    ) as DisciplineGroup;
    return disciplineGroup?.count >= freeEdgesLimit;
  }

  /**
   * sets the scrollposition of the analysis scrollContainer to bottom
   */
  private scrollToBottom(): void {
    this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
  }

  /**
   * hack to trigger filter refresh
   */
  private refreshFiltering(): void {
    this.dataSource.filter = performance.now().toString(); // hack to trigger filter refresh
  }
}
