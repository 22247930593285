import { Tuple2 } from 'src/app/core';
import { Object3D, Vector3, Mesh, Vector2, Material, Quaternion, Light } from 'three';

/**
 * calculates the middle point between the two input vectors
 * (firstVector + secondVector)/ 2
 * @param firstVector
 * @param secondVector
 * @returns {Vector3}
 */
export const calculateMiddlePoint = (firstVector: Vector3, secondVector: Vector3): Vector3 => {
  const vectorA = firstVector.clone();
  const vectorB = secondVector.clone();
  return vectorA.add(vectorB).divideScalar(2);
};

/**
 * sets the Rotation of an object based on two input vectors
 * @param object object to set the Rotation
 * @param vectors Array of 2 Vectors
 */
export const setRotation = (object: Object3D, vectors: Tuple2<Vector3>): void => {
  const vectorA = vectors.one.clone();
  const vectorB = vectors.two.clone();
  vectorA.sub(vectorB);
  object.quaternion.setFromUnitVectors(object.up, vectorA.normalize());
};

/**
 * sets position of object to position input
 * @param object any Object3D
 * @param position any Vector3D
 */
export const setPosition = (object: Object3D, position: Vector3): void => {
  object.position.fromArray(position.toArray());
};

/**
 * returns the center of the bounding Box
 * @param mesh
 */
export const getCenter = (mesh: Mesh): Vector3 => {
  const { geometry } = mesh;
  geometry.computeBoundingBox();
  const localCenter = new Vector3();
  geometry.boundingBox.getCenter(localCenter);
  // really?
  return mesh.localToWorld(localCenter);
};

/**
 * calculates the center of any Mesh
 * @param meshes Mesh to get the center from
 */
export const getAverageCenter = (...meshes: Array<Mesh>): Vector3 => {
  const centers = meshes.map((mesh) => {
    return getCenter(mesh);
  });
  const center = new Vector3();
  let count = 0;
  centers.forEach((boxCenter) => {
    center.add(boxCenter);
    count++;
  });
  return center.divideScalar(count);
};

/**
 * normalizes the mouse position for a canvas
 * @param mouseX
 * @param mouseY
 * @param canvas
 */
export const normalizeMousePosition = (
  mouseX: number,
  mouseY: number,
  canvas: HTMLCanvasElement,
): Vector2 => {
  const canvasPosition = canvas.getBoundingClientRect();
  const normMouse = new Vector2();
  normMouse.x =
    canvasPosition.width === 0
      ? (mouseX / window.innerWidth) * 2 - 1
      : ((mouseX - canvasPosition.left) / canvasPosition.width) * 2 - 1;
  normMouse.y =
    canvasPosition.height === 0
      ? -(mouseY / window.innerHeight) * 2 - 1
      : -((mouseY - canvasPosition.top) / canvasPosition.height) * 2 + 1;
  return normMouse;
};

/**
 * disposes geometry and materials of a mesh
 * @param mesh
 */
export const disposeMesh = (mesh: Mesh): undefined => {
  mesh.geometry.dispose();
  execForMaterialOrArray(mesh.material, (material) => {
    material.dispose();
  });
  return undefined;
};

/**
 * executes a function for either the material or an array of materials
 * @param material
 * @param callback
 * @returns result of callback
 */
export const execForMaterialOrArray = <T = any>(
  material: Material | Array<Material>,
  callback: (material: Material) => T,
): T | Array<T> => {
  if (material instanceof Array) {
    const result = new Array<T>();
    material.forEach((material) => {
      result.push(callback(material));
    });
    return result;
  }
  if (material instanceof Material) {
    return callback(material);
  }
};

/**
 * check if an object can be disposed and then does so. Do not use to create recursion with @disposeMesh
 * @param object
 */
export const disposeIfPossible = (object: Object3D): undefined => {
  if (object instanceof Mesh) {
    disposeMesh(object);
  }
  return undefined;
};

/**
 * calculates and returns the quaternion rotation from 'from' to 'to'
 * @param from
 * @param to
 */
export const calculateRotation = (from: Vector3, to: Vector3): Quaternion => {
  const quaternion = new Quaternion();
  quaternion.setFromUnitVectors(from.clone().normalize(), to.clone().normalize());
  return quaternion;
};

/**
 * attaches a light relative to object. use scene without relativePosition for (0,0,0)
 * @param object object to attach light to
 * @param light light to attach
 * @param relativePosition positionvector to add to the objects postion as the position for the light
 * @returns {void}
 */
export const setLightWithPosition = (
  object: Object3D,
  light: Light,
  relativePosition = new Vector3(),
): void => {
  light.position.fromArray(object.position.toArray());
  light.position.add(relativePosition);
  object.add(light);
};
