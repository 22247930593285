/**
 * mapping from display names to pdf names
 */
export const fieldNameMap = new Map([
  [
    '20000000-0000-0000-0000-000000000001',
    { actualValue: 'Messung81  3', interpretation: 'Interpretation81  3' },
  ],
  [
    '20000000-0000-0000-0000-000000000002',
    { actualValue: 'Messung79  3', interpretation: 'Interpretation79  3' },
  ],
  [
    '20000000-0000-0000-0000-000000000003',
    { actualValue: 'Messung80  3', interpretation: 'Interpretation80  3' },
  ],
  [
    '20000000-0000-0000-0000-000000000004',
    { actualValue: 'Messung2  2', interpretation: 'Interpretation2  2' },
  ],
  [
    '20000000-0000-0000-0000-000000000005',
    {
      actualValue: 'MessungANB indiv',
      targetValue: '2  2ANB indiv',
      interpretation: 'InterpretationANB indiv',
    },
  ],
  [
    '20000000-0000-0000-0000-000000000006',
    { actualValue: 'Messung1  0mm  2mm', interpretation: 'Interpretation1  0mm  2mm' },
  ],
  [
    '20000000-0000-0000-0000-000000000007',
    { actualValue: 'Messung32  5', interpretation: 'Interpretation32  5' },
  ],
  [
    '20000000-0000-0000-0000-000000000008',
    { actualValue: 'Messung7  3', interpretation: 'Interpretation7  3' },
  ],
  [
    '20000000-0000-0000-0000-000000000009',
    { actualValue: 'Messung25  5', interpretation: 'Interpretation25  5' },
  ],
  [
    '20000000-0000-0000-0000-000000000010',
    { actualValue: 'Messung65  4', interpretation: 'Interpretation65  4' },
  ],
  [
    '20000000-0000-0000-0000-000000000011',
    { actualValue: 'Messung126  6', interpretation: 'Interpretation126  6' },
  ],
  [
    '20000000-0000-0000-0000-000000000012',
    { actualValue: 'Messung80  6', interpretation: 'Interpretation80  6' },
  ],
  [
    '20000000-0000-0000-0000-000000000013',
    { actualValue: 'Messung102  5', interpretation: 'Interpretation102  5' },
  ],
  [
    '20000000-0000-0000-0000-000000000014',
    { actualValue: 'Messung70  5', interpretation: 'Interpretation70  5' },
  ],
  [
    '20000000-0000-0000-0000-000000000015',
    { actualValue: 'Messung93  6', interpretation: 'Interpretation93  6' },
  ],
  [
    '20000000-0000-0000-0000-000000000016',
    { actualValue: 'Messung131  9', interpretation: 'Interpretation131  9' },
  ],
  [
    '20000000-0000-0000-0000-000000000017',
    { actualValue: 'Messung4mm  2mm', interpretation: 'Interpretation4mm  2mm' },
  ],
  [
    '20000000-0000-0000-0000-000000000018',
    { actualValue: 'Messung4mm  2mm_2', interpretation: 'Interpretation4mm  2mm_2' },
  ],
  [
    '20000000-0000-0000-0000-000000000019',
    { actualValue: 'Messung68  4', interpretation: 'Interpretation68  4' },
  ],
  [
    '20000000-0000-0000-0000-000000000020',
    { actualValue: 'Messung105  5', interpretation: 'Interpretation105  5' },
  ],
  [
    '20000000-0000-0000-0000-000000000021',
    { actualValue: 'Messung64  4', interpretation: 'Interpretation64  4' },
  ],
  [
    '20000000-0000-0000-0000-000000000022',
    { actualValue: 'Messung4mm  2mm_3', interpretation: 'Interpretation4mm  2mm_3' },
  ],
  [
    '20000000-0000-0000-0000-000000000023',
    { actualValue: 'Messung2mm  2mm', interpretation: 'Interpretation2mm  2mm' },
  ],
]);

/**
 * a function to format the date
 */
export const formatDate = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
};
