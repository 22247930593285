import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
  private confirmationDialog: ConfirmationDialogComponent;
  navigateAwaySelection: Subject<boolean> = new Subject<boolean>();
  constructor() {}

  /**
   * Opens the dialog
   */
  openDialog(contentTranslationKey: string) {
    this.confirmationDialog.openDialog(contentTranslationKey);
  }

  /**
   * Registers a dialog
   * @param dialog Dialog component that will be used
   */
  registerDialog(dialog: ConfirmationDialogComponent) {
    this.confirmationDialog = dialog;
  }
}
