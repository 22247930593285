import { v4 } from 'uuid';
import { DisciplineGroup, ValueGroups, DisciplineEdge } from '../types';

export const disciplineGroups: Array<DisciplineGroup> = [
  {
    parent: new DisciplineGroup(),
    expanded: true,
    extendable: false,
    visible: true,
    technicalId: ValueGroups.TOOTHGROUPS,
  },
  {
    parent: new DisciplineGroup(),
    expanded: false,
    extendable: false,
    visible: true,
    technicalId: ValueGroups.TOOTHWIDTH,
  },
  {
    parent: new DisciplineGroup(),
    expanded: false,
    extendable: false,
    visible: true,
    technicalId: ValueGroups.TRANSVERSAL,
  },
  {
    parent: new DisciplineGroup(),
    expanded: false,
    extendable: false,
    visible: true,
    technicalId: ValueGroups.OCCLUSION,
  },
  {
    parent: new DisciplineGroup(),
    expanded: false,
    extendable: false,
    visible: true,
    technicalId: ValueGroups.FREEFIELD,
    count: 0,
  },
];

/**
 * generates the base edge data for toothwidths, which is supposed to be saved in a DisciplineInfoDictionary
 * @returns {Array<DisciplineEdge>}
 * @example
 * {
 *  _id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
 *  sortindex: 7,
 *  name: 'Zahn 21',
 *  group: 'Zahnbreiten',
 *  disciplineLandmarks: ['Zahn 21.1', 'Zahn 21.2'],
 * }
 */

const generateDisciplineToothEdges = (group: ValueGroups): Array<DisciplineEdge> => {
  const teeth = [
    ['11', 'Text8'],
    ['12', 'Text7'],
    ['13', 'Text6'],
    ['14', 'Text5'],
    ['15', 'Text4'],
    ['16', 'Text3'],
    ['21', 'Text9'],
    ['22', 'Text10'],
    ['23', 'Text11'],
    ['24', 'Text12'],
    ['25', 'Text13'],
    ['26', 'Text14'],
    ['31', 'Text21'],
    ['32', 'Text22'],
    ['33', 'Text23'],
    ['34', 'Text24'],
    ['35', 'Text25'],
    ['36', 'Text26'],
    ['41', 'Text20'],
    ['42', 'Text19'],
    ['43', 'Text18'],
    ['44', 'Text17'],
    ['45', 'Text16'],
    ['46', 'Text15'],
  ];
  const technicalId = 'TOOTH';

  const edges = teeth.map(
    (value): DisciplineEdge => {
      let nameAttachement = value[0];
      return {
        _id: v4(),
        technicalId,
        nameAttachement,
        pdfName: value[1],
        group,
        disciplineLandmarks: [
          `${technicalId} ${nameAttachement}.1`,
          `${technicalId} ${nameAttachement}.2`,
        ],
      };
    },
  );
  return edges;
};

/**
 * generates the Teeth group data for the DisciplineInfoDictionary
 * @example
 * {
 *  _id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
 *  sortindex: 30,
 *  name: 'OK Stützzone rechts',
 *  group: 'Zahngruppen'
 *  disciplineLandmarks: ['OK Stützzohne rechts.1', 'OK Stützzohne rechts.2'],
 * }
 */
const generateTeethGroupEdges = (group: ValueGroups): Array<DisciplineEdge> => {
  const technicalIds = [
    ['TEETHGROUP.OK_STÜTZ_R', 'rechtsIst'],
    ['TEETHGROUP.OK_STÜTZ_L', 'linksIst'],
    ['TEETHGROUP.UK_STÜTZ_R', 'rechtsIst2'],
    ['TEETHGROUP.UK_STÜTZ_L', 'linksIst2'],
  ];
  const result: Array<DisciplineEdge> = technicalIds.map((value) => {
    const technicalId = value[0];
    const edge: DisciplineEdge = {
      _id: v4(),
      technicalId,
      pdfName: value[1],
      group,
      disciplineLandmarks: [`${technicalId}.1`, `${technicalId}.2`],
    };
    return edge;
  });
  return result;
};

/**
 * generates the DisciplineEdges for transversal measurement
 * @example
 * {
 *  _id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
 *  sortindex: 40,
 *  name: 'OK VZB',
 *  group: 'Transversal'
 *  disciplineLandmarks: ['OK VZB.1', 'OK VZB.2'],
 * }
 */
const generateTransversalEdges = (group: ValueGroups): Array<DisciplineEdge> => {
  const technicalIds = [
    ['TRANSVERSAL.OK_VZB', 'VZB44OKmm'],
    ['TRANSVERSAL.OK_HZB', 'HZB66OKmm'],
    ['TRANSVERSAL.UK_VZB', 'VZB44UKmm'],
    ['TRANSVERSAL.UK_HZB', 'HZB66UKmm'],
  ];
  const result: Array<DisciplineEdge> = technicalIds.map((value) => {
    const technicalId = value[0];
    const edge: DisciplineEdge = {
      _id: v4(),
      technicalId,
      pdfName: value[1],
      group,
      disciplineLandmarks: [`${technicalId}.1`, `${technicalId}.2`],
    };
    return edge;
  });
  return result;
};

/**
 * generates the DisciplineEdges for Okklusion measurement
 * @example
 * {
 *  _id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
 *  sortindex: 50,
 *  name: 'Overjet',
 *  group: 'Okklusion'
 *  disciplineLandmarks: ['Overjet.1', 'Overjet.2'],
 * }
 */
const generateOkklusionEdges = (group: ValueGroups): Array<DisciplineEdge> => {
  const technicalIds = [
    ['OCCLUSION.OVERJET', 'Overjet'],
    ['OCCLUSION.OVERBITE', 'Overbite'],
  ];
  const result: Array<DisciplineEdge> = technicalIds.map((value) => {
    const technicalId = value[0];
    const edge: DisciplineEdge = {
      _id: v4(),
      technicalId,
      pdfName: value[1],
      group,
      disciplineLandmarks: [`${technicalId}.1`, `${technicalId}.2`],
    };
    return edge;
  });
  return result;
};

/**
 * gets valueGroup with certain technicalId (group)
 * @param group
 */
const addValueGroup = (group: ValueGroups): DisciplineGroup => {
  return disciplineGroups.find((valueGroup) => valueGroup.technicalId === group);
};

/**
 * generate uniqui free field with the field name and the intance number
 * @param technicalId
 * @param count
 */
export const generateFreeFieldEdge = (technicalId: string, newId: number): DisciplineEdge => {
  return {
    _id: v4(),
    technicalId,
    group: ValueGroups.FREEFIELD,
    disciplineLandmarks: [
      `${technicalId.toUpperCase()}.${newId}.1`,
      `${technicalId.toUpperCase()}.${newId}.2`,
    ],
  };
};

export const disciplineEdges: Array<DisciplineEdge> = [
  ...generateDisciplineToothEdges(ValueGroups.TOOTHGROUPS),
  ...generateTeethGroupEdges(ValueGroups.TOOTHWIDTH),
  ...generateTransversalEdges(ValueGroups.TRANSVERSAL),
  ...generateOkklusionEdges(ValueGroups.OCCLUSION),
];
