import { Component, HostBinding, OnInit } from '@angular/core';
import { Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from './core/enums';

import { LayoutService, ThemeService } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'dentalyzer';

  constructor(
    private layoutService: LayoutService,
    private themeService: ThemeService,
    private translateService: TranslateService,
  ) {}

  @HostBinding('class')
  get appClasses() {
    return `${this.themeService.getSelectedTheme()} ${this.layoutService.getCurrentLayout()}`;
  }

  ngOnInit() {
    this.layoutService.setCurrentLayout(Breakpoints.Small, Breakpoints.Medium);
    this.themeService.reloadThemeFromStorage();

    const browserLang = this.translateService.getBrowserLang();
    let appLang: string;
    browserLang === Languages.LANG_DE ? (appLang = browserLang) : (appLang = Languages.LANG_EN);
    this.translateService.use(appLang);
  }
}
