import { Injectable } from '@angular/core';
import { CookieNames, TokenProperties } from '../enums';

@Injectable({ providedIn: 'root' })
export class CookieService {
  constructor() {}

  /**
   * Saves the token and roles in cookies
   * @public
   * @param encodedToken - The encoded bearer token
   * @param decodedToken - The decoded bearer token
   */
  saveAuth(encodedToken: string, decodedToken: any) {
    // bearer token expiration time in seconds by default
    let expiration = decodedToken[TokenProperties.TOKEN_PROP_EXPIRATION] ,
      roles = decodedToken[TokenProperties.TOKEN_PROP_ROLES];
    this.setCookie(CookieNames.COOKIE_TOKEN, encodedToken, expiration);
    this.setCookie(CookieNames.COOKIE_ROLE, roles, expiration);
    console.log(`AuthorizationCookies successfully set`);
  }

  /**
   * Sets a single cookie
   * @private
   * @param name - Name of the cookie
   * @param value - Value of the cookie
   * @param expires - Expiration date in ms
   */
  private setCookie(name: string, value: string, expires?: number, path?: string, domain?: string) {
    let cookieStr = `${name}=${value};`;

    if (expires !== undefined) {
      const expirationDate = new Date(expires);
      cookieStr += `expires=${expirationDate.toUTCString()};`;
    }
    if (path !== undefined) {
      cookieStr += `path=${path};`;
    }
    if (domain !== undefined) {
      cookieStr += `domain=${domain};`;
    }

    // Schutz vor XSS
    cookieStr += 'SameSite=Strict';

    // neues Cookie anlegen
    document.cookie = cookieStr;
  }

  /**
   * Removes all cookies
   * @public
   */
  removeCookies() {
    this.eraseCookie(CookieNames.COOKIE_TOKEN);
    this.eraseCookie(CookieNames.COOKIE_ROLE);
  }

  /**
   * Erases a cookie
   * @private
   * @param name - name of the cookie
   */
  private eraseCookie(name: string) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }

  /**
   * Gets the roles of the current user
   */
  getRoles(): string | undefined {
    return this.getCookie(CookieNames.COOKIE_ROLE);
  }

  /**
   * @param name Name of the cookie
   * @return Value of the found cookie
   */
  getCookie(name: string): string | undefined {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (!!parts && parts.length == 2) {
      return parts.pop().split(';').shift();
    }
    return undefined;
  }
}
