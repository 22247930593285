import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogService } from '../service/confirmationDialog.service';
import { ConfirmationDialogContentComponent } from './confirmation-dialog-content.component';

@Component({
  selector: 'app-confirmation-dialog',
  template: '',
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(public dialog: MatDialog, private dialogService: ConfirmationDialogService) {}

  ngOnInit(): void {
    this.dialogService.registerDialog(this);
  }

  openDialog(contentTranslationKey: string) {
    this.dialog.open(ConfirmationDialogContentComponent, {
      data: {
        contentTranslationKey: contentTranslationKey,
      },
    });
  }
}
