<button mat-fab color="primary" class="smaller" (click)="onVisibility()">
  <mat-icon *ngIf="visibility; else invisible">visibility</mat-icon>
</button>

<ng-template #invisible>
  <mat-icon>visibility_off</mat-icon>
</ng-template>

<button
  mat-fab
  color="primary"
  class="smaller"
  (click)="onControlSelection()"
  *ngIf="cameraSelected; else rulerSelected"
>
  <mat-icon>camera_alt</mat-icon>
</button>

<ng-template #rulerSelected>
  <button mat-fab color="accent" class="smaller" (click)="onControlSelection()">
    <mat-icon>square_foot</mat-icon>
  </button>
</ng-template>

<button
  mat-fab
  color="primary"
  class="smaller"
  (click)="onModeSelection('rotate')"
  *ngIf="isTranslate; else rotateSelected"
>
  <mat-icon>open_with</mat-icon>
</button>

<ng-template #rotateSelected>
  <button mat-fab color="accent" class="smaller" (click)="onModeSelection('translate')">
    <mat-icon>3d_rotation</mat-icon>
  </button>
</ng-template>
