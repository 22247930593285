<div id="idContainer-modelAnalyzer" class="dent-modelAnalyser">
  <div
    *ngIf="filesUploaded; else fileInputContainer"
    #rendererContainer
    (pointerup)="onPointerUp($event)"
  >
    <span class="model-menu">
      <button
        *ngIf="renderingFinished"
        mat-fab
        color="primary"
        class="cameraButton"
        (click)="centerCamera()"
      >
        <mat-icon>center_focus_strong</mat-icon>
      </button>
      <app-ruler></app-ruler>
    </span>
  </div>
  <ng-template #fileInputContainer>
    <app-file-upload
      (fileUploaded)="onFileUploaded($event)"
      accept="STL"
      [fileUploadFinished]="filesUploaded"
    ></app-file-upload>
  </ng-template>
</div>

<app-confirmation-dialog></app-confirmation-dialog>
<app-waiting-spinner [showWaitingSpinner]="showWaitingSpinner"></app-waiting-spinner>
