
<section fxLayout="row" fxLayoutAlign="space-between">
  <button
    mat-raised-button
    *ngFor="let option of options"
    color="{{ option.color }}"
    matTooltip="{{ 'SIDEBAR_BUTTONS.' + option.tooltipKey | translate }}"
    matTooltipClass="tooltip-theme"
    (click)="onOptionButtonClick(option.key)"
  >
    <span
      id="{{ option.key }}-titleElement"
      *ngIf="option.showName"
      [ngClass]="{ 'dent-optionButton-text': !option.titleVisibleForIpad }"
      >{{ 'SIDEBAR_BUTTONS.' + option.key | translate }}</span
    ><mat-icon id="{{ option.key }}-iconElement" *ngIf="option.iconSrc">{{
      option.iconSrc
    }}</mat-icon>
  </button>
</section>
