import { Injectable } from '@angular/core';
import { Mesh, PerspectiveCamera, Raycaster, Vector2, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { getAverageCenter } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  camera: PerspectiveCamera;
  controls: OrbitControls;
  defaultPosition = new Vector3(113, 111, 113);

  constructor() {}

  /**
   * Creates the camera with the configuration for modelanalysis, set a light
   * @param offsetWidth
   * @param offsetHeight
   */
  createCamera(offsetWidth: number, offsetHeight: number): PerspectiveCamera {
    this.camera = new PerspectiveCamera(35, offsetWidth / offsetHeight, 0.01, 10000);
    this.camera.position.copy(this.defaultPosition);
    // set a light to illuminate from the camera
    // setLightWithPosition(this.camera, new PointLight(0xdddddd, 0.5));
    return this.camera;
  }

  /**
   * create Orbit controls and enable them on this camera and the passed domElement
   * @param domElement
   */
  createControls(domElement: HTMLElement): OrbitControls {
    this.controls = new OrbitControls(this.camera, domElement);
    this.controls.maxDistance = 3000;
    this.controls.minDistance = 1;
    return this.controls;
  }

  /**
   * get a setFromCamera Raycaster
   * @param normalizedMouse
   */
  getRaycaster(normalizedMouse: Vector2): Raycaster {
    const raycaster = new Raycaster();
    raycaster.setFromCamera(normalizedMouse, this.camera);
    return raycaster;
  }

  /**
   * uses average center of all passed objects to update what to focus on
   * @param object Mesh parameters
   */
  update(...object: Array<Mesh>): void {
    const target = getAverageCenter(...object);
    this.camera.position.copy(this.defaultPosition);
    this.camera.lookAt(target);
    this.controls.target = target;
    this.controls.update();
  }

  /**
   * sets whether or not CameraControls are enabled. True is enabled
   * @param enable
   */
  enableControls(enable: boolean): void {
    this.controls.enabled = enable;
  }

  /**
   * @returns a vector representing the rotation from the Camera to the target
   */
  getOrientation(): Vector3 {
    return this.camera.position.clone().sub(this.controls.target);
  }

  /**
   * total reset of this service
   */
  dispose(): void {
    this.controls?.dispose();
    this.camera = undefined;
  }
}
