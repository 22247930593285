
<mat-button-toggle-group appearance="legacy" value="{{ selectedKey }}">
  <mat-button-toggle
    *ngFor="let tab of tabs"
    id="{{ tab.key }}-tooltab"
    (change)="onSelectKey(tab.key)"
    value="{{ tab.key }}"
    >{{ 'TOOLTABS.' + tab.key | translate }}</mat-button-toggle
  >
</mat-button-toggle-group>
