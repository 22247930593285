import { ToolTab, ToolTabs } from '../types';

export const landmarks: ToolTab = {
  key: 'ID_LANDMARK',
};
export const levels: ToolTab = {
  key: 'ID_LEVELS',
};
export const calculations: ToolTab = {
  key: 'ID_CALCULATIONS',
};

export const analyzer2dToolTabs: ToolTabs = [landmarks, levels, calculations];

export const upperJaw: ToolTab = {
  key: 'ID_UPPERJAW',
};
export const lowerJaw: ToolTab = {
  key: 'ID_LOWERJAW',
};
export const showBoth: ToolTab = {
  key: 'ID_SHOWBOTH',
};

export const analyzer3dToolTabs: ToolTabs = [upperJaw, lowerJaw, showBoth];
