import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FileTypes, ThemeService } from 'src/app/core';
import * as THREE from 'three';
import { LayoutTheme } from 'src/app/core';
import { SceneColor } from '../../analyzer3d/services';
import {
  Sprite,
  WebGLRenderer,
  PerspectiveCamera,
  Box3,
  Vector3,
  Scene,
  TOUCH,
  MOUSE,
  TextureLoader,
  Texture,
  SpriteMaterial,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ThreeObjects } from '../models/three.objects';

@Injectable({
  providedIn: 'root',
})

/**
 * a service that controls what is being rendered
 */
export class SceneControlService {
  // threeObjects BehaviorSubject is initialised with null
  threeObjects = new BehaviorSubject<ThreeObjects>(null);

  constructor(private themeService: ThemeService) {}

  /**
   * Sets configuration for zoom and moving with mouse and touch controls
   * @private
   */
  setConfigurationForOrbitControls(controls: OrbitControls, camera: PerspectiveCamera): void {
    controls.enableZoom = true;
    controls.enableRotate = false;
    controls.enablePan = true;

    // distance for camera zoom and zoom restrictions
    const distance = camera.position.distanceTo(controls.target);
    controls.maxDistance = distance;
    controls.minDistance = 0.15;

    controls.mouseButtons.LEFT = MOUSE.PAN;
    controls.touches.ONE = TOUCH.PAN;
  }

  /**
   * Updates the controls and renders the scene and camera
   * @returns {void}
   */
  animate(threeObjects: ThreeObjects): void {
    const { camera, scene, renderer, controls } = threeObjects;
    camera.lookAt(scene.position);
    window.requestAnimationFrame(() => this.animate(threeObjects));
    controls.update();
    renderer.render(threeObjects.scene, camera);
  }

  /**
   * clear the scene
   * @returns {void}
   */
  clearScene(): void {
    const threeObjects = this.threeObjects.getValue();
    while (threeObjects.scene.children.length > 0) {
      threeObjects.scene.remove(threeObjects.scene.children[0]);
    }
    threeObjects.renderer.clear();
    this.threeObjects.next(threeObjects);
  }

  /**
   * @param  {File} file the file selected by the user
   * @returns Promise which will be resolved when the image is loaded
   */
  loadImageInRenderer(rendererContainer: ElementRef, file: File): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onloadend = (): void => {
        const imageDataUri = String(reader.result);
        const textureLoader = new TextureLoader();
        textureLoader.load(
          imageDataUri,
          (texture: Texture) => {
            const material = new SpriteMaterial({ map: texture });
            const sprite = new Sprite(material);
            sprite.name = 'sprite';

            // the initial dimensions of sprite is 1x1, so scale the sprite according to the loaded texture
            sprite.scale.set(1, (1 * texture.image.height) / texture.image.width, 0);

            const outerContainer = document.getElementById('outerContainer');
            const renderer = new WebGLRenderer({ antialias: true });
            renderer.setSize(outerContainer.offsetWidth, outerContainer.offsetHeight);

            rendererContainer.nativeElement.appendChild(renderer.domElement);

            const camera = new PerspectiveCamera(
              45,
              outerContainer.offsetWidth / outerContainer.offsetHeight,
              0.1,
              5000,
            );

            sprite.position.set(0, 0, 0);

            const boundingBox = new Box3().setFromObject(sprite);
            // boundingBox is only used to get size of the sprite
            const size = new Vector3();
            boundingBox.getSize(size);

            const z = (0.5 * (size.y * 1.5)) / Math.tan((22.5 * Math.PI) / 180);
            // calculate camera position z in relation to fov (here 45 degrees) and sprite height

            camera.position.set(0, 0, z);

            let appliedTheme = this.themeService.getSelectedTheme();

            const scene = new Scene();
            if (appliedTheme === LayoutTheme.Light) {
              scene.background = new THREE.Color(SceneColor[LayoutTheme[appliedTheme]]);
            } else {
              scene.background = new THREE.Color(SceneColor[LayoutTheme[appliedTheme]]);
            }
            scene.add(camera);
            scene.add(sprite);

            this.themeService.appliedThemeObserver.subscribe((theme) => {
              scene.background = new THREE.Color(SceneColor[LayoutTheme[theme]]);
            });

            const controls = new OrbitControls(camera, renderer.domElement);
            this.setConfigurationForOrbitControls(controls, camera);

            renderer.render(scene, camera);

            const threeObjects = { renderer, scene, camera, controls };

            this.animate(threeObjects);
            this.threeObjects.next(threeObjects);
            resolve();
          },
          // onProgress callback currently not supported
          undefined,
          // onError callback
          (event: ErrorEvent) => {
            console.error('Error loading ' + event.filename);
            reject();
          },
        );
      };
      reader.readAsDataURL(file);
    });
  }
}
