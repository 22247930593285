import { Calculation, Gender, Group, Unit } from '../models/calculation';

export const calculation: Array<Calculation> = [
  new Calculation('20000000-0000-0000-0000-000000000001', Group.SS, 81, 3, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000002', Group.SS, 79, 3, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000003', Group.SS, 80, 3, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000004', Group.SS, 2, 2, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000005', Group.SS, undefined, 1, Unit.DEGREES),
  new Calculation(
    '20000000-0000-0000-0000-000000000006',
    Group.SS,
    new Map([
      [Gender.M, -1],
      [Gender.F, 0],
    ]),
    2,
    Unit.MILLIMETER,
  ),

  new Calculation('20000000-0000-0000-0000-000000000007', Group.VS, 32, 5, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000008', Group.VS, 7, 3, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000009', Group.VS, 25, 5, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000010', Group.VS, 65, 4, Unit.PERCENT),
  new Calculation('20000000-0000-0000-0000-000000000011', Group.VS, 126, 6, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000012', Group.VS, 80, 6, Unit.PERCENT),

  new Calculation('20000000-0000-0000-0000-000000000013', Group.D, 102, 5, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000014', Group.D, 70, 5, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000015', Group.D, 93, 6, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000016', Group.D, 131, 9, Unit.DEGREES),
  new Calculation('20000000-0000-0000-0000-000000000017', Group.D, 4, 2, Unit.MILLIMETER),
  new Calculation('20000000-0000-0000-0000-000000000018', Group.D, 4, 2, Unit.MILLIMETER),

  new Calculation('20000000-0000-0000-0000-000000000019', Group.LK, 68, 4, Unit.PERCENT),
  new Calculation('20000000-0000-0000-0000-000000000020', Group.LK, 105, 5, Unit.PERCENT),
  new Calculation('20000000-0000-0000-0000-000000000021', Group.LK, 64, 4, Unit.PERCENT),

  new Calculation('20000000-0000-0000-0000-000000000022', Group.P, -4, 2, Unit.MILLIMETER),
  new Calculation('20000000-0000-0000-0000-000000000023', Group.P, -2, 2, Unit.MILLIMETER),
];
