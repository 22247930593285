import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';

import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [HomeComponent],
  imports: [CommonModule, TranslateModule, RouterModule, MatButtonModule],
})
export class HomeModule {}
