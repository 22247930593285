export class RouterHelpers {
  private seperator = '/';

  /**
   * check if the passed path contained in the current url
   * @param route plain path string or mutiple paths parts
   * @public
   * @returns {boolean}
   */
  containedInURL(route: string | Array<string>, url: string): boolean {
    let matcher = this.seperator.concat(
      typeof route === 'string' ? route : route.join(this.seperator),
    );
    return url.includes(matcher);
  }

  /**
   * check if any path part is contained in the current url
   * @param routes plain path string or mutiple path parts
   * @returns {boolean}
   * @public
   */
  anyContainedInURL(routes: Array<string>, url: string): boolean {
    return routes.findIndex((path) => url.includes(this.seperator.concat(path))) !== -1;
  }
}
