<mat-toolbar
  color="primary"
  id="dent-header"
  tabindex="0"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <button mat-icon-button class="dent-header-item" appBackButton *ngIf="!navigateBackVisible">
    <mat-icon> navigate_before </mat-icon>
  </button>
  <div
    id="headerClickToHome"
    class="dent-header-item dent-header-home"
    (click)="navigateToHome()"
    routerLinkActive="active"
    fxLayout="row"
  >
    <img
      class="dent-header-item dent-header-logo"
      src="./assets/icons/Logo.svg"
      height="30"
      width="30"
      alt="Dentalyzer Logo"
    />
    <span class="dent-header-item dent-header-appName">Dentalyzer</span>
  </div>
  <app-header-menu
    [style.visibility]="isOnLoginPage ? 'hidden' : 'visible'"
    class="dent-menu"
    (darkModeToggled)="onDarkModeToggled()"
    fxLayout="row"
  ></app-header-menu>
</mat-toolbar>
