import { Options, OptionButton } from '../types';

export const newFileOption: OptionButton = {
  key: 'ID_NEWFILE',
  iconSrc: 'note_add',
  tooltipKey: 'ID_NEWFILE_TT',
};

export const saveOption: OptionButton = {
  key: 'ID_SAVEFILE',
  iconSrc: 'picture_as_pdf',
  tooltipKey: 'ID_DOWNLOADPDF_TT',
};

export const evaluateOption: OptionButton = {
  key: 'ID_EVALUATEFILE',
  iconSrc: 'create',
  showName: true,
  titleVisibleForIpad: true,
  color: 'accent',
  tooltipKey: 'ID_EVALUATEFILE_TT',
};

export const options: Options = [newFileOption, saveOption, evaluateOption];
