import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core';
import { NoSuchFieldError, PageSizes, PDFCheckBox, PDFDocument, PDFTextField } from 'pdf-lib';

export interface PDFNameMap {
  identifier?: string;
  fieldName: string;
  check?: boolean;
  text?: string;
}

type Mode = 'Fields' | 'Position';

interface PDFWriteOptions {
  mode: Mode;
  pdfName: string;
  analysisName: 'ANALYSIS.FRS' | 'ANALYSIS.MA';
}

export const modelOptions: PDFWriteOptions = {
  mode: 'Fields',
  pdfName: 'ModelanalysisTemplate',
  analysisName: 'ANALYSIS.MA',
};

export const frsOptions: PDFWriteOptions = {
  mode: 'Fields',
  pdfName: 'FRS-AnalysisTemplate',
  analysisName: 'ANALYSIS.FRS',
};

interface Values {
  names?: Array<PDFNameMap>;
  positions?: Array<PDFPositionMap>;
  pictures?: Blob[];
}

@Injectable({
  providedIn: 'root',
})
export class PdfWriteService {
  fileType = '.pdf';
  pdfFolder = './assets/';
  constructor(readonly authService: AuthService, readonly translate: TranslateService) {}

  /**
   * start writing to pdf
   * @param values .names for PDFNameMap .positions for PDFPositionMap
   * @param options specify mode for field or position writing. Defaults available as modelOptions and frsOptions
   */
  async write(values: Values, options: PDFWriteOptions = modelOptions) {
    const { mode, pdfName, analysisName } = options;
    const { names, positions, pictures } = values;

    const file = await this.getFile(pdfName);
    if (file === undefined) {
      return Promise.reject();
    }
    let modifiedPDF: Blob;
    if (mode === 'Fields') {
      modifiedPDF = await this.modifyFieldsPDF(file, names, pictures);
    } else {
      modifiedPDF = await this.modifyPositionPDF(file, positions);
    }
    const downloadName = this.determineDownloadName(analysisName);
    this.downloadPDF(modifiedPDF, downloadName);
  }

  determineDownloadName(analysisName: string): string {
    const stringDate = new Date().toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    let analysis: string;
    this.translate.get(analysisName).subscribe((data) => {
      analysis = data;
    });

    const username = 'demoKiel';

    return `${username}_${analysis}_${stringDate}`;
  }

  /**
   * fetches the pdf from where it is saved
   */
  getFile(ref: string): Promise<ArrayBuffer | undefined> {
    return fetch(`${this.pdfFolder}${ref}${this.fileType}`).then((pdf) => pdf.arrayBuffer());
  }

  async modifyFieldsPDF(
    pdf: ArrayBuffer,
    input: Array<PDFNameMap>,
    pictures?: Blob[],
  ): Promise<Blob> {
    const pdfDoc = await PDFDocument.load(pdf);
    const form = pdfDoc.getForm();
    input.forEach((value) => {
      const { fieldName } = value;
      try {
        const field = form.getField(fieldName);
        if (field instanceof PDFTextField) {
          field.setText(value.text ?? '');
        }
        if (field instanceof PDFCheckBox) {
          if (value.check !== undefined && value.check !== field.isChecked()) {
            field.check();
          }
        }
      } catch (error: unknown) {
        if (error instanceof NoSuchFieldError) {
          console.warn('Could not map field with key=', fieldName);
        }
      }
    });
    if (pictures !== undefined && pictures.length > 0) {
      this.appendPictures(pdfDoc, pictures);
    }
    const pdfBytes = await pdfDoc.save({ updateFieldAppearances: true });
    return new Blob([pdfBytes]);
  }

  /**
   * appends all pictures to the document as png
   * @param pdfDoc
   * @param pictures
   */
  async appendPictures(pdfDoc: PDFDocument, pictures: Blob[]): Promise<void> {
    const addOnePicture = async (picture: Blob) => {
      const bufferPicture = await new Response(picture).arrayBuffer();
      const page = pdfDoc.addPage(PageSizes.A4);
      const pdfPicture = await pdfDoc.embedPng(bufferPicture);
      page.drawImage(pdfPicture, {
        x: page.getWidth() / 2 - pdfPicture.width / 2,
        y: page.getHeight() / 2 - pdfPicture.height / 2 + 250,
        width: pdfPicture.width,
        height: pdfPicture.height,
      });
    };
    const promises = pictures.map(addOnePicture);
    await Promise.all(promises);
  }

  /**
   * loads the pdf, inputs any available measurement
   * @param pdf
   * @param input uses PDFPositionMap to modify the pdf based on position values
   */
  async modifyPositionPDF(pdf: ArrayBuffer, input: Array<PDFPositionMap>): Promise<Blob> {
    const pdfDoc = await PDFDocument.load(pdf);
    // assumes first pdf page to be the page to input values to
    const page = pdfDoc.getPage(0);
    input.forEach((value) => {
      page.drawText(value.text, value.position);
    });
    const pdfBytes = await pdfDoc.save({ updateFieldAppearances: true });
    return new Blob([pdfBytes]);
  }

  downloadPDF(pdf: Blob, downloadName): void {
    const link = document.createElement('a');
    link.download = downloadName + this.fileType;
    link.href = URL.createObjectURL(pdf);
    link.click();
  }
}

interface PDFPositionMap {
  identifier: string;
  text: string;
  position: Position;
}

interface Position {
  x: number;
  y: number;
}
