import { BufferGeometry, Line, LineBasicMaterial, Vector2, Vector3 } from 'three';
import { Injectable } from '@angular/core';
import { Edge } from '../models/edge';
import { Mark } from '../models/mark';
import { StaticDataService } from './static-data.service';
import { SceneControlService } from './scene-control.service';
import { intersect, abs } from 'mathjs';

/**
 * the whole functionality for edges
 * @Injectable in root
 */
@Injectable({
  providedIn: 'root',
})
export class EdgeService {
  edges: Array<Edge> = null;

  /**
   * creates an instance of EdgeService
   * @param {StaticDataService} staticDataService the service object to get all the inital edges
   * @param {SceneControlService} sceneControlService the service object to make changes to the renderer
   */
  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly sceneControlService: SceneControlService,
  ) {
    this.edges = staticDataService.getEdges();
  }

  /**
   * draws the line between two positions
   * @param {Edge} edge the edge from the array
   * @returns {void}
   */
  drawEdge(edge: Edge): void {
    const marks = Array<Vector3>();

    marks.push(new Vector3(edge.mark1.position.x, edge.mark1.position.y, 0.001));
    marks.push(new Vector3(edge.mark2.position.x, edge.mark2.position.y, 0.001));

    const geometry = new BufferGeometry().setFromPoints(marks);
    const material = new LineBasicMaterial({
      color: '#003252',
      transparent: true,
      depthTest: true,
      depthWrite: false,
      polygonOffset: true,
      polygonOffsetFactor: -4,
      linejoin: 'round',
      linewidth: 2,
    });

    const line = new Line(geometry, material);
    line.frustumCulled = false;
    line.name = 'edge';
    line.uuid = edge.id;
    edge.visible = true;
    edge.set = true;

    // get current threeObjects from service
    const threeObjects = this.sceneControlService.threeObjects.getValue();

    threeObjects.scene.add(line);

    // push the updated threeObjects
    this.sceneControlService.threeObjects.next(threeObjects);
  }

  /**
   * finds the partner marks of a mark to draw a edge between them
   * @param {Mark} mark mark whose partners need to be found
   * @returns {Mark[] | undefined} the partner marks or undefined if no partner marks exist
   */
  findPartnerMarks(mark: Mark): Mark[] {
    const partnerMarks = [];
    for (let edge of this.edges) {
      if (edge.mark1.id == mark.id && edge.mark2.set) {
        partnerMarks.push(edge.mark2);
      } else if (edge.mark2.id == mark.id && edge.mark1.set) {
        partnerMarks.push(edge.mark1);
      }
    }
    return partnerMarks.length > 0 ? partnerMarks : undefined;
  }

  /**
   * sets the edge in the edge array and calculates the distance
   * @param {Edge} currentEdge
   * @returns {void}
   */
  setEdgeInEdges(currentEdge: Edge): void {
    for (let edge of this.edges) {
      if (edge.id == currentEdge.id && edge.mark1.set && edge.mark2.set) {
        edge.setEdge();
        edge.setDistance();
      }
    }
  }

  /**
   * get the edge with two marks
   * @param {Mark} mark1 the first mark
   * @param {Mark} mark2 the second mark
   * @returns {Edge | undefined} the edge or undefined
   */
  getEdge(mark1: Mark, mark2: Mark): Edge {
    for (let edge of this.edges) {
      if (
        (edge.mark1.id == mark1.id && edge.mark2.id == mark2.id) ||
        (edge.mark2.id == mark1.id && edge.mark1.id == mark2.id)
      ) {
        return edge;
      }
    }
    return undefined;
  }

  /**
   * set the mark in the edge array
   * @param {Mark} mark the mark from the toolbar
   * @returns {void}
   */
  setMarkInEdges(mark: Mark): void {
    for (let edge of this.edges) {
      if (edge.mark1.id == mark.id) {
        edge.mark1 = mark;
      } else if (edge.mark2.id == mark.id) {
        edge.mark2 = mark;
      }
    }
  }

  /**
   * clears the edges in the array
   * @returns {void}
   */
  clearEdges(): void {
    this.edges.forEach((edge) => {
      edge.unsetEdge(undefined);
    });
  }

  /**
   * unsets the mark in the array and removes it from the scene
   * @param {Mark} mark the mark which should be unset
   */
  unsetMarkInEdges(mark: Mark): void {
    for (let edge of this.edges) {
      if (edge.mark1.id == mark.id || edge.mark2.id == mark.id) {
        edge.unsetEdge(mark);
        this.removeEdgeOnScene(edge);
      }
    }
  }

  /**
   * remoge a edge from the scene
   * @param {Edge} edge the edge which should be removed
   * @return {void}
   */
  removeEdgeOnScene(edge: Edge): void {
    const threeObjects = this.sceneControlService.threeObjects.getValue();

    const obj = threeObjects.scene.getObjectByProperty('uuid', edge.id);
    threeObjects.scene.remove(obj);

    this.sceneControlService.threeObjects.next(threeObjects);
  }

  /**
   * removes the edge to the correspondig mark
   * @param {Mark} mark the mark which correspond to the edge
   * @return {void}
   */
  removeEdgeByMark(mark: Mark): void {
    for (let edge of this.edges) {
      if (edge.mark1.id == mark.id || edge.mark2.id == mark.id) {
        this.removeEdgeOnScene(edge);
      }
    }
  }

  /**
   * hide all the edges
   * @return {void}
   */
  hideEdges(): void {
    const threeObjects = this.sceneControlService.threeObjects.getValue();

    threeObjects.scene.children.forEach((child) => {
      if (child.name == 'edge') {
        child.visible = false;
      }
    });

    this.sceneControlService.threeObjects.next(threeObjects);
  }

  /**
   * hide an edge
   * @param {Edge} edge the edge to hide
   * @return {void}
   */
  hideEdge(edge: Edge): void {
    const threeObjects = this.sceneControlService.threeObjects.getValue();
    const obj = threeObjects.scene.getObjectByProperty('uuid', edge.id);
    obj.visible = false;
    edge.visible = false;
    this.sceneControlService.threeObjects.next(threeObjects);
  }

  /**
   * fade in an edge
   * @param {Edge} edge the edge to fade in
   * @return {void}
   */
  fadeInEdge(edge: Edge): void {
    const threeObjects = this.sceneControlService.threeObjects.getValue();
    const obj = threeObjects.scene.getObjectByProperty('uuid', edge.id);
    obj.visible = true;
    edge.visible = true;
    this.sceneControlService.threeObjects.next(threeObjects);
  }

  /**
   * fade in all the edges
   * @return {void}
   */
  showEdges(): void {
    const threeObjects = this.sceneControlService.threeObjects.getValue();

    threeObjects.scene.children.forEach((child) => {
      if (child.name == 'edge') {
        child.visible = true;
      }
    });

    this.sceneControlService.threeObjects.next(threeObjects);
  }

  /**
   * find the edge by the id
   * @param {string} edgeId the id
   */
  findEdgeById(edgeId: string): Edge {
    let resultEdge: Edge;
    for (let edge of this.edges) {
      if (edge.id == edgeId) {
        resultEdge = edge;
      }
    }
    return resultEdge;
  }

  /**
   * parse the distance from pixel to millimeters
   * @param {number} distanceInPixel the distance
   */
  calibrateDistanceFromPixelToMm(distanceInPixel: number): number {
    let pixelDivisorToMm: number;
    let distanceInMm: number;
    let edge = this.findEdgeById('10000000-0000-0000-0000-000000000001');
    edge.setDistance();
    let calibrationEdgeDistanceInPixel: number = edge.distance;
    if (calibrationEdgeDistanceInPixel > 0 && distanceInPixel > 0) {
      pixelDivisorToMm = calibrationEdgeDistanceInPixel / 10;
      distanceInMm = distanceInPixel / pixelDivisorToMm;
      return distanceInMm;
    } else {
      console.log('KalibrationMarks are not set correctly');
    }
  }

  /**
   * calculate the intersection of two edges
   * @param {Edge} edge1 the first edge
   * @param {Edge} edge2 the second edge
   * @return {Vector3} the intersection
   */
  getIntersectionOfEdges(edge1: Edge, edge2: Edge): Vector3 {
    const edge1Mark1X = edge1.mark1.position.x;
    const edge1Mark1Y = edge1.mark1.position.y;
    const edge1Mark2X = edge1.mark2.position.x;
    const edge1Mark2Y = edge1.mark2.position.y;
    const edge2Mark1X = edge2.mark1.position.x;
    const edge2Mark1Y = edge2.mark1.position.y;
    const edge2Mark2X = edge2.mark2.position.x;
    const edge2Mark2Y = edge2.mark2.position.y;

    const intersection = intersect(
      [edge1Mark1X, edge1Mark1Y],
      [edge1Mark2X, edge1Mark2Y],
      [edge2Mark1X, edge2Mark1Y],
      [edge2Mark2X, edge2Mark2Y],
    );

    return intersection !== null ? new Vector3(intersection[0], intersection[1], 0) : undefined;
  }

  /**
   * calculates the distance from one to another mark with two marks as an input
   * @param mark1 first posistion
   * @param mark2 position distance is calculated to
   */
  getDistanceBetweenInMm(mark1: Mark, mark2: Mark) {
    let dis: number;
    if (mark1.position != undefined && mark2.position != undefined) {
      dis = mark1.position.distanceTo(mark2.position);
      dis = this.calibrateDistanceFromPixelToMm(dis);
      return dis;
    }
  }
}
