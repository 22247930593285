import { Injectable } from '@angular/core';
import { PDFNameMap, PdfWriteService } from 'src/app/shared/service';
import { isDisciplineEdge, ValueGroups } from '../types';
import { DisciplineInfoService } from './DisciplineInfo.service';

@Injectable({
  providedIn: 'root',
})
export class ModelevaluationService {
  constructor(readonly pdfWriter: PdfWriteService, readonly infoDict: DisciplineInfoService) {}

  /**
   * reads all data from edges with defined Edge.length
   * @param infoDict
   */
  readValues(): PDFNameMap[] {
    const result = new Array<PDFNameMap>();
    this.infoDict.edges.toArray().forEach((edge) => {
      if (isDisciplineEdge(edge) && edge.group !== ValueGroups.FREEFIELD) {
        const { length } = edge;
        if (length !== undefined) {
          result.push({ fieldName: edge.pdfName, text: length.toFixed(2) });
        }
      }
    });
    return result;
  }

  /**
   * main function to start evaluation with. Reads relevant data and starts pdf processing
   * @param infoDict DisciplineInfoDictionary to read the analysis data from
   * @param additional Additional writable data like checkboxes of the angular form
   */
  async evaluate(additional: PDFNameMap[] = []): Promise<void> {
    const writableEntries = this.readValues().concat(additional);
    await this.pdfWriter.write({ names: writableEntries });
  }
}
