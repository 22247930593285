import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WaitingSpinnerComponent } from './waiting-spinner/waiting-spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogContentComponent,
} from './confirmation-dialog';
import { BackButtonDirective } from './directives';

// empty shared module left in code for better expandability afterwards
@NgModule({
  declarations: [
    BackButtonDirective,
    WaitingSpinnerComponent,
    ConfirmationDialogComponent,
    ConfirmationDialogContentComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [WaitingSpinnerComponent, ConfirmationDialogComponent, BackButtonDirective],
})
export class SharedModule {}
