export const ignoreDefault = (callback: (key: string) => void): ((key: string) => void) => {
  const result = (key: string) => {
    if (key !== '') {
      callback(key);
    }
  };
  return result;
};

export const execForTwo = <T, k extends keyof T, l extends keyof T, U>(
  obj: T,
  properties: [k, l],
  func: (element: T[k] | T[l]) => U,
): Tuple2<U> => {
  let one;
  if (Object.prototype.hasOwnProperty.call(obj, properties[0])) {
    one = func(obj[properties[0]]);
  }
  let two;
  if (Object.prototype.hasOwnProperty.call(obj, properties[1])) {
    two = func(obj[properties[1]]);
  }
  return new Tuple2<U>(one, two);
};

export class Tuple2<T> {
  one: T;
  two: T;

  constructor(one: T, two: T) {
    this.one = one;
    this.two = two;
  }

  forBoth<U>(func: (element: T) => U): Tuple2<U> {
    return execForTwo(this, ['one', 'two'], func);
  }
}

/**
 * creates deep copy similar to jQuerys "extend()"
 */
export const deepCopy = (a: any, b: any) => {
  for (let key in b) {
    if (b.hasOwnProperty(key)) a[key] = b[key];
    return a;
  }
};
