import { Mark, Typ, Group } from '../models/mark';

export const marks: Array<Mark> = [
  new Mark(
    '00000000-0000-0000-0000-000000000001',
    Typ.CALIBRATION,
    Group.RULER,
    './assets/images/2dMarks/K1.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000002',
    Typ.CALIBRATION,
    Group.RULER,
    './assets/images/2dMarks/K2.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000003',
    Typ.LAND,
    Group.SKULL,
    './assets/images/2dMarks/S.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000004',
    Typ.LAND,
    Group.SKULL,
    './assets/images/2dMarks/N.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000005',
    Typ.LAND,
    Group.SKULL,
    './assets/images/2dMarks/Spa.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000006',
    Typ.LAND,
    Group.SKULL,
    './assets/images/2dMarks/Spp.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000007',
    Typ.LAND,
    Group.SKULL,
    './assets/images/2dMarks/Sp.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000008',
    Typ.LAND,
    Group.SKULL,
    './assets/images/2dMarks/A.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000009',
    Typ.LAND,
    Group.SKULL,
    './assets/images/2dMarks/Ar.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000010',
    Typ.LAND,
    Group.JAW,
    './assets/images/2dMarks/Me.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000011',
    Typ.LAND,
    Group.JAW,
    './assets/images/2dMarks/Pog.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000012',
    Typ.LAND,
    Group.JAW,
    './assets/images/2dMarks/B.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000013',
    Typ.LAND,
    Group.JAW,
    './assets/images/2dMarks/Tgp.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000014',
    Typ.LAND,
    Group.JAW,
    './assets/images/2dMarks/Tga.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000015',
    Typ.LAND,
    Group.JAW,
    './assets/images/2dMarks/Go.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000016',
    Typ.LAND,
    Group.JAW,
    './assets/images/2dMarks/Goa.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000017',
    Typ.LAND,
    Group.TOOTH,
    './assets/images/2dMarks/InOK1.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000018',
    Typ.LAND,
    Group.TOOTH,
    './assets/images/2dMarks/ApOK1.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000019',
    Typ.LAND,
    Group.TOOTH,
    './assets/images/2dMarks/InUK1.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000020',
    Typ.LAND,
    Group.TOOTH,
    './assets/images/2dMarks/ApUK1.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000021',
    Typ.LAND,
    Group.TOOTH,
    './assets/images/2dMarks/vPOcP.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000022',
    Typ.LAND,
    Group.TOOTH,
    './assets/images/2dMarks/hPOcP.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000023',
    Typ.LAND,
    Group.SOFTPART,
    './assets/images/2dMarks/Ls.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000024',
    Typ.LAND,
    Group.SOFTPART,
    './assets/images/2dMarks/Li.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000025',
    Typ.LAND,
    Group.SOFTPART,
    './assets/images/2dMarks/ProN.png',
  ),
  new Mark(
    '00000000-0000-0000-0000-000000000026',
    Typ.LAND,
    Group.SOFTPART,
    `./assets/images/2dMarks/Pog'.png`,
  ),
  new Mark('00000000-0000-0000-0000-000000000027', Typ.LAND, Group.HELPER),
];
