import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { EvaluationComponent } from '../analyzer3d';
// import { EVALUATION_PATH, AuthGuard } from '../../core';

const routes: Routes = [
  // {
  //   path: EVALUATION_PATH,
  //   component: EvaluationComponent,
  //   canActivate: [AuthGuard],
  //   outlet: 'primary',
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalysisRoutingModule {}
