<div class="dent-confirmationDialog-title" mat-dialog-title>
  <mat-icon>warning</mat-icon> {{ 'CONFIRMATION_DIALOG.TITLE' | translate }}
</div>
<span class="dent-confirmationDialog-content" mat-dialog-content
  >{{ (contentTranslationKey) | translate }}</span
>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" (click)="onSelect(false)" cdkFocusInitial>
    {{ 'CONFIRMATION_DIALOG.CANCEL_BTN' | translate }}
  </button>
  <button mat-raised-button mat-dialog-close color="accent" (click)="onSelect(true)">
    {{ 'CONFIRMATION_DIALOG.CONFIRM_BTN' | translate }}
  </button>
</div>
