import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HandleUnrecoverableStateService {
  constructor(
    private _snackbar: MatSnackBar,
    updates: SwUpdate,
    readonly translate: TranslateService,
  ) {
    updates.unrecoverable.subscribe((event) => {
      let begin;
      translate.get('UNRECOVERABLE.BEGIN').subscribe((data) => {
        begin = data;
      });
      let end;
      translate.get('UNRECOVERABLE.END').subscribe((data) => {
        end = data;
      });
      let reload;
      translate.get('SNACKBAR.RELOAD').subscribe((data) => {
        reload = data;
      });
      let snackBarRef = this._snackbar.open(`${begin}${event.reason}${end}`, reload);
      snackBarRef.onAction().subscribe(() => {
        window.location.reload();
      });
    });
  }
}
