import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Mark, Typ } from '../models/mark';
import { Mesh, Vector3, Object3D } from 'three';
import {
  getCenter,
  getMarkMesh,
  getMarkGripMesh,
  calcDirectionVector,
} from './service-helper/mark.helper';
import { createLabel } from './service-helper/label.helper';
import { Injectable } from '@angular/core';
import { StaticDataService } from './static-data.service';
import { SceneControlService } from './scene-control.service';
import { intersect } from 'mathjs';

/**
 * the whole functionality for marks
 * @Injectable in root
 */
@Injectable({
  providedIn: 'root',
})
export class MarkService {
  marks: Array<Mark> = null;

  /**
   * creates an instance of MarkService
   * @param {StaticDataService} staticDataService the service object to get all the inital marks
   * @param {SceneControlService} sceneControlService the service object to make changes to the renderer
   */
  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly sceneControlService: SceneControlService,
    private readonly translateService: TranslateService,
  ) {
    this.marks = staticDataService.getMarks();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.reloadLabels();
    });
  }

  /**
   * draw the mark on the mouseclick position
   * @param {Mark} mark the selected mark
   * @param {Vector3} position the position from the mouseclick
   * @returns {void}
   */
  drawMark(mark: Mark, position: Vector3, isAutoMark: boolean, labelVisible?: boolean): void {
    // get the Crosshair and set uuid and position
    var markMesh = getMarkMesh(isAutoMark);
    markMesh.uuid = mark.id;
    markMesh.position.set(position.x, position.y, 0.002);
    const markCenter = getCenter(markMesh);

    const grip = getMarkGripMesh();
    grip.position.set(markCenter.x, markCenter.y, 0.001);
    markMesh.add(grip);

    // get current threeObjects from service
    const threeObjects = this.sceneControlService.threeObjects.getValue();

    var markName: string;
    this.translateService.get('MARK.' + mark.id + '.SHORTFORM').subscribe((name: string) => {
      markName = name;
    });
    const label = createLabel(markName);

    // length of markname * 4.3 is the factor to center the label unter the mark
    label.position.set(markCenter.x + (120 - markName.length * 4.3), markCenter.y - 70, 0.5);

    if (isAutoMark) {
      label.visible = labelVisible ?? false;
    }

    markMesh.add(label);

    // add the (already scaled) mesh to the scene
    threeObjects.scene.add(markMesh);

    // push the updated threeObjects
    this.sceneControlService.threeObjects.next(threeObjects);
  }

  /**
   * Reloads labels of already set marks
   */
  reloadLabels() {
    const threeObjects = this.sceneControlService.threeObjects.getValue();

    if (threeObjects != null) {
      this.marks.forEach((mark) => {
        if (mark.set) {
          const markObject = threeObjects.scene.getObjectByProperty('uuid', mark.id);
          const oldLabel = markObject.children[1];
          markObject.remove(oldLabel);

          var markName: string;
          this.translateService.get('MARK.' + mark.id + '.SHORTFORM').subscribe((name: string) => {
            markName = name;
          });
          const newLabel = createLabel(markName);
          newLabel.position.set(oldLabel.position.x, oldLabel.position.y, 0.5);
          newLabel.visible = oldLabel.visible;
          markObject.add(newLabel);
        }
      });

      this.sceneControlService.threeObjects.next(threeObjects);
    }
  }

  /**
   * set the mark in the array
   * @param {Mark} currentMark the mark which should be set
   * @param {Vector3} position the position for the mark
   * @returns {Mark} the updated mark
   */
  setMarkInMarks(currentMark: Mark, position: Vector3): Mark {
    for (let mark of this.marks) {
      if (mark.id == currentMark.id) {
        mark.setMark(position);
        return mark;
      }
    }

    return undefined;
  }

  /**
   * get the position from the mark of the scene
   * @param {Mark} mark the prefferd mark
   * @returns {Vector3} the position
   */
  getMarkPositionFromScene(mark: Mark): Vector3 {
    const threeObjects = this.sceneControlService.threeObjects.getValue();

    const obj = threeObjects.scene.getObjectByProperty('uuid', mark.id);

    return obj.position;
  }

  /**
   * clears the marks in the array
   * @returns {void}
   */
  clearMarks(): void {
    this.marks.forEach((mark) => {
      mark.unsetMark();
    });
  }

  /**
   * checks if the calibrationmarks are set
   * @returns {boolean} true when both calibrationmarks are set;
   * false when only one ore none calibrationsmarks are set
   */
  checkCalibrationSet(): boolean {
    var counter = 0;

    for (let mark of this.marks) {
      if (mark.typ == Typ.CALIBRATION && mark.set) {
        counter++;
      }
      if (counter == 2) {
        return true;
      }
    }

    return false;
  }

  /**
   * unset the mark in the array an removes it from the scene
   * @param {Mark} currentMark the mark which should be unset
   * @returns {void}
   */
  unsetMarkInMarks(currentMark: Mark): void {
    for (let mark of this.marks) {
      if (mark.id == currentMark.id) {
        mark.unsetMark();
        this.removeMarkOnScene(mark);
        break;
      }
    }
  }

  /**
   * remove a mark from the scene
   * @param {Mark} mark the mark which should be removed
   * @returns {void}
   */
  removeMarkOnScene(mark: Mark): void {
    const threeObjects = this.sceneControlService.threeObjects.getValue();

    const obj = threeObjects.scene.getObjectByProperty('uuid', mark.id);
    threeObjects.scene.remove(obj);

    this.sceneControlService.threeObjects.next(threeObjects);
  }

  /**
   * calculates the line slope for two vector points
   * @param {Mark|Vector3} mark1 mark of the vector 1 or an vector
   * @param {Mark|Vector3} mark2 mark of the vector 2 or an vector
   * @returns the slope of the line equation
   */
  getSlopeOfTwoVectors(mark1: Mark | Vector3, mark2: Mark | Vector3): number {
    const mark1pos = mark1 instanceof Mark ? mark1.position : mark1;
    const mark2pos = mark2 instanceof Mark ? mark2.position : mark2;

    const slope = (mark2pos.y - mark1pos.y) / (mark2pos.x - mark1pos.x);
    return slope;
  }

  /**
   * calculates the orthogonal line slope for two vector points
   * @param {Mark|Vector3} mark1 mark of the vector 1 or an vector
   * @param {Mark|Vector3} mark2 mark of the vector 2 or an vector
   * @returns the slope of the line equation
   */
  getOrthogonalSlope(mark1: Mark | Vector3, mark2: Mark | Vector3): number {
    const mark1pos = mark1 instanceof Mark ? mark1.position : mark1;
    const mark2pos = mark2 instanceof Mark ? mark2.position : mark2;

    const slope = (mark2pos.y - mark1pos.y) / (mark2pos.x - mark1pos.x);
    return -1 / slope;
  }

  /**
   * calculates a vector that yields an orthogonal line with mark1 that is orthogonal to another line
   * @param mark1 the mark required for an orthogonal intersection point
   * @param slope the orthogonal slope (on ProN-Pog')
   * @param necXAxisValue the x-axis value of the mark (ProN) that is farthest from mark1 (Ls|Li).
   * nec
   */
  calcOrthogonalPartnerMark(mark1: Mark | Vector3, slope: number, necXAxisValue: number): Vector3 {
    const mark1pos = mark1 instanceof Mark ? mark1.position : mark1;

    const distance = Math.abs(necXAxisValue - mark1pos.x);

    const newX = mark1pos.x + distance;
    const newY = slope * (newX - mark1pos.x) + mark1pos.y;

    return new Vector3(newX, newY, 0);
  }

  /**
   * calculate the intersection of four marks
   * @param {Mark|Vector3} mark1 the first mark
   * @param {Mark|Vector3} mark2 the second mark
   * @param {Mark|Vector3} mark3 the third mark
   * @param {Mark|Vector3} mark4 the fourth mark
   * @return {Vector3} the intersection
   */
  getIntersectionOfMarks(
    mark1: Mark | Vector3,
    mark2: Mark | Vector3,
    mark3: Mark | Vector3,
    mark4: Mark | Vector3,
  ): Vector3 {
    const markVec1: [number, number] =
      mark1 instanceof Mark ? [mark1.position.x, mark1.position.y] : [mark1.x, mark1.y];
    const markVec2: [number, number] =
      mark2 instanceof Mark ? [mark2.position.x, mark2.position.y] : [mark2.x, mark2.y];
    const markVec3: [number, number] =
      mark3 instanceof Mark ? [mark3.position.x, mark3.position.y] : [mark3.x, mark3.y];
    const markVec4: [number, number] =
      mark4 instanceof Mark ? [mark4.position.x, mark4.position.y] : [mark4.x, mark4.y];

    const intersection = intersect(markVec1, markVec2, markVec3, markVec4);

    return intersection !== null ? new Vector3(intersection[0], intersection[1], 0) : undefined;
  }

  /**
   * calculates the angle between two slopes
   * @param m1 the slope of mark1
   * @param m2 the slope of mark2
   * @param left true the prefered angle will be left otherwise it will be right
   */
  getAngleBetweenTwoStraightLines(m1: number, m2: number, left: boolean): number {
    let angle = Math.atan(Math.abs((m1 - m2) / (1 + m1 * m2)));
    angle = (angle * 180) / Math.PI;
    if ((m1 > 0 && m2 > 0) || (m1 < 0 && m2 < 0)) {
      return left ? angle : 180 - angle;
    } else {
      return left ? 180 - angle : angle;
    }
  }

  /**
   * calculates the angle between three vectors
   * @param {Vector3|Mark} mark1 mark1 or the vector
   * @param {Vector3|Mark} mark2 mark2 or the vector
   * @param {Vector3|Mark} intersection mark3 or the vector
   * @return {number} the angle
   */
  getAngleBetweenVectors(
    mark1: Vector3 | Mark,
    mark2: Vector3 | Mark,
    intersection: Vector3 | Mark,
  ): number {
    const vec1: Vector3 = mark1 instanceof Mark ? mark1.position : mark1;
    const vec2: Vector3 = mark2 instanceof Mark ? mark2.position : mark2;
    const intVec: Vector3 = intersection instanceof Mark ? intersection.position : intersection;

    const vec1IntVec = calcDirectionVector(vec1, intVec);
    const vec2IntVec = calcDirectionVector(vec2, intVec);

    const scalarProduct =
      vec1IntVec.x * vec2IntVec.x + vec1IntVec.y * vec2IntVec.y + vec1IntVec.z * vec2IntVec.z;
    return (Math.acos(scalarProduct / (vec1IntVec.length() * vec2IntVec.length())) * 180) / Math.PI;
  }

  /**
   * search the mark with the markId
   * @param {string} markId the markId from the searched mark
   */
  getMarkByMarkId(markId: string): Mark {
    for (let mark of this.marks) {
      if (mark.id == markId) {
        return mark;
      }
    }
    return undefined;
  }

  /**
   * inverts the visibility of a mark
   * @param mark the mark which should be hided/faded in
   * @returns {void}
   */
  invertMarkVisibility(mark: Mark): void {
    const threeObjects = this.sceneControlService.threeObjects.getValue();
    const obj = threeObjects.scene.getObjectByProperty('uuid', mark.id);
    obj.visible = !obj.visible;
    mark.visible = !mark.visible;
    this.sceneControlService.threeObjects.next(threeObjects);
  }
}
