export class Calculation {
  /**
   * creates an instance of a calculation
   * @param {string} id the uuid of the calculation
   * @param {Group} group decides which characteristic the calculation has
   * @param {number | Map<string, number>} targetValue the default value; can be only one value or decided between male and vemale
   * @param {number} deviation the devation in which the acutal value should be
   * @param {Unit} unit the unit of the target value, devation and actual value
   * @param {number} actualValue the actual measured value
   */
  constructor(
    public id: string,
    public readonly group?: Group,
    public targetValue?: number | Map<Gender, number>,
    public readonly deviation?: number,
    public readonly unit?: Unit,
    public actualValue?: number,
  ) {}
}

export enum Gender {
  M = 'male',
  F = 'female',
}

export enum Unit {
  DEGREES = '°',
  MILLIMETER = ' mm',
  PERCENT = ' %',
}

export enum Group {
  SS = 'SS',
  VS = 'VS',
  D = 'D',
  LK = 'LK',
  P = 'P',
}
