<button
  id="headerMenuBtn"
  #headerMenuBtn
  mat-icon-button
  class="dent-header-item"
  (click)="onToggleMenuBtn()"
>
  <mat-icon>menu</mat-icon>
</button>
<div
  #headerMenu
  class="dent-menu-overlayContainer"
  [style.width]="hideMenu ? '0%' : '34%'"
  tabindex="1"
  (blur)="onBlur($event)"
>
  <div class="dent-header-menu-border mat-app-background"></div>
  <mat-card class="dent-header-menu-card">
    <mat-card-content>
      <h2 class="dent-header-menu-item">{{ 'MENU.TITLE' | translate }}</h2>
      <div class="dent-menu-row">
        <span class="dent-header-menu-item">
          {{ 'MENU.PREFIX_USERNAME' | translate }} <br />
          <b id="headerMenuUsername">{{ username }}</b>
        </span>
        <button
          id="headerMenu-logoutButton"
          mat-raised-button
          class="dent-header-menu-action"
          (click)="onLogoutPress()"
          color="accent"
        >
          {{ 'MENU.BUTTON_LOGOUT' | translate }}
        </button>
      </div>
      <div class="dent-header-menu-border mat-app-background"></div>
      <div class="dent-menu-row">
        <span class="dent-header-menu-item">{{ 'MENU.DARK_MODE' | translate }}</span>
        <mat-slide-toggle
          [checked]="isDarkMode"
          class="dent-header-menu-action"
          (change)="onDarkModeSlideToggled($event)"
          tabindex="2"
        ></mat-slide-toggle>
      </div>
      <div class="dent-menu-row" fxLayoutAlign="space-between">
        <span class="dent-header-menu-item">{{ 'MENU.LANGUAGE' | translate }}</span>
        <div class="dent-header-menu-item dent-header-menu-item-flags">
          <img
            src="./assets/icons/de.png"
            width="35px"
            height="25px"
            alt="German"
            role="button"
            (click)="onChangeLang(languages.LANG_DE)"
            (touchstart)="onChangeLang(languages.LANG_DE)"
          />
          <img
            src="./assets/icons/gb.png"
            width="35px"
            height="25px"
            alt="English"
            role="button"
            (click)="onChangeLang(languages.LANG_EN)"
            (touchstart)="onChangeLang(languages.LANG_EN)"
          />
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
