import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Analyzer2dComponent } from '../analyzer2d';
import { Analyzer3dComponent } from '../analyzer3d';

import { FRS_ANALYSIS_FLG } from '../../core';
import { analyzer2dToolTabs, analyzer3dToolTabs, options } from './models';
import { ToolTabs, Options } from './types';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.sass'],
})
export class AnalysisComponent implements OnInit {
  @ViewChild('dentAnalysisScrollContainer') scrollContainer: ElementRef;

  scrollContainerId = 'dent-scrollTable';
  showFRSAnalysis: boolean;
  analysisTitle: string;
  analysisToolTabs: ToolTabs;
  analysisOptions: Options;
  @ViewChild('analyzer2d') analyzer2d: Analyzer2dComponent;
  @ViewChild('analyzer3d') analyzer3d: Analyzer3dComponent;
  childRoutesActivated = false;

  constructor(private activeRoutedService: ActivatedRoute) {}

  ngOnInit(): void {
    this.activeRoutedService.data.subscribe((data) => {
      let isFrsAnalysis = data.flg === FRS_ANALYSIS_FLG;
      this.showFRSAnalysis = isFrsAnalysis;
      isFrsAnalysis ? (this.analysisTitle = '2D') : (this.analysisTitle = '3D');

      this.analysisToolTabs = isFrsAnalysis ? analyzer2dToolTabs : analyzer3dToolTabs;
      this.analysisOptions = options;
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!!this.analyzer2d || !!this.analyzer3d)
      return this.showFRSAnalysis
        ? this.analyzer2d.canDeactivate()
        : this.analyzer3d.canDeactivate();
  }
}
