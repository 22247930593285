import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.sass'],
})
export class PageNotFoundComponent implements OnInit {
  hasFoundEasteregg: Boolean = false;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Sets hasFoundEasteregg to true at click on the hidden button.
   * This triggers the *ngIf and loads the iframe for the EE.
   */
  onEastereggFound() {
    this.hasFoundEasteregg = true;
  }
}
