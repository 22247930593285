import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { returnUrlQueryParamName } from '../constants';
import { AuthService } from '../services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  /**
   * Checks if the current user has permission to access any route, e.g. if user is logged in
   * @param _
   * @param state the state of the router
   */
  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login'], { queryParams: { [returnUrlQueryParamName]: state.url } });
      console.warn('AuthGuard.canActivate(): not logged in');
      return false;
    }
    return true;
  }
}
