import { Mark } from './mark';

export class Edge {
  /**
   * creates an instance of a edge
   * @param {string} id the uuid of the edge
   * @param {Location} location the geometrical location of the edge
   * @param {Mark} mark1 starting point of the edge
   * @param {Mark} mark2 end point of the edge
   * @param {string} imgUri the image URL of the edge
   * @param {number} distance the distance of the edge in pixel
   * @param {boolean} set the propertie to know if the edge is set or not
   * @param {boolean} visible the propertie to know if the edge is visible or not
   */
  constructor(
    public id: string,
    public readonly location?: Location,
    public mark1?: Mark,
    public mark2?: Mark,
    public readonly imgUri?: string,
    public distance?: number,
    public set: boolean = false,
    public visible: boolean = false,
  ) {}

  /**
   * calculate and set the distance of the edge
   * @returns {void}
   */
  setDistance(): void {
    if (
      this.mark1 == undefined ||
      this.mark2 == undefined ||
      this.mark1.position == undefined ||
      this.mark2.position == undefined
    ) {
      throw new Error('One or both marks have not a position!');
    }

    this.distance = this.mark1.position.distanceTo(this.mark2.position);
  }

  /**
   * sets the edge when the corresponding points are set
   * @returns {void}
   */
  setEdge(): void {
    this.set = true;
    this.visible = true;
  }

  /**
   * clears the properties to unset an edge
   * @param {Mark | undefined} mark the mark which should be unset
   * @returns {void}
   */
  unsetEdge(mark: Mark | undefined): void {
    this.set = false;
    this.visible = false;
    this.distance = null;

    if (mark == undefined) {
      this.mark1.unsetMark();
      this.mark2.unsetMark();
    } else {
      if (this.mark1.id == mark.id) {
        this.mark1.unsetMark();
      } else {
        this.mark2.unsetMark();
      }
    }
  }
}

export enum Location {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
  HELPER = 'HELPER',
}
