import { Component, OnInit, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthService, ThemeService } from '../../services';
import { LayoutTheme, Languages } from '../../enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.sass'],
  exportAs: 'headerMenu',
})
export class HeaderMenuComponent implements OnInit {
  @Output()
  readonly darkModeToggled = new EventEmitter<boolean>();
  hideMenu = true;
  isDarkMode = false;
  username: string;
  lockFunc = false;
  lockTimeout: any;
  @ViewChild('headerMenu') headerMenu: ElementRef;
  @ViewChild('headerMenuBtn', { read: ElementRef, static: false }) headerMenuBtn: ElementRef;
  constructor(
    private authService: AuthService,
    private themeService: ThemeService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.authService.username.subscribe((name) => {
      this.username = name;
    });
    this.isDarkMode = this.themeService.getSelectedTheme() === LayoutTheme.Dark;
  }

  /**
   * Event handler for menu button click
   */
  onToggleMenuBtn() {
    if (!this.lockFunc) {
      this.toggleMenu();
    }
  }

  /**
   * Toggles the menu
   */
  toggleMenu() {
    this.hideMenu = !this.hideMenu;

    if (!this.hideMenu) {
      this.headerMenu.nativeElement.focus();
    } else {
      document.getElementById('dent-main').focus();
    }

    clearTimeout(this.lockTimeout);
    this.lockTimeout = setTimeout(() => {
      this.lockFunc = false;
    }, 500);
  }

  /**
   * Handles the blur event of the menu
   * @param event Event data
   */
  onBlur(event: any) {
    let clickedOnMain = document.getElementById('dent-main').contains(event.relatedTarget),
      clickedInsideMenu = this.headerMenu.nativeElement.contains(event.relatedTarget);

    if ((clickedOnMain || !clickedInsideMenu) && !!event.relatedTarget) {
      this.lockFunc = true;
      this.toggleMenu();
    }
  }

  /**
   * Handles the logout button press event
   */
  onLogoutPress() {
    this.toggleMenu();
    this.authService.logout();
  }

  /**
   * Handles the dark mode toggle change event
   */
  onDarkModeSlideToggled(event: MatSlideToggleChange) {
    this.isDarkMode = event.checked;
    this.themeService.switchTheme(this.isDarkMode);
    this.headerMenu.nativeElement.focus();
  }

  /**
   * Event handler for language change event
   * @param langKey ISO 639-1 key of the language
   */
  onChangeLang(langKey: string) {
    if (this.translateService.currentLang !== langKey) {
      this.translateService.use(langKey);
    }
  }

  /**
   * Makes the languages enum accessable in the template
   */
  get languages() {
    return Languages;
  }
}
