<div class="dent-fileUpload">
  <div id="dropContainer" class="dent-drop-container" appDnd (fileDropped)="onFileDropped($event)">
    <mat-icon>publish</mat-icon>
    <span class="dent-fileUpload-fstDisclaimer">{{ 'FILEUPLOAD.CONTENT1' | translate }}</span>
    <span class="dent-fileUpload-sndDisclaimer">{{ 'FILEUPLOAD.CONTENT2' | translate }}</span>
    <label class="dent-customButton-accent" for="fileDropRef">{{
      'FILEUPLOAD.CONTENT3' | translate
    }}</label>
    <input
      type="file"
      #fileDropRef
      id="fileDropRef"
      multiple
      (change)="fileBrowseHandler($event)"
      accept="{{ explorerPreSelection }}"
    />
    <app-waiting-spinner
      [showWaitingSpinner]="showWaitingSpinner"
      [overlayFullPage]="false"
    ></app-waiting-spinner>
  </div>
</div>
