<div class="outer-container" id="outerContainer">
  <app-file-upload
    [hidden]="imageUploaded"
    (fileUploaded)="onFileUploaded($event)"
    accept="JPEG"
    [fileUploadFinished]="imageUploaded"
  ></app-file-upload>

  <div
    [hidden]="!imageUploaded"
    class="renderer-container"
    #rendererContainer
    (pointerup)="onPointerUp($event)"
    (pointerdown)="onPointerDown($event)"
    (pointermove)="onPointerMove($event)"
    (pointerout)="onPointerOut($event)"
  ></div>
</div>

<app-confirmation-dialog> </app-confirmation-dialog>
<app-waiting-spinner [showWaitingSpinner]="showWaitingSpinner"></app-waiting-spinner>
