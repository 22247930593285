import { Vector3 } from 'three';

export class Mark {
  /**
   * creates an instance of a mark
   * @param {string} id the uuid of the mark
   * @param {Typ} typ decides if the mark is a calibrationmark or a landmark
   * @param {string} shortform technical shortcut of the mark
   * @param {Group} group decides in which area and on which object the mark is set
   * @param {string} imgUri mark specific picture to be shown in the practice mode
   * @param {Vector3} position the postion of the mark as a 3D Vector
   * @param {boolean} set the propertie to know if the marke is set or not
   * @param {boolean} visible the propertie to know if the mark is visible or not
   */
  constructor(
    public id: string,
    public readonly typ?: Typ,
    public readonly group?: Group,
    public readonly imgUri?: string,
    public position?: Vector3,
    public set: boolean = false,
    public visible: boolean = false,
  ) {}

  /**
   * duplicates this mark
   * @returns {Mark}
   */
  copy(): Mark {
    return new Mark(
      this.id,
      this.typ,
      this.group,
      this.imgUri,
      this.position,
      this.set,
      this.visible,
    );
  }

  /**
   * updates the mark when the corresponding point is set
   * @param {Vector3} position the position from the mark which is set in the scene
   * @returns {void}
   */
  setMark(positon: Vector3): void {
    this.set = true;
    this.visible = true;
    this.position = positon;
  }

  /**
   * clears the properties to unset an mark
   * @returns {void}
   */
  unsetMark(): void {
    this.set = false;
    this.visible = false;
    this.position = null;
  }

  /**
   * set the visibility to ture
   * @returns {void}
   */
  showMark(): void {
    this.visible = true;
  }

  /**
   * set the visibility to false
   * @returns {void}
   */
  hideMark(): void {
    this.visible = false;
  }
}

export enum Typ {
  CALIBRATION = 'CALIBRATION',
  LAND = 'LAND',
}

export enum Group {
  RULER = 'RULER',
  SKULL = 'SKULL',
  JAW = 'JAW',
  TOOTH = 'TOOTH',
  SOFTPART = 'SOFTPART',
  HELPER = 'HELPER',
}
