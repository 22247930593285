import { Component, OnDestroy, OnInit } from '@angular/core';
import { RulerService } from '../../services/ruler.service';

@Component({
  selector: 'app-ruler',
  templateUrl: './ruler.component.html',
  styleUrls: ['./ruler.component.sass'],
})
export class RulerComponent implements OnInit, OnDestroy {
  visibility = false;
  cameraSelected = true;
  isTranslate = true;

  constructor(readonly rulerService: RulerService) {}

  ngOnInit(): void {
    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === ' ') {
        this.cameraSelected = !this.cameraSelected;
      }
    });
  }

  /**
   * switch visibility of ruler and change button appearance
   */
  onVisibility() {
    this.visibility = !this.visibility;
    this.rulerService.visible(this.visibility);
  }

  /**
   * switch controls to ruler and if not visible display the ruler, switch appearance of corresponding button
   */
  onControlSelection(): void {
    this.cameraSelected = !this.cameraSelected;
    if (!this.cameraSelected && !this.visibility) {
      this.onVisibility();
    }
    this.rulerService.setControl(!this.cameraSelected);
  }

  /**
   * toggle ruler control mode between translate and rotate
   * @param mode
   */
  onModeSelection(mode: string): void {
    this.rulerService.transformControls.setMode(mode);
    this.isTranslate = !this.isTranslate;
  }

  ngOnDestroy(): void {
    this.rulerService.dispose();
  }
}
