import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginErrorStateMatcher } from '../../utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-login-logout',
  templateUrl: './login-logout.component.html',
  styleUrls: ['./login-logout.component.sass'],
})
export class LoginLogoutComponent implements OnInit {
  showError = false;
  errorMsg: string;
  errorMsgSub = new Subject<string>();
  showLoadingSpinner = false;
  loginForm: FormGroup;
  matcher = new LoginErrorStateMatcher();

  constructor(private authService: AuthService, private translateService: TranslateService) {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.errorMsgSub.subscribe((msg) => {
      this.showErrorContainer(msg);
    });

    this.authService.failedLoginResp.subscribe((res: HttpErrorResponse) => {
      this.pickErrorMsg(res.status);
    });

    this.authService.loginState.subscribe(() => {
      this.showLoadingSpinner = false;
    });
  }

  /**
   * Handles the login form submit event
   * @public
   */
  onLogin() {
    if (this.loginForm.valid) {
      this.showLoadingSpinner = true;
      let inputValues = this.loginForm.value;
      this.authService.login(inputValues.username, inputValues.password);
    }
  }

  /**
   * Picks the corresponding error message to a error response
   * @param status error code
   * @returns error message subject
   */
  pickErrorMsg(status: number) {
    switch (status) {
      case 401:
        this.translateService.get('ERRORMESSAGES.NOT_AUTHORIZED').subscribe((message: string) => {
          this.errorMsgSub.next(message);
        });
        break;
      default:
        this.translateService.get('ERRORMESSAGES.NO_CONNECTION').subscribe((message: string) => {
          this.errorMsgSub.next(message);
        });
    }
  }

  /**
   * Shows the error message
   * @param message the error message to show
   */
  showErrorContainer(message: string) {
    this.showError = true;
    this.errorMsg = message;
  }
}
