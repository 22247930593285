import { Injectable } from '@angular/core';
import { Jaw } from '../types';
import { Subject } from 'rxjs';
import { BufferAttribute, BufferGeometry } from 'three';
import { Tuple2 } from 'src/app/core';

@Injectable({
  providedIn: 'root',
})
export class SplittingService {
  splitFinish = new Subject<Jaw>();
  constructor() {}

  /**
   * splits the blob of any stl and calls next on splitFinish with Blobresults
   * @param input
   */
  splitGeneric(input: Blob): void {
    if (typeof Worker !== undefined) {
      const worker = new Worker(`../workers/generic-split.worker`, {
        type: 'module',
      });
      worker.onmessage = (event: MessageEvent<TupleOutput | GroupedASCIIOutput>) => {
        const result = event.data;
        const jaw = isGroupedASCIIOutput(result)
          ? this.makeJawFromGroupedASCII(result)
          : this.makeJaw(result);
        if (result.ascii) {
          jaw.ascii = result.ascii;
        }
        this.splitFinish.next(jaw);
      };
      worker.postMessage(input);
    }
  }

  makeJaw(jaw: TupleOutput): Jaw {
    if (jaw.one === undefined) {
      const bufferGeometry = this.makeBufferGeometry(jaw.two);
      return new Jaw(bufferGeometry, undefined);
    }
    if (jaw.two === undefined) {
      const bufferGeometry = this.makeBufferGeometry(jaw.one);
      return new Jaw(bufferGeometry, undefined);
    }
    const triangleTuple = new Tuple2(jaw.one, jaw.two);
    const bufferGeometry = triangleTuple.forBoth(this.makeBufferGeometry);
    return new Jaw(bufferGeometry.one, bufferGeometry.two);
  }

  makeBufferGeometry(trianglesObject: TriangleCollection): BufferGeometry {
    const triangles = Object.values(trianglesObject);
    const { length } = triangles;
    const positionArray = new Float32Array(length * 9);
    const normalArray = new Float32Array(length * 9);
    for (let i = 0; i < triangles.length; i++) {
      const triangle = triangles[i];
      positionArray.set(triangle[0], i * 9);
      positionArray.set(triangle[1], i * 9 + 3);
      positionArray.set(triangle[2], i * 9 + 6);

      normalArray.set(triangle.normal, i * 9);
      normalArray.set(triangle.normal, i * 9 + 3);
      normalArray.set(triangle.normal, i * 9 + 6);
    }
    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new BufferAttribute(positionArray, 3));
    geometry.setAttribute('normal', new BufferAttribute(normalArray, 3));
    return geometry;
  }

  makeJawFromGroupedASCII(jaw: GroupedASCIIOutput): Jaw {
    const jawTuple = new Tuple2(jaw.one, jaw.two);
    const meshTuple = jawTuple.forBoth(this.groupedToGeometry);
    return new Jaw(meshTuple.one, meshTuple.two);
  }

  groupedToGeometry(grouped: { normal: Float32Array; position: Float32Array }): BufferGeometry {
    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new BufferAttribute(grouped.position, 3));
    geometry.setAttribute('normal', new BufferAttribute(grouped.normal, 3));
    return geometry;
  }
}

interface Triangle {
  0: [number, number, number];
  1: [number, number, number];
  2: [number, number, number];
  p1: string;
  p2: string;
  p3: string;
  normal: [number, number, number];
}

interface TriangleCollection {
  [key: number]: Triangle;
}
interface TupleOutput {
  one: TriangleCollection;
  two: TriangleCollection;
  ascii?: true;
}

interface GroupedASCIIOutput {
  one: { normal: Float32Array; position: Float32Array };
  two: { normal: Float32Array; position: Float32Array };
  ascii?: true;
}

const isGroupedASCIIOutput = (
  data: TupleOutput | GroupedASCIIOutput,
): data is GroupedASCIIOutput => {
  return 'position' in data.one;
};
