import { Injectable } from '@angular/core';
import { Store } from 'src/app/core';
import { Mesh, MeshPhongMaterial, Object3D, PerspectiveCamera, Vector2, Vector3 } from 'three';
import { CameraService } from './camera.service';
import { disposeIfPossible } from './helper.service';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

@Injectable({
  providedIn: 'root',
})
export class RulerService {
  ruler: Mesh;
  transformControls: TransformControls;
  isSelected = false;
  xPos: number;
  yPos: number;
  touches = new Store<Touch>();
  clicks = new Vector2();
  edgeCollection = new Object3D();

  constructor(readonly camera: CameraService) {}

  async initializeRuler(camera: PerspectiveCamera, domElement: HTMLCanvasElement) {
    this.ruler = await createRuler();
    this.ruler.rotateOnWorldAxis(new Vector3(1, 0, 0), -Math.PI / 2);
    this.ruler.visible = false;
    this.ruler.name = 'ruler';
    this.transformControls = new TransformControls(camera, domElement);
    this.transformControls.attach(this.ruler);
    this.transformControls.enabled = false;
    this.transformControls.visible = false;
    this.transformControls.setSize(0.75);
  }

  /**
   * total reset of this service
   */
  dispose(): void {
    disposeIfPossible(this.ruler);
    disposeIfPossible(this.transformControls);
  }

  /**
   * switch visibility to visible. True makes it visible
   * @param visible
   */
  visible(visible: boolean): void {
    this.ruler.visible = visible;
    this.transformControls.visible = visible;
  }

  /**
   * switches between this being controlled or camera being controlled
   * @param isControlled
   */
  setControl(isControlled: boolean) {
    this.transformControls.enabled = isControlled;
    this.camera.enableControls(!isControlled);
  }

  center() {
    const axes = readRotationSteps(this.transformControls);
    axes.forEach((axis) => {
      this.snapOnAxis(axis);
    });
  }

  snapOnAxis(axis: Axis) {
    const { rotation } = this.ruler;
    switch (axis) {
      case 'X':
        rotation.x = this.snapOnAxisReducer(rotation.x);
        break;
      case 'Y':
        rotation.y = this.snapOnAxisReducer(rotation.y);
        break;
      case 'Z':
        rotation.z = this.snapOnAxisReducer(rotation.z);
        break;
    }
  }

  snapOnAxisReducer(rotation: number): number {
    if (Math.abs(rotation) < Math.PI / snapPrecision) return 0; // 0
    if (Math.abs(rotation - Math.PI / 2) < Math.PI / snapPrecision) return Math.PI / 2; // PI/2
    if (Math.abs(Math.abs(rotation) - Math.PI) < Math.PI / snapPrecision) return Math.PI; //  PI
    if (Math.abs(rotation + Math.PI / 2) < Math.PI / snapPrecision) return -Math.PI / 2; // PI*(3/2)
    return rotation;
  }

  onKeydown(key: string) {
    switch (key.toLowerCase()) {
      case 'w':
        this.transformControls.setMode('translate');
        break;

      case 'e':
        this.transformControls.setMode('rotate');
        break;

      case 'r':
        this.transformControls.setMode('scale');
        break;

      case 'Add':
      case '+': // +, num+
        this.transformControls.setSize(this.transformControls.size + 0.1);
        break;

      case 'Subtract':
      case '-': // -, num-
        this.transformControls.setSize(Math.max(this.transformControls.size - 0.1, 0.1));
        break;

      case 'x':
        this.transformControls.showX = !this.transformControls.showX;
        break;

      case 'y':
        this.transformControls.showY = !this.transformControls.showY;
        break;

      case 'z':
        this.transformControls.showZ = !this.transformControls.showZ;
        break;

      case ' ':
        this.transformControls.enabled = !this.transformControls.enabled;
        break;
      case 'c':
        this.center();
        break;
    }
  }
}
/**
 * creates basic Ruler
 */
const createRuler = async (): Promise<Mesh> => {
  const loader = new STLLoader();
  const bufferGeometry = await loader.loadAsync('./assets/ruler.stl');
  return new Mesh(bufferGeometry, new MeshPhongMaterial({ color: 0x81acc2 }));
};

/**
 * turns the position information of a touch into a Vector2
 * @param touch
 */
const toVector2 = (touch: Touch): Vector2 => {
  return new Vector2(touch.clientX, touch.clientY);
};

const axes: Axis[] = ['X', 'Y', 'Z'];
type Axis = 'X' | 'Y' | 'Z';

const readRotationSteps = (controls: TransformControls): Axis[] => {
  return axes.filter((axis) => controls[`show${axis}`] === true);
};

const snapPrecision = 15;
