export enum LayoutStructure {
  Small = 'dent-layoutS',
  Medium = 'dent-layoutM',
  Large = 'dent-layoutL',
}

export enum LayoutTheme {
  Dark = <any>'dark-theme',
  Light = <any>'light-theme',
}
