import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ENTRY_PATHS, HOME_PATH, LOGIN_PATH } from '../../constants';
import { RouterHelpers } from '../../utils/routerHelpers';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
  @Output()
  readonly darkModeSwitched = new EventEmitter<boolean>();
  isOnLoginPage = false;
  navigateBackVisible = false;
  routerHelpers = new RouterHelpers();

  // is router intentionally not readonly or private?
  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let currentRoute = router.url;
        this.navigateBackVisible = this.routerHelpers.anyContainedInURL(ENTRY_PATHS, currentRoute);
        this.isOnLoginPage = this.routerHelpers.containedInURL(LOGIN_PATH, currentRoute);
      }
    });
  }

  ngOnInit(): void {}

  /**
   * Event handler for dark mode toggle
   */
  onDarkModeToggled() {
    this.darkModeSwitched.emit();
  }

  /**
   * navigates to home
   */
  navigateToHome() {
    this.router.navigateByUrl(`/${HOME_PATH}`);
  }
}
