import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LayoutTheme } from '../enums';
import { OverlayContainer } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private selectedTheme: LayoutTheme;
  appliedThemeObserver = new Subject<LayoutTheme>();

  constructor(private OverlayContainer: OverlayContainer) {
    this.setThemeToDefault();
  }
  /**
   * sets the Themetype depending on isDark
   * @param isDark userSelection
   * @public
   * @returns {void}
   */
  switchTheme(isDark: boolean): void {
    this.setSelectedTheme(isDark ? LayoutTheme.Dark : LayoutTheme.Light);
    this.saveThemeInStorage();
  }

  /**
   * sets the themeType by Type
   * @param theme
   * @public
   * @returns {void}
   */
  setSelectedTheme(theme: LayoutTheme): void {
    this.selectedTheme = theme;
    const overlayContainerClasses = this.OverlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) =>
      item.includes('-theme'),
    );
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(theme.toString());
    this.appliedThemeObserver.next(theme);
  }

  /**
   * sets the ThemeType back to default
   * @public
   * @returns {void}
   */
  setThemeToDefault(): void {
    this.setSelectedTheme(LayoutTheme.Light);
  }

  /**
   * returns the current Themetype
   * @public
   * @returns {LayoutTheme}
   */
  getSelectedTheme(): LayoutTheme {
    return this.selectedTheme;
  }

  /**
   * save the current theme type to the local browser storge
   * @public
   * @returns {void}
   */
  saveThemeInStorage(): void {
    window.localStorage.setItem('selectedTheme', this.selectedTheme.toString());
  }
  /**
   * sets the theme type to the value saved in the local browser storge
   * @public
   * @returns {void}
   */
  reloadThemeFromStorage() {
    let theme = window.localStorage.getItem('selectedTheme');
    if (theme) {
      this.setSelectedTheme((theme as unknown) as LayoutTheme);
    }
  }
}
