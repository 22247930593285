import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';

import { Analyzer2dComponent } from './analyzer2d.component';
import { FileUploadModule } from '../file-upload';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [Analyzer2dComponent, ToolbarComponent],
  imports: [
    CommonModule,
    FileUploadModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule,
    SharedModule,
    MatSnackBarModule,
  ],
  exports: [Analyzer2dComponent, ToolbarComponent],
})
export class Analyzer2dModule {}
