export class Store<T> {
  private store: { [key: string]: T } = {};

  getValue(key: string | number): T | undefined {
    const keyS = typeof key === 'string' ? key : key.toString();
    if (this.store.hasOwnProperty(keyS)) {
      return this.store[keyS];
    }
  }

  setValue(key: string | number, value: T): void {
    const keyS = typeof key === 'string' ? key : key?.toString();
    if (keyS in this.store && !this.store.hasOwnProperty(keyS)) {
      return;
    }
    this.store[keyS] = value;
  }

  hasOwnProperty(key: string): boolean {
    return this.store.hasOwnProperty(key);
  }

  delete(key: string): T {
    let deleted;
    if (this.store.hasOwnProperty(key)) {
      deleted = this.store[key];
      delete this.store[key];
    }
    return deleted;
  }

  toArray(): Array<T> {
    return Object.values(this.store);
  }
}
