import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { FileUploadComponent } from './file-upload.component';
import { SharedModule } from '../../shared';
import { DndDirective } from './direcitves/dnd.directive';
@NgModule({
  declarations: [FileUploadComponent, DndDirective],
  imports: [
    BrowserModule,
    CommonModule,
    MatIconModule,
    SharedModule,
    TranslateModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  exports: [FileUploadComponent, DndDirective],
  providers: [],
  bootstrap: [FileUploadComponent],
})
export class FileUploadModule {}
