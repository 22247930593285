import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import {
  loginRequestPath,
  returnUrlQueryParamName,
  loginReqClientId,
  tokenIssuer,
  LOGIN_PATH,
  HOME_PATH,
} from '../constants';
import { Roles, CookieNames, TokenProperties } from '../enums';
import { CookieService } from './cookie.service';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

const tokenPropertyName = 'access_token';

@Injectable({ providedIn: 'root' })
export class AuthService {
  username: BehaviorSubject<string> = new BehaviorSubject<string>('');
  failedLoginResp: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();
  loginState: Subject<boolean> = new Subject();
  validToken = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private route: ActivatedRoute,
  ) {
    if (this.isLoggedIn) {
      this.refetchUsername();
    }
  }

  /**
   * Logs a user in
   * @public
   * @param username - The username
   * @param password - The password
   */
  login(username: string | undefined, password: string | undefined) {
    if (username !== 'demoKiel' || password !== 'bertramWS22') {
      this.failedLoginResp.next(new HttpErrorResponse({ status: 401 }));
      this.loginState.next(false);
      return;
    }

    const encoded = 'test';
    const decoded = {};
    const todayTime = new Date().getTime();
    // expired in 1 day
    decoded[TokenProperties.TOKEN_PROP_EXPIRATION] = todayTime + 12 * 60 * 60 * 1000;
    this.cookieService.saveAuth(encoded, decoded);

    this.username.next('Max Mustermann');
    this.route.queryParams.pipe(first()).subscribe((params) => {
      if (params[returnUrlQueryParamName]) {
        const redirectPath = params[returnUrlQueryParamName];
        this.router.navigateByUrl(redirectPath);
      } else {
        this.router.navigateByUrl('/' + HOME_PATH);
      }
      this.loginState.next(true);
    });
  }

  /**
   * Decodes a bearer token
   * @public
   * @param encodedToken - The encoded token
   * @returns The encoded bearer token
   */
  getDecodedToken(encodedToken: string) {
    return JSON.parse(atob(encodedToken.split('.')[1]));
  }

  /**
   * Logs the current user out
   * @public
   */
  logout() {
    this.cookieService.removeCookies();
    this.router.navigateByUrl('/' + LOGIN_PATH);
  }

  /**
   * Validates the claims for the jwt
   */
  validateToken() {
    const endcodedToken = this.cookieService.getCookie(CookieNames.COOKIE_TOKEN),
      decodedToken = this.getDecodedToken(endcodedToken);

    const jwtRegex = new RegExp('^[a-zA-Z0-9-_]+?.[a-zA-Z0-9-_]+?.([a-zA-Z0-9-_]+)?$');

    let matchesRegex = jwtRegex.test(endcodedToken),
      correctIssuer = decodedToken[TokenProperties.TOKEN_PROP_ISSUER] == tokenIssuer,
      correctCliendId = decodedToken[TokenProperties.TOKEN_PROP_CLIENT] == loginReqClientId,
      hasName = decodedToken[TokenProperties.TOKEN_PROP_USERNAME].length > 0,
      hasGroups = decodedToken[TokenProperties.TOKEN_PROP_ROLES].length > 0,
      hasExpiration = decodedToken[TokenProperties.TOKEN_PROP_EXPIRATION] > 0;

    this.validToken =
      matchesRegex && correctIssuer && correctCliendId && hasName && hasGroups && hasExpiration;

    if (!this.validToken) {
      this.logout();
    }
  }

  /**
   * Refetchs the username
   */
  refetchUsername() {
    this.username.next('Max Mustermann');
  }

  /**
   * Checks if user has admin role
   * @return true, if a user has admin role
   *         else false.
   */
  get isAdmin() {
    const rolesStr = this.cookieService.getRoles();
    if (rolesStr === undefined) {
      return false;
    }

    const rolesArray = rolesStr.split(',');
    return rolesArray !== undefined && rolesArray.includes(Roles.ROLE_ADMIN);
  }

  /**
   * Gets the login status
   */
  get isLoggedIn() {
    return !!this.cookieService.getCookie(CookieNames.COOKIE_TOKEN);
  }
}
