<div class="dent-home-content" role="main">
  <img
    class="dent-home-logo"
    src="./assets/icons/Logo_standalone.png"
    alt="Dentalyzer Logo"
    height="70"
    width="70"
  />
  <h1>{{ 'HOME.TITLE' | translate }}</h1>
  <p>{{ 'HOME.SUBTITLE' | translate }}</p>
  <div class="dent-home-buttons-wrapper">
    <button mat-raised-button class="dent-home-button" routerLink="/{{ frsAnalysisPath }}">
      {{ 'ANALYSIS.2D' | translate }}
    </button>
    <button mat-raised-button class="dent-home-button" routerLink="/{{ modelAnalysisPath }}">
      {{ 'ANALYSIS.3D' | translate }}
    </button>
  </div>
</div>
<div class="dent-img-content">
  <img
    src="./assets/icons/KielLogo.png"
    width="25%"
    alt="Logo Universitätsklinikum Heidelberg"
    class="dent-unihd-logo"
  />
</div>

<router-outlet></router-outlet>
