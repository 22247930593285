/*
 * First Level Paths of the Webpage
 */

export const HOME_PATH = 'home';
export const FRS_ANALYSIS_PATH = 'frsanalysis';
export const MODEL_ANALYSIS_PATH = 'modelanalysis';
// export const EVALUATION_PATH = 'evaluate';
export const LOGIN_PATH = 'login';

export const ENTRY_PATHS = [HOME_PATH, LOGIN_PATH];
