export enum FileTypes {
  STL = '.stl',
  JPEG = 'image/jpeg',
}
export namespace FileTypes {
  /**
   * creates regex, that matches filenames with fileType
   * @param fileType the fileType ´, e.g .stl
   * @param flags regexp flags like 'i', 'g' aso.
   * @returns {RegExp}
   * @public
   */
  export function toRegex(fileType: string, flags?: string): RegExp {
    return new RegExp(`.*${fileType}$`, flags);
  }
  /**
   * checks if file has accepted Type
   * @param file the file you have
   * @param accept the enumtype it should have
   * @returns {boolen}
   * @public
   */
  export function acceptFileType(file: File, accept: string): boolean {
    if (file.type) {
      return isFileType(file.type, accept);
    }
    return hasFileEndingInName(file.name, accept);
  }
  /**
   * checks if filename matches certain fileType
   * @param name the fileName
   * @param match the enumType to check
   * @returns {boolean}
   * @private
   */
  function hasFileEndingInName(name: any, match: string): boolean {
    return toRegex(FileTypes[match], 'i').test(name);
  }
  /**
   * checks if FileType matches
   * @param type the actual fileType, e.g 'image/jpeg'
   * @param match the enumType it should match, e.g. JPEG
   * @returns {boolean}
   * @public
   */
  function isFileType(type: any, match: string): boolean {
    return type == FileTypes[match];
  }
}
