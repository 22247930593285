<div *ngIf="!childRoutesActivated" class="dent-analysis" fxLayout="row">
  <div class="dent-analyse-container">
    <app-analyzer2d #analyzer2d *ngIf="showFRSAnalysis; else showModelAnalyse"></app-analyzer2d>
    <ng-template #showModelAnalyse>
      <app-analyzer3d #analyzer3d></app-analyzer3d>
    </ng-template>
  </div>
  <div class="dent-sidebar-container" fxLayout="column">
    <label class="dent-analysisLabel">{{ 'ANALYSIS.' + analysisTitle | translate }}</label>
    <app-tool-tabs class="dent-tabs" [tabs]="analysisToolTabs"></app-tool-tabs>
    <div class="dent-tools" #dentAnalysisScrollContainer id="{{ scrollContainerId }}">
      <app-toolbar *ngIf="showFRSAnalysis; else showModelSidebar"></app-toolbar>
      <ng-template #showModelSidebar>
        <app-model-sidebar scrollContainerRef="{{ scrollContainerId }}"></app-model-sidebar>
      </ng-template>
    </div>
    <app-option-button-group
      [options]="analysisOptions"
      class="dent-options"
    ></app-option-button-group>
  </div>
</div>
<router-outlet
  (activate)="childRoutesActivated = true"
  (deactivate)="childRoutesActivated = false"
></router-outlet>
