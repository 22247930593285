import { Injectable, ElementRef } from '@angular/core';

import {
  AmbientLight,
  Color,
  HemisphereLight,
  Mesh,
  PointLight,
  Scene,
  Vector3,
  WebGLRenderer,
  Object3D,
  Vector2,
  MeshPhongMaterial,
  SphereBufferGeometry,
  Intersection,
} from 'three';
import { Edge, VectorGrap3d } from '../model';
import { Jaw, Landmark, readNameId } from '../types';
import { DisciplineInfoService } from './DisciplineInfo.service';
import { v4 } from 'uuid';
import { ButtonService } from '../../analysis/services';
import { RulerService } from './ruler.service';
import { CameraService } from './camera.service';
import { disposeIfPossible, normalizeMousePosition, setLightWithPosition } from './helper.service';
import { LayoutTheme, ThemeService } from 'src/app/core';
import {
  upperJaw as upperJawButton,
  lowerJaw as lowerJawButton,
  showBoth,
} from '../../analysis/models';
import { Subject } from 'rxjs';

export enum AnalysisState {
  NONE = 'NONE',
  UPPER = 'UPPER',
  LOWER = 'LOWER',
  BOTH = 'BOTH',
}

export enum SceneColor {
  Dark = '#01141e',
  Light = '#ffffff',
}

const defaultCanvasSize = {
  offsetHeight: 600,
  offsetWidth: 800,
};

@Injectable({
  providedIn: 'root',
})
export class SceneControlService {
  renderer: WebGLRenderer;
  scene: Scene;
  jaw: Jaw;
  analysisState = AnalysisState.UPPER;
  vectorGraph = new VectorGrap3d();
  _landmark: Landmark;
  moved = 0;
  objectInitCallback: (jaw: Jaw) => void;
  animationFrame: number;
  firstEdgeSet = new Subject<string>();

  get landmark(): Landmark {
    return this._landmark;
  }

  set landmark(value: Landmark) {
    if (this._landmark !== undefined && value !== undefined) {
      this.removeFromStorable(this.landmark.name);
    }
    this._landmark = value;
  }

  constructor(
    readonly infoService: DisciplineInfoService,
    readonly buttonService: ButtonService,
    readonly themeService: ThemeService,
    readonly ruler: RulerService,
    readonly camera: CameraService,
  ) {
    this.infoService.currentDeleteEdge.subscribe((id) => {
      this.deleteEdge(id);
    });

    this.buttonService.onUpperJaw.subscribe(() => {
      this.switchToUpperState();
    });
    this.buttonService.onlowerJaw.subscribe(() => {
      this.switchToLowerState();
    });
    this.buttonService.onShowBoth.subscribe(() => {
      this.switchToBothState();
    });
    this.themeService.appliedThemeObserver.subscribe((theme) => {
      if (this.scene) {
        this.scene.background = new Color(SceneColor[LayoutTheme[theme]]);
      }
    });
  }

  /**
   * call to start animation of the scene on the canvas
   * @returns {void}
   */
  animate(): void {
    this.renderer?.render(this.scene, this.camera.camera);
    this.animationFrame = requestAnimationFrame(() => this.animate());
  }

  /**
   * initializes jaw objects
   * @param jawObject the jaw to render
   * @returns {void}
   */
  initObjects(jaw: Jaw): void {
    this.jaw = jaw;
    if (this.scene === undefined) {
      this.objectInitCallback = this.initObjects;
      return;
    }
    const { upper, lower } = this.jaw;
    if (upper !== undefined && lower !== undefined) {
      this.analysisState = AnalysisState.BOTH;
      this.initBothJawObject(upper, lower);
    } else if (upper !== undefined) {
      this.analysisState = AnalysisState.UPPER;
      this.initUpperJawObject(upper);
    } else if (lower !== undefined) {
      this.analysisState = AnalysisState.LOWER;
      this.initLowerJawObject(lower);
    }
    this.setSceneLights(jaw.ascii);
    this.centerCamera();
    this.updateTabButtons();
  }

  /**
   * initializes the three.js view for modelanalysis in light mode. Starts animation
   * @returns {void}
   */
  initScene(rendererContainer: ElementRef): void {
    const analyzerContainer = document.getElementById('idContainer-modelAnalyzer');
    const { offsetHeight, offsetWidth } = analyzerContainer ?? defaultCanvasSize;

    // renderer
    this.renderer = new WebGLRenderer({ antialias: true });
    const renderer = this.renderer;
    renderer.setSize(offsetWidth, offsetHeight);
    // remove outline onFocus canvas
    renderer.domElement.style.outline = 'none';
    rendererContainer.nativeElement.appendChild(renderer.domElement);

    // scene
    this.scene = new Scene();
    const scene = this.scene;
    scene.background = new Color(SceneColor[LayoutTheme[this.themeService.getSelectedTheme()]]);

    // camera
    const camera = this.camera.createCamera(offsetWidth, offsetHeight);
    scene.add(camera);

    // controls
    const controls = this.camera.createControls(renderer.domElement);
    //prevent placement of Landmarks if click is for moving
    controls.addEventListener('change', () => {
      this.moved++;
    });
    renderer.render(scene, camera);

    this.ruler.initializeRuler(camera, renderer.domElement).then(() => {
      scene.add(this.ruler.ruler);
      scene.add(this.ruler.transformControls);
      this.ruler.transformControls.addEventListener('objectChange', () => {
        this.moved++;
      });
      scene.add(this.ruler.edgeCollection);
    });

    this.animate();

    if (this.objectInitCallback !== undefined) {
      this.objectInitCallback(this.jaw);
      this.objectInitCallback = undefined;
    }
  }

  setSceneLights(ascii?: true) {
    // lights
    // illuminate everything in general
    if (ascii) {
      this.scene.add(new HemisphereLight(0xffffff, 0x555555, 1));
      return;
    }
    setLightWithPosition(this.scene, new AmbientLight(0xffffff, 0.5));
    // illuminate the scene from (0,0,0) this makes it much better somehow
    this.scene.add(new PointLight('#fff', 0.5));
    //lighter illumination from below
    setLightWithPosition(
      this.scene,
      new HemisphereLight(0x111111, 0x888888, 0.2),
      new Vector3(0, 50, 0),
    );
  }

  /**
   * initializes the jawObject depending on the type
   * @param jawObject
   * @returns {void}
   */
  initUpperJawObject(jawObject: Mesh): void {
    this.addSafely(jawObject);
  }

  /**
   * initializes the jawObject depending on the type
   * @param jawObject
   * @returns {void}
   */
  initLowerJawObject(jawObject: Mesh): void {
    this.addSafely(jawObject);
  }

  /**
   * initializes everything relating the jawobject
   * @param jawObject
   * @returns {void}
   */
  initBothJawObject(upperJaw, lowerJaw): void {
    this.addSafely(upperJaw, lowerJaw);
  }

  /**
   * places a Circle in the Scene at the point of intersection and saves a landmark either in
   * this.landmark or as part of an Edge in this.vectorGraph and updates the model-sidebar information
   * deletes resets this.landmark if another edge was selected
   * @param mouseX clientX position
   * @param mouseY clientY position
   */
  placeLandmark(mouseX: number, mouseY: number): void {
    // sort Edges into a bothObject?
    if (this.moved > 2) {
      this.moved = 0;
      return;
    }
    if (
      // prevent placement if already placed
      this.doesEdgeExist()
    ) {
      return;
    }

    const { pointvector, storeable } = this.determinePlacement(mouseX, mouseY);

    let id: string;
    this.infoService.currentSelection.subscribe((selectedId) => {
      id = selectedId;
    });

    const disciplineEdge = this.infoService.getDisciplineEdge(id);
    const [first, second] = disciplineEdge.disciplineLandmarks;

    if (this.landmark === undefined || this.landmark.name !== first) {
      this.landmark = makeLandmark(pointvector, storeable, first);
      this.firstEdgeSet.next(id);
    } else {
      const secondLandmark = makeLandmark(pointvector, storeable, second);
      const edge = new Edge(id, [this.landmark, secondLandmark], readNameId(disciplineEdge));
      storeable.add(edge.toCylinder());

      this.vectorGraph.addEdge(edge);
      this.infoService.updateDisciplineEdgeLength(edge);
      this.infoService.update();
      this.firstEdgeSet.next(null);
      this.landmark = undefined;
    }
  }

  /**
   * determines the vector at with the intersection happens and returns the vector and the object to add the marks to
   * @param mouseX
   * @param mouseY
   * @returns
   */
  determinePlacement(mouseX: number, mouseY: number) {
    const normalizedMouse = normalizeMousePosition(mouseX, mouseY, this.renderer.domElement);

    const intersection = this.getIntersection(normalizedMouse);
    if (intersection === undefined) {
      return;
    }

    const { point, object: intersectObject } = intersection;
    const storeable =
      intersectObject.name === 'ruler' ? this.ruler.edgeCollection : intersectObject;

    return { pointvector: point, storeable };
  }

  /**
   * gets the intersection point of the mouseposition based on analysisState
   * @param normalizedMouse normalized mouseposition relative to container
   * @returns {Intersection}
   */
  getIntersection(normalizedMouse: Vector2): Intersection | undefined {
    const raycaster = this.camera.getRaycaster(normalizedMouse);
    const intersectables = this.ruler.ruler.visible ? [this.ruler.ruler] : [];
    switch (this.analysisState) {
      // TODO intersect with ruler if visible
      case AnalysisState.UPPER:
        if (this.jaw.upper !== undefined) {
          intersectables.push(this.jaw.upper);
        }
        break;

      case AnalysisState.LOWER:
        if (this.jaw.lower !== undefined) {
          intersectables.push(this.jaw.lower);
        }
        break;

      case AnalysisState.BOTH:
        if (this.jaw.upper !== undefined && this.jaw.lower !== undefined) {
          intersectables.push(this.jaw.upper, this.jaw.lower);
        }
        break;
    }
    return raycaster.intersectObjects(intersectables)[0];
  }

  /**
   * checks if the currently selected Edge was created
   * @returns {boolean} true if edge is not undefined or id is undefined
   */
  doesEdgeExist(): boolean {
    let edge: Edge | undefined;
    let id: string | undefined;
    this.infoService.currentSelection.subscribe((selectedId) => {
      edge = this.vectorGraph.getEdge(selectedId);
      id = selectedId;
    });
    return id === undefined || edge !== undefined;
  }

  /**
   * deletes the Edge from vectorGraph, removes related Graphics from the Scene and updates the disciplineEdges length
   * to undefined
   * @param id id of the Edge to delete
   */
  deleteEdge(id: string) {
    const disciplineEdge = this.infoService.getDisciplineEdge(id);
    if (disciplineEdge === undefined) {
      return;
    }
    disciplineEdge.length = undefined;
    this.infoService.update();
    this.vectorGraph.removeEdge(id);
    this.removeFromStorable(readNameId(disciplineEdge));
    disciplineEdge.disciplineLandmarks.forEach((name) => this.removeFromStorable(name));
    this.firstEdgeSet.next(null);
  }

  /**
   * if possible removes an object from the upperJaw and lowerJaw and disposes it
   * @param name name of the object to remove from a jaw object
   */
  removeFromStorable(name: string): void {
    // TODO remove also from Both and ruler groupings
    const removal = (mesh: Object3D, name: string): void => {
      const object = mesh?.getObjectByName(name);
      mesh?.remove(object);
      disposeIfPossible(mesh);
    };
    this.jaw.forBoth((part) => {
      removal(part, name);
    });
    removal(this.ruler.edgeCollection, name);
  }

  /**
   * makes the camera look at the displayed object. Assumes object.position is the center of object, might need boundigBox though
   */
  centerCamera(): void {
    switch (this.analysisState) {
      case AnalysisState.UPPER:
        this.camera.update(this.jaw.upper);
        break;
      case AnalysisState.LOWER:
        this.camera.update(this.jaw.lower);
        break;
      case AnalysisState.BOTH:
        this.camera.update(this.jaw.upper, this.jaw.lower);
        break;
    }
  }

  /**
   * switches Analysis for display and actions with upper jaw
   */
  switchToUpperState() {
    this.analysisState = AnalysisState.UPPER;
    changeVisibility(true, this.jaw?.upper);
    changeVisibility(false, this.jaw?.lower /* , this.rulerMarks */);
  }

  /**
   * switches Analysis for display and actions with lower jaw
   */
  switchToLowerState() {
    this.analysisState = AnalysisState.LOWER;
    changeVisibility(true, this.jaw?.lower);
    changeVisibility(false, this.jaw?.upper /* , this.rulerMarks */);
  }

  /**
   * switches Analysis for display and actions with whole jaw
   */
  switchToBothState() {
    this.analysisState = AnalysisState.BOTH;
    changeVisibility(true, this.jaw?.upper, this.jaw?.lower /* , this.rulerMarks */);
  }

  /*
   * adds an object only if it does not already exist
   * @param objects
   */
  addSafely(...objects: Array<Object3D>): void {
    const scene = this.scene;
    const checkAndAdd = (object: Object3D) => {
      if (scene.getObjectById(object.id) === undefined) {
        scene.add(object);
      }
    };
    objects.forEach(checkAndAdd);
  }

  /**
   * changes the selected tooltab button to the current state
   */
  updateTabButtons() {
    switch (this.analysisState) {
      case AnalysisState.UPPER:
        this.buttonService.analysisState.next(upperJawButton.key);
        break;
      case AnalysisState.LOWER:
        this.buttonService.analysisState.next(lowerJawButton.key);
        break;
      case AnalysisState.BOTH:
        this.buttonService.analysisState.next(showBoth.key);
        break;
    }
  }

  /**
   * resets the Service as intended for new file reset
   */
  reset(): void {
    cancelAnimationFrame(this.animationFrame);
    this.scene = undefined;
    this.renderer?.dispose();
    this.camera.dispose();
    this.ruler.dispose();
  }

  dispose(): void {
    // seems to be called twice if only a half jaw was loaded
    cancelAnimationFrame(this.animationFrame);
    this.renderer?.state.reset();
    this.renderer?.dispose();
    this.jaw?.upper?.traverse(disposeIfPossible);
    this.jaw?.lower?.traverse(disposeIfPossible);
    this.jaw?.forBoth(disposeIfPossible);
    this.jaw = undefined;
    this.camera.dispose();
    this.scene?.traverse(disposeIfPossible);
    this.scene = undefined;
    this.infoService?.resetDisplayedEdges();
    this.infoService.update();
    this.vectorGraph?.reset();
    this.ruler.dispose();
  }
}

/**
 * changes visibility for the objects and it children to visibility
 * @param objects
 * @param visibility
 */
const changeVisibility = (visibility: boolean, ...objects: Array<Object3D>): void => {
  const makeVisibilityChange = (object: Object3D) => {
    if (object === undefined) {
      return;
    }
    object.visible = visibility;
  };
  const changeObjectVisibility = (object: Object3D) => {
    makeVisibilityChange(object);
    object?.traverse(makeVisibilityChange);
  };

  if (objects.length > 0) {
    objects.forEach(changeObjectVisibility);
  }
};

const sphereMaterial = new MeshPhongMaterial({ color: 0xf3c456 });

/**
 * create a Sphere
 */
export const newSphereMarker = (point = new Vector3()): Mesh<SphereBufferGeometry> => {
  const markGeometry = new SphereBufferGeometry(0.4, 14, 10);
  const sphereMesh = new Mesh(markGeometry, sphereMaterial);
  sphereMesh.position.add(point);
  return sphereMesh;
};

/**
 * create a Landmark for pointvector with name and add it to storable
 * @param pointvector
 * @param storeable
 * @param name
 * @returns
 */
const makeLandmark = (pointvector: Vector3, storeable: Object3D, name: string) => {
  // scene
  const markMesh = newSphereMarker(pointvector);
  storeable.add(markMesh);

  // vectorgraph
  const _id = v4();
  markMesh.name = name;
  return { _id, name, pointvector, edges: [] };
};
