<table
  mat-table
  [dataSource]="dataSource"
  [ngClass]="editMode ? 'dent-enabled' : 'dent-disabled'"
  class="dent-modelSidebar"
>
  <!-- Distance Column -->
  <ng-container matColumnDef="distance">
    <th mat-header-cell class="col-1" *matHeaderCellDef>{{ 'SIDEBAR.COL1' | translate }}</th>
    <td
      mat-cell
      class="col-1"
      *matCellDef="let element"
      [ngClass]="{ selected: editMode && selectedRowRef == element._id }"
      (click)="editMode && onEdgeCellClick(element)"
    >
      <ng-container *ngIf="element.length != undefined">
        <span class="dent-modelSidebar-distance">{{ element.length | number: '.2-2' }} </span
        ><span class="dent-modelSidebar-unit">mm</span>
      </ng-container>
      <button
        *ngIf="element.length == undefined"
        mat-icon-button
        color="primary"
        disabled="{{ !editMode }}"
        (click)="onEdgeCellClick(element)"
      >
        <mat-icon>linear_scale</mat-icon>
      </button>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell class="col-2" *matHeaderCellDef>{{ 'SIDEBAR.COL2' | translate }}</th>
    <td
      mat-cell
      class="col-2"
      *matCellDef="let element"
      [ngClass]="{ selected: editMode && selectedRowRef == element._id }"
      (click)="editMode && onEdgeCellClick(element)"
    >
      {{ element.technicalId | translate }}
      <span *ngIf="element.nameAttachement"> {{ element.nameAttachement }}</span>
    </td>
    <td mat-footer-cell *matFooterCellDef class="dent-tableInput">
      <input
        placeholder="{{ 'SIDEBAR.INPUT_PLACEHOLDER' | translate }}..."
        type="text"
        maxlength="30"
        [(ngModel)]="customEdgeName"
        (keyup.enter)="editMode && onAddCustomEdgeClick()"
      />
    </td>
  </ng-container>

  <!-- Remove Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'SIDEBAR.COL3' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      [ngClass]="{ selected: editMode && selectedRowRef == element._id }"
    >
      <button
        mat-icon-button
        color="primary"
        class="iconElement"
        disabled="{{ !editMode || (!isEdgeDeleteable(element) && !isCustomEdge(element)) }}"
        (click)="onEdgeDeleteClick(element)"
        [style.opacity]="isCustomEdge(element) || isEdgeDeleteable(element) ? 1 : 0.3"
      >
        <mat-icon *ngIf="!isCustomEdge(element) || element.length">delete</mat-icon>
        <mat-icon *ngIf="isCustomEdge(element) && !element.length">delete_forever</mat-icon>
      </button>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <button
        mat-icon-button
        color="primary"
        class="iconElement"
        disabled="{{ !editMode }}"
        (click)="editMode && onAddCustomEdgeClick()"
      >
        <mat-icon>add_circle_outlined</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr
    [fxHide]="!footerVisible"
    mat-footer-row
    *matFooterRowDef="displayedColumns; sticky: true"
  ></tr>

  <!-- Group header -->
  <ng-container matColumnDef="groupHeader">
    <td class="dent-modelSidebar-groupHeader" colspan="999" mat-cell *matCellDef="let group">
      <div fxLayout="row" fxLayoutAlign="none center" (click)="editMode && groupHeaderClick(group)">
        <button mat-icon-button fxFlex="10" [disabled]="!editMode">
          <mat-icon *ngIf="!group.expanded">navigate_next</mat-icon>
          <mat-icon *ngIf="group.expanded">expand_more</mat-icon>
        </button>
        <span style="font-weight: 600; cursor: pointer">{{
          'VALUEGROUP.' + group.technicalId | translate
        }}</span>
      </div>
    </td>
  </ng-container>
  <tr
    id="id-{{ row.technicalId }}"
    mat-row
    *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
  ></tr>
</table>
