import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { RouterHelpers } from 'src/app/core/utils/routerHelpers';
import { ENTRY_PATHS } from '../../core';

@Directive({
  selector: '[appBackButton]',
})
export class BackButtonDirective {
  private backButtonDisabled = false;
  private routerHelpers = new RouterHelpers();
  constructor(private router: Router) {
    this.router.events.subscribe(
      () =>
        (this.backButtonDisabled = this.routerHelpers.anyContainedInURL(ENTRY_PATHS, router.url)),
    );
  }

  @HostListener('click')
  onClick(): void {
    if (!this.backButtonDisabled) {
      this.router.navigateByUrl('/');
    }
  }
}
