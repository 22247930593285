import { Mark } from '../models/mark';
import { Edge, Location } from '../models/edge';

export const edges: Array<Edge> = [
  new Edge(
    '10000000-0000-0000-0000-000000000001',
    Location.VERTICAL,
    new Mark('00000000-0000-0000-0000-000000000001'),
    new Mark('00000000-0000-0000-0000-000000000002'),
  ),
  new Edge( // N - A
    '10000000-0000-0000-0000-000000000002',
    Location.VERTICAL,
    new Mark('00000000-0000-0000-0000-000000000004'),
    new Mark('00000000-0000-0000-0000-000000000008'),
  ),
  new Edge( // N - B
    '10000000-0000-0000-0000-000000000003',
    Location.VERTICAL,
    new Mark('00000000-0000-0000-0000-000000000004'),
    new Mark('00000000-0000-0000-0000-000000000012'),
  ),
  new Edge( // N - Me
    '10000000-0000-0000-0000-000000000004',
    Location.VERTICAL,
    new Mark('00000000-0000-0000-0000-000000000004'),
    new Mark('00000000-0000-0000-0000-000000000010'),
  ),
  new Edge( // N - POG
    '10000000-0000-0000-0000-000000000005',
    Location.VERTICAL,
    new Mark('00000000-0000-0000-0000-000000000004'),
    new Mark('00000000-0000-0000-0000-000000000011'),
  ),
  new Edge( // AR - GO
    '10000000-0000-0000-0000-000000000006',
    Location.VERTICAL,
    new Mark('00000000-0000-0000-0000-000000000009'),
    new Mark('00000000-0000-0000-0000-000000000015'),
  ),
  new Edge( // LNOK - APOK
    '10000000-0000-0000-0000-000000000007',
    Location.VERTICAL,
    new Mark('00000000-0000-0000-0000-000000000017'),
    new Mark('00000000-0000-0000-0000-000000000018'),
  ),
  new Edge(
    '10000000-0000-0000-0000-000000000008',
    Location.VERTICAL,
    new Mark('00000000-0000-0000-0000-000000000019'),
    new Mark('00000000-0000-0000-0000-000000000020'),
  ),
  new Edge( // PRON - POG'
    '10000000-0000-0000-0000-000000000009',
    Location.VERTICAL,
    new Mark('00000000-0000-0000-0000-000000000025'),
    new Mark('00000000-0000-0000-0000-000000000026'),
  ),
  new Edge( // S - N
    '10000000-0000-0000-0000-000000000010',
    Location.HORIZONTAL,
    new Mark('00000000-0000-0000-0000-000000000003'),
    new Mark('00000000-0000-0000-0000-000000000004'),
  ),
  new Edge( // SPA - SPP
    '10000000-0000-0000-0000-000000000011',
    Location.HORIZONTAL,
    new Mark('00000000-0000-0000-0000-000000000005'),
    new Mark('00000000-0000-0000-0000-000000000006'),
  ),
  new Edge( // ME - GO
    '10000000-0000-0000-0000-000000000012',
    Location.HORIZONTAL,
    new Mark('00000000-0000-0000-0000-000000000010'),
    new Mark('00000000-0000-0000-0000-000000000015'),
  ),
  new Edge( // SPP - A
    '10000000-0000-0000-0000-000000000013',
    Location.HORIZONTAL,
    new Mark('00000000-0000-0000-0000-000000000006'),
    new Mark('00000000-0000-0000-0000-000000000008'),
  ),
  new Edge( // GOA - POG
    '10000000-0000-0000-0000-000000000014',
    Location.HORIZONTAL,
    new Mark('00000000-0000-0000-0000-000000000016'),
    new Mark('00000000-0000-0000-0000-000000000026'),
  ),
  new Edge( // VHOCP - HPOCP
    '10000000-0000-0000-0000-000000000015',
    Location.HORIZONTAL,
    new Mark('00000000-0000-0000-0000-000000000021'),
    new Mark('00000000-0000-0000-0000-000000000022'),
  ),
  new Edge( // HELPER
    '10000000-0000-0000-0000-000000000016',
    Location.HELPER,
    new Mark('00000000-0000-0000-0000-000000000015'),
    new Mark('00000000-0000-0000-0000-000000000027'),
  ),
];
