import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './features/home';
import { AnalysisComponent } from './features';
import {
  MODEL_ANALYSIS_FLG,
  FRS_ANALYSIS_FLG,
  HOME_PATH,
  LOGIN_PATH,
  MODEL_ANALYSIS_PATH,
  FRS_ANALYSIS_PATH,
  AuthGuard,
  RouteGuard,
} from './core';
import { LoginLogoutComponent, PageNotFoundComponent } from './core/components';

const routes: Routes = [
  { path: HOME_PATH, component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: MODEL_ANALYSIS_PATH,
    component: AnalysisComponent,
    data: { flg: MODEL_ANALYSIS_FLG },
    canActivate: [AuthGuard],
    canDeactivate: [RouteGuard],
    loadChildren: () =>
      // lazy loading
      import('./features/analysis/analysis-routing.module').then(
        (module) => module.AnalysisRoutingModule,
      ),
  },
  {
    path: FRS_ANALYSIS_PATH,
    component: AnalysisComponent,
    data: { flg: FRS_ANALYSIS_FLG },
    canActivate: [AuthGuard],
    canDeactivate: [RouteGuard],
  },
  { path: LOGIN_PATH, component: LoginLogoutComponent },
  { path: '', redirectTo: HOME_PATH, pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [RouteGuard],
})
export class AppRoutingModule {}
