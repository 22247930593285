import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface DeactivateGuardedComponent {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class RouteGuard implements CanDeactivate<DeactivateGuardedComponent> {
  canDeactivate(component: DeactivateGuardedComponent) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
