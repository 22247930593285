import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Jaw } from '../types';
import { SplittingService } from './splitting.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  fileState = new Subject<boolean>();
  subscription: Subscription;

  constructor(readonly splittingService: SplittingService) {
    this.fileState.next(false);
  }

  /**
   * asynchronous loading of stl files
   * @async
   * @param input blob to load stl from
   * @param onProgress Progressevent callback of the used loader
   * @returns {Promise<Mesh>}
   */
  async loadStl(input: Blob, callback: (jaw: Jaw) => void): Promise<void> {
    const processSplitResult = async (jaw: Jaw) => {
      this.subscription.unsubscribe();
      callback(jaw);
    };
    this.subscription = this.splittingService.splitFinish.subscribe(processSplitResult);
    this.splittingService.splitGeneric(input);
  }
}
