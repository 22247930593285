import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Store } from 'src/app/core';
import { DisciplineEdge, DisciplineGroup, DisplayedEdge, ValueGroups, readNameId } from '../types';
import { disciplineEdges, disciplineGroups, generateFreeFieldEdge, Edge } from '../model';

@Injectable({ providedIn: 'root' })
export class DisciplineInfoService {
  private selectedSource = new ReplaySubject<string>(1);
  private deleteEdgeSource = new Subject<string>();
  focusEventSource = new Subject<'focus' | ''>();
  currentSelection = this.selectedSource.asObservable();
  currentDeleteEdge = this.deleteEdgeSource.asObservable();

  edges = new Store<DisciplineEdge>();
  groups = new Store<DisciplineGroup>();
  groupedEdgeUpdate = new BehaviorSubject<Array<DisplayedEdge>>([]);

  private previousCount = 0;

  constructor() {
    disciplineGroups.forEach((group) => {
      this.addDisciplineGroup(group);
    });
    disciplineEdges.forEach((edge) => this.addDisciplineEdge(edge));
    this.update();
  }

  /**
   * sends the id for the currently selected Edge via ReplaySubject
   * @param id Id of DisciplineEdge object correlating to the selection
   */
  nextSelectionById(id: string): void {
    this.selectedSource.next(id);
  }

  /**
   * sends the id for the Edge thats supposed to be deleted
   * @param id UUID of the Edge
   */
  nextDeleteEdge(id: string): void {
    this.deleteEdgeSource.next(id);
  }

  /** gets discipline edge by Id
   * @returns {DisciplineEdge | undefined} DisciplineEdge | undefined
   */
  getDisciplineEdge(id: string): DisciplineEdge | undefined {
    return this.edges.getValue(id);
  }

  /**
   * Adds the edge
   * @returns {void}
   */
  addDisciplineEdge(disciplineEdge: DisciplineEdge): void {
    if (this.edges.hasOwnProperty(disciplineEdge._id)) return;
    this.edges.setValue(disciplineEdge._id, disciplineEdge);
    const group = this.groups.getValue(disciplineEdge.group);
    if (group !== undefined) {
      group.associatedEdges.setValue(readNameId(disciplineEdge), disciplineEdge._id);
      group.count++;
    }
  }

  /**
   * deletes Edge only if the map contains the id
   * @returns {void}
   */
  removeDisciplineEdge(id: string): void {
    const deleted = this.edges.delete(id);
    const group = this.groups.getValue(deleted.group);
    group.associatedEdges.delete(readNameId(deleted));
    group.count--;
  }

  addDisciplineGroup(group: DisciplineGroup): void {
    group.associatedEdges = new Store<string>();
    this.groups.setValue(group.technicalId, group);
  }

  addFreeFieldEdge(technicalId: string): void {
    const freeFieldEdge = generateFreeFieldEdge(technicalId, this.previousCount);
    this.addDisciplineEdge(freeFieldEdge);
    this.previousCount++;
  }

  /**
   * updates the Length property of the corresponding DisplayedEdge
   * @param edge the Edge to read the Length from
   * @returns {void}
   */
  updateDisciplineEdgeLength(edge: Edge): void {
    const disciplineEdge = this.getDisciplineEdge(edge._id);
    if (disciplineEdge !== undefined) {
      disciplineEdge.length = edge.getDistance();
      return;
    }
  }

  update(): void {
    const edgeGroupUpdate: DisplayedEdge[] = [];
    this.groups.toArray().forEach((group) => {
      edgeGroupUpdate.push(group);
      group.associatedEdges.toArray().forEach((edge) => {
        edgeGroupUpdate.push(this.getDisciplineEdge(edge));
      });
    });
    this.groupedEdgeUpdate.next(edgeGroupUpdate);
  }

  /**
   * resets length of all edges
   */
  resetDisplayedEdges(): void {
    this.edges.toArray().forEach((edge) => {
      if (edge.group === ValueGroups.FREEFIELD) {
        this.removeDisciplineEdge(edge._id);
        return;
      }
      if ('length' in edge) {
        edge.length = undefined;
      }
    });
    this.groups.toArray().forEach((group) => {
      if (group.technicalId !== ValueGroups.TOOTHGROUPS) {
        group.expanded = false;
      }
    });
  }
}
