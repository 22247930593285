import { Edge } from './Edge';
import { Landmark } from '../types';
import { Store } from 'src/app/core';

export class VectorGrap3d {
  _id?: string;
  landmarks = new Store<Landmark>();
  edges = new Store<Edge>();

  /**
   * Creates the Object for managing current placement of Landmarks and Edges
   * @constructor
   * @param _id for if we want to assign an id before saving
   */
  constructor(_id?: string) {
    this._id = _id;
  }

  // Edge

  /** gets Edge by Name
   * @returns {Edge | undefined}
   */
  getEdge(name: string): Edge | undefined {
    return this.edges.getValue(name);
  }

  /**
   * Adds the Edge if the Map does not contain the id
   * tries to add any not already contained Landmarks
   * @param edge the Edge to add
   * @throws Error if the id of the edge is duplicate
   * @returns {void}
   */
  addEdge(edge: Edge): void {
    if (this.edges.hasOwnProperty(edge._id)) {
      throw new Error(`already contains an edge with ${edge._id}`);
    }
    this.edges.setValue(edge._id, edge);
    edge.landmarks.forBoth((landmark) => {
      if (!this.landmarks.hasOwnProperty(landmark._id)) {
        this.landmarks.setValue(landmark._id, landmark);
      }
    });
  }

  /**
   * deletes Edge only if the store contains the id
   * @returns {void}
   */
  removeEdge(id: string): void {
    this.edges.delete(id);
  }

  // Landmark functions are ignored because they are unused

  /* istanbul ignore next*/
  /** gets Landmark by id
   * @returns {Landmark | undefined}
   */
  getLandmark(id: string): Landmark | undefined {
    return this.landmarks.getValue(id);
  }

  /* istanbul ignore next*/
  /**
   * Adds the Landmark if the Map does not contain the id otherwise it throws
   * @throws {Error} if the id of the landmark is duplicate
   * @returns {void}
   */
  addLandmark(landmark: Landmark): void {
    if (this.landmarks.hasOwnProperty(landmark._id)) {
      throw new Error('already contains this Landmark');
    }
    this.landmarks.setValue(landmark._id, landmark);
  }

  /* istanbul ignore next*/
  /**
   * deletes Landmark only if the map contains the id
   * @return {void}
   */
  removeLandmark(id: string): void {
    this.landmarks.delete(id);
  }

  /** removes all edges and landmarks */
  reset(): void {
    this.edges = new Store<Edge>();
    this.landmarks = new Store<Landmark>();
  }
}
