import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { RulerService } from './services/ruler.service';
import { ButtonService } from '../analysis/services/buttons.service';
import { SceneControlService, LoadingService, DisciplineInfoService } from './services';
import { ModelevaluationService } from './services/modelevaluation.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogService } from '../../shared/service/confirmationDialog.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HOME_PATH } from '../../core/constants';
import { DeactivateGuardedComponent } from 'src/app/core/guards/route.guard';

@Component({
  selector: 'app-analyzer3d',
  templateUrl: './analyzer3d.component.html',
  styleUrls: ['./analyzer3d.component.sass'],
})
export class Analyzer3dComponent
  implements OnInit, AfterViewInit, OnDestroy, DeactivateGuardedComponent {
  private rendererContainer: ElementRef<HTMLDivElement>;
  subscriptions: Subscription;
  renderingFinished = false;
  showWaitingSpinner = false;

  @ViewChild('rendererContainer') set content(content: ElementRef) {
    if (content) {
      this.rendererContainer = content;
      this.sceneControlService.initScene(content);
      setTimeout(() => (this.renderingFinished = true));
    }
  }

  filesUploaded = false;
  leftClicked = false;
  rightClicked = false;

  /**
   * Analyzer3dComponent constructor
   * @constructor
   */
  constructor(
    readonly sceneControlService: SceneControlService,
    readonly loader: LoadingService,
    readonly selectionService: DisciplineInfoService,
    readonly evaluationService: ModelevaluationService,
    readonly buttonService: ButtonService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    readonly rulerService: RulerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.subscriptions = this.buttonService.onSave.subscribe(() => {
      if (this.filesUploaded) {
        this.showWaitingSpinner = true;
        this.evaluationService.evaluate().finally(() => {
          this.showWaitingSpinner = false;
        });
      }
    });

    this.subscriptions.add(
      this.buttonService.onEvaluation.subscribe(() => {
        this.tempoaryNotImplementedSnackbar();
      }),
    );

    this.subscriptions.add(
      this.buttonService.onNewFile.subscribe(() => {
        if (this.filesUploaded) {
          this.confirmationDialogService.openDialog('CONFIRMATION_DIALOG.CONTENT.NEW_FILE');
          this.confirmationDialogService.navigateAwaySelection.subscribe((resetImage) => {
            if (resetImage) {
              this.reset();
            }
          });
        }
      }),
    );

    this.subscriptions.add(
      this.selectionService.focusEventSource.subscribe(() => {
        this.rendererContainer?.nativeElement?.focus();
      }),
    );

    this.subscriptions.add(
      this.selectionService.focusEventSource.subscribe(() => {
        this.rendererContainer?.nativeElement?.focus();
      }),
    );

    this.subscriptions.add(
      this.loader.fileState.subscribe((state) => (this.filesUploaded = state)),
    );
  }

  ngAfterViewInit() {
    const component = this;
    window.addEventListener('keydown', (event) => {
      component.rulerService.onKeydown(event.key);
    });
  }

  /**
   * Takes the first file in a filelist and tries loading it if it ends on stl
   * @async
   * @param files Filelist as the file to load
   * @returns {Promise<void>}
   */
  async onFileUploaded(file: File): Promise<void> {
    if (file) {
      this.loader.loadStl(file, (jaw) => {
        this.loader.fileState.next(true);
        this.sceneControlService.initObjects(jaw);
      });
    }
  }

  /**
   * openes "not implemeneted Yet" snackbar
   */
  tempoaryNotImplementedSnackbar(): void {
    let snackBarText = '';
    this.translateService.get('MSG_NOT_IMPLEMENTED_YET').subscribe((translation: string) => {
      snackBarText = translation;
    });
    this.snackBar.open(snackBarText, '', {
      duration: 4000,
      panelClass: 'dent-snackBar',
    });
  }

  /**
   * Checks if the user can navigate away
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (this.filesUploaded) {
      this.confirmationDialogService.openDialog('CONFIRMATION_DIALOG.CONTENT.NAV_BACK');
      return this.confirmationDialogService.navigateAwaySelection;
    }
    return true;
  }

  @HostListener('window:popstate', ['$event'])
  onBrowserNavigateBack(event: Event) {
    if (this.filesUploaded) {
      event.preventDefault();
      this.router.navigate([`/${HOME_PATH}`], { replaceUrl: true });
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  onAppLeave(event) {
    if (this.filesUploaded) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  /**
   * starts placement of Landmarks
   * @param event Clickevent
   */
  onPointerUp(event: MouseEvent): void {
    this.sceneControlService.placeLandmark(event.clientX, event.clientY);
  }

  /**
   * clears the scene and shows the file input
   * @returns {void}
   */
  reset(): void {
    this.sceneControlService.dispose();
    this.loader.fileState.next(false);
    this.renderingFinished = false;
  }

  /**
   * delegate centering to sceneControlService
   */
  centerCamera(): void {
    this.sceneControlService.centerCamera();
    this.rendererContainer.nativeElement.focus();
  }

  @HostListener('contextmenu', ['$event']) preventMenu(event: Event) {
    event.preventDefault();
  }

  ngOnDestroy(): void {
    this.sceneControlService.dispose();
    this.subscriptions.unsubscribe();
  }

  /**
   * adjusts the renderer size when the window size is changed
   * @returns {void}
   */
  @HostListener('window:resize', ['$event']) onWindowResize(): void {
    if (this.sceneControlService.camera && this.sceneControlService.renderer) {
      const outerContainer = document.getElementById('idContainer-modelAnalyzer');
      this.sceneControlService.camera.camera.aspect =
        outerContainer.clientWidth / outerContainer.clientHeight;
      this.sceneControlService.camera.camera.updateProjectionMatrix();
      this.sceneControlService.renderer.setSize(
        outerContainer.clientWidth,
        outerContainer.clientHeight,
      );
      this.centerCamera();
    }
  }
}
