import { Component, Inject } from '@angular/core';
import { ConfirmationDialogService } from '../service/confirmationDialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog-content',
  templateUrl: './confirmation-dialog-content.html',
  styleUrls: ['./confirmation-dialog.component.sass'],
})
export class ConfirmationDialogContentComponent {
  contentTranslationKey = '';
  constructor(
    private dialogService: ConfirmationDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.contentTranslationKey = data.contentTranslationKey;
  }

  onSelect(close: boolean) {
    this.dialogService.navigateAwaySelection.next(close);
  }
}
