import {
  Component,
  Inject,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { FileTypes } from 'src/app/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.sass'],
})
export class FileUploadComponent implements OnInit {
  @Input() accept: string;
  @Output() fileUploaded = new EventEmitter<any>();
  @Input() set fileUploadFinished(finished: boolean) {
    this.showWaitingSpinner = finished;
  }
  @ViewChild('fileDropRef', { static: false }) fileDropElement: ElementRef;

  showWaitingSpinner = false;
  explorerPreSelection: string;

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.explorerPreSelection = FileTypes[this.accept];
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event) {
    let files = event.target.files;
    this.prepareFilesList(files);
    event.target.value = null;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: FileList) {
    this.showWaitingSpinner = true;
    let file = files[0];
    if (FileTypes.acceptFileType(file, this.accept)) {
      return this.fileUploaded.emit(file);
    }
    console.error(
      `Dataformat of uploaded file is not accepted. Please use ${FileTypes[this.accept]}`,
    );
    this.showWaitingSpinner = false;

    let snackBarText = '';
    this.translateService
      .get('FILEUPLOAD.SNACKBAR', { fileType: this.accept })
      .subscribe((translation: string) => {
        snackBarText = translation;
      });

    this.snackBar.open(snackBarText, '', {
      duration: 4000,
      panelClass: 'dent-snackBar',
    });
  }
}
