import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Object3D } from 'three/src/core/Object3D';
import { Mark } from '../models/mark';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private subjectUpload = new Subject<any>();
  private subjectMarkEvent = new BehaviorSubject<any>(undefined);
  private subjectSelectedMarkFromScene = new Subject<any>();
  private subjectLandmark = new Subject<any>();
  private subjectFailedUpload = new Subject<any>();
  currentUpload = this.subjectUpload.asObservable();
  currentMarkEvent = this.subjectMarkEvent;
  selectedMarkFromScene = this.subjectSelectedMarkFromScene.asObservable();
  currentLandmark = this.subjectLandmark.asObservable();
  lastUploadFailed = this.subjectFailedUpload.asObservable();

  constructor() {}

  nextUpload(state: boolean) {
    this.subjectUpload.next(state);
  }

  nextSelectionChange(mark: Mark) {
    this.subjectMarkEvent.next(mark);
  }

  nextLandmarkEnabled() {
    this.subjectLandmark.next();
  }

  nextFailedUpload() {
    this.subjectFailedUpload.next();
  }

  nextSelectionChangeFromScene(markObject: Object3D | undefined) {
    this.subjectSelectedMarkFromScene.next(markObject);
  }
}
