import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Mark } from './models/mark';
import { EdgeService } from './services/edge.service';
import { MarkService } from './services/mark.service';
import { ThreeObjects } from './models/three.objects';
import {
  calculateMousePosition,
  getIntersectionPoint,
  checkIntersectionForMark,
} from './services/service-helper/mark.helper';
import { Observable, Subscription } from 'rxjs';
import { SceneControlService } from './services/scene-control.service';
import { Object3D } from 'three';
import { SharedService } from './services/shared.service';
import { ButtonService } from '../analysis/services/buttons.service';
import { ConfirmationDialogService } from '../../shared/service/confirmationDialog.service';
import { DeactivateGuardedComponent } from 'src/app/core/guards/route.guard';
import { AutoLogicService } from './services/auto-logic.service';
import { Router } from '@angular/router';
import { HOME_PATH } from '../../core/constants';
import { CalculationService } from './services/calculation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-analyzer2d',
  templateUrl: './analyzer2d.component.html',
  styleUrls: ['./analyzer2d.component.sass'],
})
export class Analyzer2dComponent implements OnInit, OnDestroy, DeactivateGuardedComponent {
  @ViewChild('rendererContainer') rendererContainer: ElementRef;
  imageUploaded = false;
  threeObjects: ThreeObjects;
  selectedMark: Mark;
  selectedMarkObject: Object3D;
  markObjectWithVisibleLabel: Object3D | null;
  pointerDown = false;
  pointerMove = false;
  landmarksEnabled = false;
  threeObjectsSubscription!: Subscription;
  newFileSubscription!: Subscription;
  pdfDownloadSubscription!: Subscription;
  showWaitingSpinner = false;

  /**
   * injects the service classes
   * @param {SceneControlService} sceneControlService to handle with the threeJs objects
   * @param {EdgeService} edgeService working with edges
   * @param {MarkService} markService working with marks
   * @param {sharedService} sharedService for interaction between siblings analyzer 2d and toolbar
   */
  constructor(
    private readonly sceneControlService: SceneControlService,
    public edgeService: EdgeService,
    public markService: MarkService,
    readonly sharedService: SharedService,
    readonly buttonService: ButtonService,
    private confirmationDialogService: ConfirmationDialogService,
    readonly autoLogicService: AutoLogicService,
    private router: Router,
    readonly calculationService: CalculationService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    this.newFileSubscription = buttonService.onNewFile.subscribe(() => {
      if (this.imageUploaded) {
        this.confirmationDialogService.openDialog('CONFIRMATION_DIALOG.CONTENT.NEW_FILE');
        this.confirmationDialogService.navigateAwaySelection.subscribe((resetImage) => {
          if (resetImage) {
            this.onResetImageUpload();
          }
        });
      }
    });
    this.pdfDownloadSubscription = buttonService.onSave.subscribe(() => {
      if (this.imageUploaded) {
        this.showWaitingSpinner = true;
        this.calculationService.printPdf().finally(() => {
          this.showWaitingSpinner = false;
        });
      }
    });
    sharedService.currentUpload.subscribe((state) => {
      this.imageUploaded = state;
      if (!state) {
        this.selectedMark = undefined;
      }
    });
    this.buttonService.onEvaluation.subscribe(() => {
      this.tempoaryNotImplementedSnackbar();
    });
  }

  ngOnInit(): void {
    this.threeObjectsSubscription = this.subscribeThreeObjects();
    this.sharedService.currentMarkEvent.subscribe((m) => this.onMarkSelectionChange(m));
    this.sharedService.selectedMarkFromScene.subscribe((m) => {
      if (this.markObjectWithVisibleLabel != undefined) {
        this.markObjectWithVisibleLabel.children[1].visible = false; // hide label of last selected mark object
      }

      if (m != undefined) {
        m.children[1].visible = true; // show label of selected mark from scene
      }
    });
  }

  /**
   * clears the edges and marks to set them again after renavigate to this page
   * @return {void}
   */
  ngOnDestroy(): void {
    this.edgeService.clearEdges();
    this.markService.clearMarks();
    this.calculationService.clearCalibrations();
    this.newFileSubscription.unsubscribe();
    this.pdfDownloadSubscription.unsubscribe();
  }

  /**
   * openes "not implemeneted Yet" snackbar
   */
  tempoaryNotImplementedSnackbar(): void {
    let snackBarText = '';
    this.translateService.get('MSG_NOT_IMPLEMENTED_YET').subscribe((translation: string) => {
      snackBarText = translation;
    });
    this.snackBar.open(snackBarText, '', {
      duration: 4000,
      panelClass: 'dent-snackBar',
    });
  }

  /**
   * Checks if the user can navigate away
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (this.imageUploaded) {
      this.confirmationDialogService.openDialog('CONFIRMATION_DIALOG.CONTENT.NAV_BACK');
      return this.confirmationDialogService.navigateAwaySelection;
    }
    return true;
  }

  @HostListener('window:popstate', ['$event'])
  onBrowserNavigateBack(event: Event) {
    if (this.imageUploaded) {
      event.preventDefault();
      history.pushState(null, null, window.location.pathname);
      this.router.navigate([`/${HOME_PATH}`], { replaceUrl: true });
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  onAppLeave(event) {
    if (this.imageUploaded) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  /**
   * called when a file is selected by the user
   * @param {FileList} files the file(s) selected by the user
   * @returns {void}
   */
  onFileUploaded(file: File): void {
    if (file) {
      this.sceneControlService
        .loadImageInRenderer(this.rendererContainer, file)
        .then(() => {
          this.imageUploaded = true;
          this.sharedService.nextUpload(this.imageUploaded);
          // prevent placement of landmarks if click is for moving
          this.threeObjects.controls.addEventListener('change', () => {
            this.pointerMove = true;
          });
        })
        .catch(() => {});
    }
  }

  /**
   * event to reset the image which is loaded
   * @returns {void}
   */
  onResetImageUpload(): void {
    // clear Scene and other threeJs Objects
    this.sceneControlService.clearScene();
    // set imageUpload to false
    this.imageUploaded = false;
    this.sharedService.nextUpload(this.imageUploaded);
    // clear the edges, marks and calculations
    this.edgeService.clearEdges();
    this.markService.clearMarks();
    this.calculationService.clearCalibrations();
    // Clears an already existing canvas as a child of the renderer container
    // Without that, the renderer container would have more than
    // one canvas and then it would showing only a black screen
    const children = this.rendererContainer.nativeElement.childNodes;
    for (let child of children) {
      this.rendererContainer.nativeElement.removeChild(child);
    }
  }

  /**
   * the event for the pointerclick down
   * @param {MouseEvent} evt
   * @returns {void}
   */
  onPointerDown(evt: MouseEvent): void {
    this.pointerDown = true;
    this.pointerMove = false;

    const mousePosition = calculateMousePosition(
      evt.clientX,
      evt.clientY,
      this.threeObjects.renderer.domElement,
    );

    // get the selected mark on the scene
    this.selectedMarkObject = checkIntersectionForMark(
      mousePosition,
      this.threeObjects.camera,
      this.threeObjects.scene,
    );

    if (this.selectedMarkObject != undefined) {
      this.sharedService.nextSelectionChangeFromScene(this.selectedMarkObject);
      this.markObjectWithVisibleLabel = this.selectedMarkObject;

      const movingMark = this.markService.getMarkByMarkId(this.selectedMarkObject.uuid);
      const partnerMarks = this.edgeService.findPartnerMarks(movingMark);
      if (partnerMarks != undefined) {
        partnerMarks.forEach((partnerMark) => {
          const edge = this.edgeService.getEdge(movingMark, partnerMark);
          this.edgeService.hideEdge(edge);
        });
      }

      // hide all the edges
      // this.edgeService.hideEdges();
    }
  }

  /**
   * the event for the pointermove
   * @param {MouseEvent} evt
   * @returns {void}
   */
  onPointerMove(evt: MouseEvent): void {
    if (this.pointerDown && this.selectedMarkObject != undefined) {
      // enable the controls to move a mark (and not the image)
      this.threeObjects.controls.enabled = false;

      const mousePosition = calculateMousePosition(
        evt.clientX,
        evt.clientY,
        this.threeObjects.renderer.domElement,
      );

      let position = getIntersectionPoint(
        mousePosition,
        this.threeObjects.camera,
        this.threeObjects.scene,
      );

      if (position != undefined) {
        this.selectedMarkObject.position.set(position.x, position.y, 0.002);
        this.pointerMove = true;
      }
    }
  }

  /**
   * the event for the pointerclick up
   * @param {MouseEvent} evt
   * @returns {void}
   */
  onPointerUp(evt: MouseEvent): void {
    this.pointerDown = false;

    if (!this.imageUploaded) return;

    if (this.selectedMark != undefined && !this.selectedMark.set && !this.pointerMove) {
      this.drawMark(evt.clientX, evt.clientY);
    }

    if (this.selectedMarkObject != undefined) {
      this.moveMark();
    }

    this.pointerMove = false;
    this.threeObjects.controls.enabled = true;
  }

  drawMark(x: number, y: number) {
    const mousePosition = calculateMousePosition(x, y, this.threeObjects.renderer.domElement);

    const position = getIntersectionPoint(
      mousePosition,
      this.threeObjects.camera,
      this.threeObjects.scene,
    );

    if (position != undefined) {
      const mark = this.selectedMark.copy();
      // draw the mark and set it
      this.markService.drawMark(mark, position, false);
      // update mark in the array
      const newMark = this.markService.setMarkInMarks(mark, position);
      // set the mark in the edge array
      this.edgeService.setMarkInEdges(newMark);
      // find the second mark to draw the edge
      const partnerMarks = this.edgeService.findPartnerMarks(newMark);

      if (partnerMarks != undefined) {
        partnerMarks.forEach((partnerMark) => {
          // get the corresponding edge
          const edge = this.edgeService.getEdge(newMark, partnerMark);
          // draw the edge
          this.edgeService.drawEdge(edge);
          // set the edge in the edge array
          this.edgeService.setEdgeInEdges(edge);
        });
      }
      const landmarksEnabled = this.markService.checkCalibrationSet();
      if (landmarksEnabled) {
        this.sharedService.nextLandmarkEnabled();
      }
      this.selectedMark = newMark;

      this.calculationService.evaluate();
      if (this.autoLogicService.isRelatedToSp(mark)) {
        this.autoLogicService.runSpRoutine();
      }
      if (this.autoLogicService.isRelatedToGo(mark)) {
        this.autoLogicService.runGoRoutine();
      }
      if (this.autoLogicService.isRelatedToVpocp(mark)) {
        this.autoLogicService.runVpocpRoutine();
      }
    }
  }

  /**
   * the logic for moving a mark after pointer up
   * @returns {void}
   */
  moveMark(): void {
    const markId = this.selectedMarkObject.uuid;
    const markPosition = this.selectedMarkObject.position;

    // get mark by markid
    const mark = this.markService.getMarkByMarkId(markId);
    // update mark in the array
    const newMark = this.markService.setMarkInMarks(mark, markPosition);
    // update mark in the array
    this.edgeService.setMarkInEdges(newMark);
    // remove the correspondig edge from the scene
    this.edgeService.removeEdgeByMark(newMark);

    // fade in all the othe edges
    // this.edgeService.showEdges();

    // get the mark from the correspondig partnermark
    const partnerMarks = this.edgeService.findPartnerMarks(newMark);

    if (partnerMarks != undefined) {
      partnerMarks.forEach((partnerMark) => {
        // get the corresponding edge
        const edge = this.edgeService.getEdge(newMark, partnerMark);
        // draw the edge
        this.edgeService.drawEdge(edge);
        // set the edge in the edge array
        this.edgeService.setEdgeInEdges(edge);
      });
    }
    this.calculationService.evaluate();
    if (this.autoLogicService.isRelatedToSp(mark)) {
      this.autoLogicService.runSpRoutine();
    }
    if (this.autoLogicService.isRelatedToGo(mark)) {
      this.autoLogicService.runGoRoutine();
    }
    if (this.autoLogicService.isRelatedToVpocp(mark)) {
      this.autoLogicService.runVpocpRoutine();
    }
  }

  /**
   * adjusts the renderer size when the window size is changed
   * @param {any} evt
   * @returns {void}
   */
  @HostListener('window:resize', ['$event']) onWindowResize(evt): void {
    if (this.threeObjects != null) {
      const outerContainer = document.getElementById('outerContainer');
      this.threeObjects.camera.aspect = outerContainer.clientWidth / outerContainer.clientHeight;
      this.threeObjects.camera.updateProjectionMatrix();
      this.threeObjects.renderer.setSize(outerContainer.clientWidth, outerContainer.clientHeight);
    }
  }

  /**
   * sets the selected mark from the sidebar to work with it
   * @param {Mark} mark from the selected button
   * @returns {void}
   */
  onMarkSelectionChange(mark: Mark): void {
    // hide the current mark label
    if (this.selectedMark != undefined) {
      const markObject = this.threeObjects.scene.getObjectByProperty('uuid', this.selectedMark.id);
      if (markObject != undefined) {
        markObject.children[1].visible = false;
      }
    }

    this.selectedMark = mark;

    // show the new mark label
    if (this.selectedMark != undefined) {
      const markObject = this.threeObjects.scene.getObjectByProperty('uuid', this.selectedMark.id);
      if (markObject != undefined) {
        markObject.children[1].visible = true;
      }
    }
  }

  /**
   * subscribe to threeObjects in order to be notified everytime it is updated
   * @returns {Subscription}
   */
  subscribeThreeObjects(): Subscription {
    const next = (updatedThreeObjects: ThreeObjects) => {
      this.threeObjects = updatedThreeObjects;
    };
    return this.sceneControlService.threeObjects.subscribe(next);
  }

  /**
   * sets the point if the curser leaves the canvas
   * @param {MouseEvent} evt
   * @returns {void}
   */
  onPointerOut(evt: MouseEvent): void {
    if (this.pointerDown && this.selectedMarkObject != undefined) {
      this.pointerDown = false;
      this.selectedMarkObject = undefined;
    }
  }
}
