<div class="sidebar-container">
  <!-- PUNKTE -->
  <div *ngIf="activeTable == 'ID_LANDMARK'">
    <table
      mat-table
      [dataSource]="markService.marks"
      multiTemplateDataRows
      [ngClass]="imageUploaded ? 'dent-enabled' : 'dent-disabled'"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="set">
        <th class="mark-col-1" mat-header-cell *matHeaderCellDef>
          {{ 'TOOLBAR.COL1' | translate }}
        </th>
        <td
          class="mark-col-1"
          mat-cell
          *matCellDef="let mark"
          [ngClass]="{ 'selected-row': imageUploaded && selectedMark == mark }"
          (click)="!imageUploaded ? $event.stopPropagation() : onMarkSelectionChangePublish(mark)"
        >
          <mat-icon color="primary" *ngIf="mark.set"> check_circle </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th class="mark-col-2" mat-header-cell *matHeaderCellDef>
          {{ 'TOOLBAR.COL2' | translate }}
        </th>
        <td
          class="mark-col-2"
          mat-cell
          *matCellDef="let mark"
          [ngClass]="{ 'selected-row': imageUploaded && selectedMark == mark }"
          (click)="!imageUploaded ? $event.stopPropagation() : onMarkSelectionChangePublish(mark)"
        >
          ({{ 'MARK.' + mark.id + '.SHORTFORM' | translate }}) -
          {{ 'MARK.' + mark.id + '.NAME' | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reset">
        <th class="mark-col-3" mat-header-cell *matHeaderCellDef>
          {{ 'TOOLBAR.COL3' | translate }}
        </th>
        <td
          class="mark-col-3"
          mat-cell
          *matCellDef="let mark"
          [ngClass]="{ 'selected-row': imageUploaded && selectedMark == mark }"
          (click)="!imageUploaded ? $event.stopPropagation() : onMarkSelectionChangePublish(mark)"
        >
          <button
            color="primary"
            mat-icon-button
            [disabled]="!mark.set"
            [style.opacity]="!mark.set || autoLogicService.isAutoMark(mark) ? 0.3 : 1"
            (click)="onResetMark(mark)"
          >
            <mat-icon> delete </mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let mark" [attr.colspan]="markColumns.length">
          <div
            class="mark-detail"
            [@detailExpand]="mark == expandedMark ? 'expanded' : 'collapsed'"
          >
            <div class="mark-image"><img [src]="mark.imgUri" [alt]="mark.name" /></div>
            <div class="mark-description">
              {{ 'MARK.' + mark.id + '.DESC' | translate }}
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="markColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let mark; columns: markColumns"
        [ngClass]="{ 'selected-row': mark === selectedMark && imageUploaded }"
        [hidden]="checkHelperGroup(mark)"
        (click)="
          !imageUploaded ? $event.stopPropagation() : onMarkSelectionChangePublish(mark);
          expandedMark = expandedMark === mark ? null : mark
        "
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mark-detail-row"></tr>
    </table>
  </div>
  <!-- EBENEN -->
  <div *ngIf="activeTable == 'ID_LEVELS'" class="dent-toolbar-levels">
    <table
      mat-table
      [dataSource]="edgeService.edges"
      multiTemplateDataRows
      [ngClass]="imageUploaded ? 'dent-enabled' : 'dent-disabled'"
    >
      <ng-container matColumnDef="levelSet">
        <th class="edge-col-1" mat-header-cell *matHeaderCellDef>
          {{ 'TOOLBAR.COL1' | translate }}
        </th>
        <td
          class="edge-col-1"
          mat-cell
          *matCellDef="let edge"
          [ngClass]="{ 'selected-row': imageUploaded && selectedEdge == edge }"
        >
          <mat-icon color="primary" *ngIf="edge.set"> check_circle </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th class="edge-col-2" mat-header-cell *matHeaderCellDef>
          {{ 'TOOLBAR.COL2' | translate }}
        </th>
        <td
          class="edge-col-2"
          mat-cell
          *matCellDef="let edge"
          [ngClass]="{ 'selected-row': imageUploaded && selectedEdge == edge }"
        >
          ({{ 'EDGES.' + edge.id + '.SHORTFORM' | translate }}) <br />
          {{ 'EDGES.' + edge.id + '.NAME' | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="visibility">
        <th class="edge-col-3" mat-header-cell *matHeaderCellDef>
          {{ 'TOOLBAR.EDGE_COL3' | translate }}
        </th>
        <td
          class="edge-col-3"
          mat-cell
          *matCellDef="let edge"
          [ngClass]="{ 'selected-row': imageUploaded && selectedEdge == edge }"
        >
          <button
            mat-icon-button
            [disabled]="!edge.set"
            [style.opacity]="!edge.set ? 0.3 : 1"
            (click)="onChangeEdgeVisibility(edge)"
          >
            <mat-icon *ngIf="edge.visible" color="primary"> visibility </mat-icon>
            <mat-icon *ngIf="!edge.visible"> visibility_off </mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="edgeColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let edge; columns: edgeColumns"
        [ngClass]="{ 'selected-row': imageUploaded && edge === selectedEdge }"
        (click)="(selectedEdge === edge); selectedEdge = selectedEdge === edge ? null : edge"
      ></tr>
    </table>
  </div>
  <!-- calculation -->
  <div *ngIf="activeTable == 'ID_CALCULATIONS'">
    <table
      mat-table
      [dataSource]="calculationService.calculations"
      multiTemplateDataRows
      [ngClass]="imageUploaded ? 'dent-enabled' : 'dent-disabled'"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="name">
        <th class="calculation-col-1" mat-header-cell *matHeaderCellDef>
          {{ 'TOOLBAR.COL2' | translate }}
        </th>
        <td
          class="calculation-col-1"
          mat-cell
          *matCellDef="let calculation"
          [ngClass]="{ 'selected-row': imageUploaded && selectedCalculation == calculation }"
        >
          {{ 'CALCULATION.' + calculation.id + '.SHORTFORM' | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actual">
        <th class="calculation-col-2" mat-header-cell *matHeaderCellDef>
          {{ 'TOOLBAR.CALCULATION_COL2' | translate }}
        </th>
        <td
          class="calculation-col-2"
          mat-cell
          *matCellDef="let calculation"
          [ngClass]="{ 'selected-row': imageUploaded && selectedCalculation == calculation }"
        >
          {{ getActualValue(calculation) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="target">
        <th class="calculation-col-3" mat-header-cell *matHeaderCellDef>
          {{ 'TOOLBAR.CALCULATION_COL3' | translate }}
        </th>
        <td
          class="calculation-col-3"
          mat-cell
          *matCellDef="let calculation"
          [ngClass]="{ 'selected-row': imageUploaded && selectedCalculation == calculation }"
        >
          {{ getTargetValue(calculation) }}
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetailAngle">
        <td mat-cell *matCellDef="let calculation" [attr.colspan]="calculationColumns.length">
          <div
            class="calculation-detail"
            [@detailExpand]="
              imageUploaded && calculation == expandedCalculation ? 'expanded' : 'collapsed'
            "
          >
            <div class="calculation-name">
              {{ 'CALCULATION.' + calculation.id + '.DESCRIPTION' | translate }}
              <p>
                <span class='interpretation-operator'>=</span>
                {{ 'CALCULATION.' + calculation.id + '.OPTIMALINTERPREATION' | translate }} <br />
                <span class='interpretation-operator'><</span>
                {{ 'CALCULATION.' + calculation.id + '.SMALLERINTERPREATION' | translate }} <br />
                <span class='interpretation-operator'>></span>
                {{ 'CALCULATION.' + calculation.id + '.LARGERINTERPREATION' | translate }}
              </p>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="calculationColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let calculation; columns: calculationColumns"
        [ngClass]="{ 'selected-row': imageUploaded && calculation === selectedCalculation }"
        (click)="
          !imageUploaded
            ? $event.stopPropagation()
            : onCalculationSelectionChangePublish(calculation);
          expandedCalculation = expandedCalculation === calculation ? null : calculation
        "
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetailAngle']"
        class="mark-detail-row"
      ></tr>
    </table>
  </div>
</div>
