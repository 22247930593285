import { Tuple2 } from 'src/app/core';
import { CylinderBufferGeometry, Mesh, MeshPhongMaterial } from 'three';
import { calculateMiddlePoint, setPosition, setRotation } from '../services/helper.service';
import { Landmark } from '../types';

export class Edge {
  _id: string;
  name: string;
  landmarks: Landmarks;

  /**
   * Constructor to create the currently placed Edges
   * @constructor
   * @param landmarks tuple of two Landmarks
   * @param options for optional id and name of the Edge
   */
  constructor(_id: string, landmarks: [Landmark, Landmark], name: string) {
    this.landmarks = new Landmarks(landmarks[0], landmarks[1]);
    this._id = _id;
    this.name = name;
  }

  /**
   * Returns the Edge information as Three.js Cylinder
   * @param material material to render the line with default is black
   * @throws Error if it fails to create a Cylinder with three.js
   * @returns {Mesh<CylinderBufferGeometry>}
   */
  toCylinder(material?: MeshPhongMaterial): Mesh<CylinderBufferGeometry> {
    const usedMaterial = material === undefined ? cylinderMaterial : material;
    const landmarkvectors = this.landmarks.forBoth((landmark) => {
      return landmark.pointvector.clone();
    });
    const geometry = new CylinderBufferGeometry(0.1, 0.1, this.getDistance(), 16);
    let cylinder!: Mesh<CylinderBufferGeometry>;
    try {
      cylinder = new Mesh(geometry, usedMaterial);
      setPosition(cylinder, calculateMiddlePoint(landmarkvectors.one, landmarkvectors.two));
      setRotation(cylinder, landmarkvectors);
    } catch (err) {
      // Logging ?
      throw new Error(`failed to create Line: error=${err?.message}`);
    }
    cylinder.name = this.name;
    return cylinder;
  }

  /**
   * calculates distance between contained Landmarks
   * @returns {number}
   */
  getDistance(): number {
    return this.landmarks.one.pointvector.distanceTo(this.landmarks.two.pointvector);
  }
}

const cylinderMaterial = new MeshPhongMaterial({ color: 0x003252 });

class Landmarks extends Tuple2<Landmark> {}
