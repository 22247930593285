import { Component, OnInit } from '@angular/core';

import { MODEL_ANALYSIS_PATH, FRS_ANALYSIS_PATH } from '../../core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  frsAnalysisPath: string = FRS_ANALYSIS_PATH;
  modelAnalysisPath: string = MODEL_ANALYSIS_PATH;
  constructor() {}

  ngOnInit(): void {}
}
