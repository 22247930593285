import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';

import { Analyzer3dComponent } from './analyzer3d.component';
import { FileUploadModule } from '../file-upload';
import { ModelSidebarComponent } from './components/model-sidebar';
import { SharedModule } from '../../shared';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatSortModule } from '@angular/material/sort';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RulerComponent } from './components/ruler/ruler.component';

@NgModule({
  declarations: [Analyzer3dComponent, ModelSidebarComponent, RulerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    FlexLayoutModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [Analyzer3dComponent, ModelSidebarComponent],
})
export class Analyzer3dModule {}
