<div *ngIf="!hasFoundEasteregg; else showEasteregg">
  <div class="page-not-found" fxLayout="column">
    <div fxLayout="row" fxFlex="20" class="gif-container">
      <img class="gif" src="assets/gif/confused_travolta.gif" />
      <div class="easteregg-button" (click)="onEastereggFound()"></div>
    </div>
    <div class="container">
      <h1 class="text">404</h1>
      <p class="text">{{ 'PAGENOTFOUND.TEXT' | translate }}</p>
      <button mat-raised-button routerLink="/home">
        {{ 'PAGENOTFOUND.HOME_BUTTON' | translate }}
      </button>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>

<!-- Easteregg -->
<ng-template #showEasteregg>
  <div class="easteregg-container">
    <iframe
      class="easteregg"
      src="https://www.youtube.com/embed/G1IbRujko-A?autoplay=1&mute=0&controls=0&loop=1"
      allow="autoplay"
    ></iframe>
  </div>
</ng-template>
