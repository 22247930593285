import { GridGapDirective } from '@angular/flex-layout/grid/typings/gap/gap';
import { CircleGeometry } from 'three';
import { Mark } from '../../models/mark';
import {
  Camera,
  Mesh,
  MeshBasicMaterial,
  Raycaster,
  Scene,
  Vector2,
  Vector3,
  Object3D,
} from 'three';

/**
 * create a Mesh Object in shape of a crosshair and scale it down
 * @returns Mesh
 */
export const getMarkMesh = (isAutoMark: boolean): Mesh => {
  const geometry = new CircleGeometry(2, 32);
  const material = new MeshBasicMaterial({
    color: isAutoMark ? '#81acc2' : '#f3c456',
    transparent: true,
  });
  const markShape = new Mesh(geometry, material);
  markShape.name = 'mark';

  markShape.scale.set(0.00175, 0.00175, 0.00175);

  // when this is set, it checks every frame if the object is in the frustum of the camera
  // before rendering the object.Otherwise the object gets rendered every frame even if it isn't visible
  markShape.frustumCulled = false;

  return markShape;
};

/**
 * returns a circle shaped Mesh for better grip at marks
 */
export const getMarkGripMesh = (): Mesh => {
  const geometry = new CircleGeometry(10, 32);
  const material = new MeshBasicMaterial();
  const grip = new Mesh(geometry, material);
  grip.name = 'grip';
  grip.frustumCulled = false;
  grip.visible = false;
  return grip;
};

/**
 * returns the center of the bounding Box
 * @param mesh
 */
export const getCenter = (mesh: Mesh): Vector3 => {
  const geometry = mesh.geometry;
  geometry.computeBoundingBox();
  const localCenter = new Vector3();
  geometry.boundingBox.getCenter(localCenter);
  return mesh.localToWorld(localCenter);
};

/**
 * fet the intersection from the mouseclick on the sprite
 * @param {Vector2} mousePosition the clicked mouseposition
 * @param {Camera} camera the perspective camera
 * @param {Scene} scene the scene
 * @returns {Vector3} the correct coordinates in a 3D Vector
 */
export const getIntersectionPoint = (
  mousePosition: Vector2,
  camera: Camera,
  scene: Scene,
): Vector3 => {
  if (mousePosition == null || camera == null || scene == null) {
    throw new Error('The mousePosition, the camera or the scene is null!');
  }

  var rayCaster = new Raycaster();
  rayCaster.setFromCamera(mousePosition, camera);
  var intersects = rayCaster.intersectObject(scene.getObjectByName('sprite'));

  if (intersects.length > 0) {
    intersects[0].point.z = 0;
    return intersects[0].point;
  }
};

/**
 * convert the client mouseposition to the canvas mouseposition
 * @param {number} x the x-position from the client mouseclick
 * @param {number} y the y-position from the client mouseclick
 * @param {HTMLCanvasElement} canvas the canvas DOM Element
 * @returns {Vector2} the correct mouseposition as a 2D Vector
 */
export const calculateMousePosition = (
  x: number,
  y: number,
  canvas: HTMLCanvasElement,
): Vector2 => {
  // check whether the canvas is not null
  if (canvas == null) {
    throw new Error('The canvas is null!');
  }

  // get position from canvas for correct mousePosition
  const canvasPosition = canvas.getBoundingClientRect();

  // check whether the width or height is 0. Protection for a DividedByZero Expection!
  if (canvasPosition.width == 0 || canvasPosition.height == 0) {
    throw new Error('The canvas width or height is 0!');
  }

  const mouse = new Vector2();
  mouse.x = ((x - canvasPosition.left) / canvasPosition.width) * 2 - 1;
  mouse.y = -((y - canvasPosition.top) / canvasPosition.height) * 2 + 1;

  return mouse;
};

/**
 * checks the intersection for a mark and return the mark threeJs object
 * @param {Vector2} mousePosition the clicked mouseposition
 * @param {Camera} camera the perspective camera
 * @param {Scene} scene the scene
 * @returns {Obejct3D} the threeJs mark object
 */
export const checkIntersectionForMark = (
  mousePosition: Vector2,
  camera: Camera,
  scene: Scene,
): Object3D => {
  if (mousePosition == null || camera == null || scene == null) {
    throw new Error('The mousePosition, the camera or the scene is null!');
  }

  var rayCaster = new Raycaster();
  rayCaster.setFromCamera(mousePosition, camera);

  var markChildren = new Array<Object3D>();
  for (let child of scene.children) {
    if (child.name == 'mark') {
      markChildren.push(child);
    }
  }

  var intersects = [];
  markChildren.forEach((mark) => {
    const hits = rayCaster.intersectObject(mark, true);
    if (hits.length > 0) {
      if (hits[0].object.name == 'grip') {
        intersects.push(hits[0].object.parent);
      }
    }
  });

  if (intersects.length > 0) {
    return intersects[0];
  }
};

/**
 * Calculate the direction vector for two marks
 * @param {Mark|Vector3} mark1 mark1 or the vector
 * @param {Mark|Vector3} mark2 mark2 or the vector
 * @returns {Vector3} direction vector
 */
export const calcDirectionVector = (mark1: Mark | Vector3, mark2: Mark | Vector3): Vector3 => {
  const vec1: Vector3 = mark1 instanceof Mark ? mark1.position : mark1;
  const vec2: Vector3 = mark2 instanceof Mark ? mark2.position : mark2;

  return new Vector3(vec2.x - vec1.x, vec2.y - vec1.y, vec2.z - vec1.z);
};
