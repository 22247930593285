import { Component, OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { MarkService } from '../services/mark.service';
import { Group, Mark } from '../models/mark';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { EdgeService } from '../services/edge.service';
import { ButtonService } from '../../analysis/services/buttons.service';
import { Edge, Location } from '../models/edge';
import { CalculationService } from '../services/calculation.service';
import { Calculation, Gender } from '../models/calculation';
import { AutoLogicService } from '../services/auto-logic.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ToolbarComponent implements OnInit {
  imageUploaded = false;
  landmarkEnabled = false;
  markColumns = ['set', 'name', 'reset'];
  expandedMark: Mark | null;
  selectedMark: Mark | null;
  selectedEdge: Edge | null;
  expandedCalculation: Calculation | null;
  selectedCalculation: Calculation | null;
  edgeColumns = ['levelSet', 'name', 'visibility'];
  calculationColumns = ['name', 'actual', 'target'];
  activeTable = 'ID_LANDMARK';

  constructor(
    private sharedService: SharedService,
    public markService: MarkService,
    public edgeService: EdgeService,
    public calculationService: CalculationService,
    private buttonService: ButtonService,
    readonly autoLogicService: AutoLogicService,
  ) {
    this.sharedService.currentUpload.subscribe((state) => {
      this.imageUploaded = state;
      if (!state) {
        this.expandedMark = null;
        this.selectedMark = null;
      }
    });
    this.sharedService.currentLandmark.subscribe(() => (this.landmarkEnabled = true));
    this.buttonService.onLevels.subscribe((key) => (this.activeTable = key));
    this.buttonService.onLandmark.subscribe((key) => (this.activeTable = key));
    this.buttonService.onCalculations.subscribe((key) => (this.activeTable = key));
  }

  ngOnInit(): void {
    this.sharedService.selectedMarkFromScene.subscribe((m) => {
      if (m != undefined) {
        var selectedMarkFromScene = this.markService.getMarkByMarkId(m.uuid);
        this.onMarkSelectionChangePublish(selectedMarkFromScene);
        this.expandedMark = selectedMarkFromScene;
      }
    });
  }

  /**
   * get mapped target value based on gender
   * @param Calculation calculation (angle) to get the target value from
   * @returns {string} target value of male and female
   */
  getTargetValue(calculation: Calculation): string {
    if (calculation.targetValue === undefined) {
      return `- ± ${calculation.deviation.toString()}${calculation.unit}`;
    } else if (typeof calculation.targetValue === 'number' && calculation.id.endsWith('5')) {
      let targetValue = `${calculation.targetValue.toFixed(1)}`;
      targetValue += `${calculation.unit}`;
      targetValue += ` ± ${calculation.deviation.toString()}`;
      targetValue += `${calculation.unit}`;

      return targetValue;
    } else if (typeof calculation.targetValue === 'number') {
      let targetValue = `${calculation.targetValue.toFixed(0)}`;
      targetValue += `${calculation.unit}`;
      targetValue += ` ± ${calculation.deviation.toString()}`;
      targetValue += `${calculation.unit}`;

      return targetValue;
    } else {
      const map: Map<Gender, number> = calculation.targetValue as Map<Gender, number>;

      let targetValue = `M: ${map.get(Gender.M)}${calculation.unit}`;
      targetValue += ` ± ${calculation.deviation.toString()}`;
      targetValue += `${calculation.unit}\n`;
      targetValue += `F: ${map.get(Gender.F)}${calculation.unit}`;
      targetValue += ` ± ${calculation.deviation.toString()}${calculation.unit}`;

      return targetValue;
    }
  }

  /**
   * get mapped actual value
   * @param Calculation calculation (angle) to get the actual value from
   * @returns {string} actual value
   */
  getActualValue(calculation: Calculation): string {
    return calculation.actualValue === undefined
      ? '-'
      : `${calculation.actualValue?.toFixed(1)}${calculation.unit}`;
  }

  /**
   * check the group | location of the mark | edge and return true if the group | location  equals HELPER
   * this funciton for hiding the helper marks | edges in the toolbar
   * @param {Mark | Edge} item the mark or edge which have to be checked
   * @returns {boolean} true = hide; false = show
   */
  checkHelperGroup(item: Mark | Edge): boolean {
    if (item instanceof Mark) return item.group == Group.HELPER ? true : false;
    if (item instanceof Edge) return item.location == Location.HELPER ? true : false;
  }

  onMarkSelectionChangePublish(mark: Mark) {
    this.selectedMark = mark;
    this.sharedService.nextSelectionChange(mark);
  }

  onCalculationSelectionChangePublish(calculation: Calculation) {
    this.selectedCalculation = calculation;
  }

  /**
   * resets the mark
   * @param {Mark} mark the selected mark from to toolbar
   * @returns {void}
   */
  onResetMark(mark: Mark): void {
    if (!mark.set) {
      return;
    }

    this.markService.unsetMarkInMarks(mark);
    this.edgeService.unsetMarkInEdges(mark);

    if (this.autoLogicService.isRelatedToSp(mark)) {
      this.autoLogicService.runSpRoutine();
    }
    if (this.autoLogicService.isRelatedToGo(mark)) {
      this.autoLogicService.runGoRoutine();
    }
    if (this.autoLogicService.isRelatedToVpocp(mark)) {
      this.autoLogicService.runVpocpRoutine();
    }
  }

  /**
   * change visibility of edge
   * @param edge edge to change the visibility
   */
  onChangeEdgeVisibility(edge: Edge): void {
    if (edge.visible) {
      this.edgeService.hideEdge(edge);
    } else {
      this.edgeService.fadeInEdge(edge);
    }

    if (edge.mark1.group === Group.HELPER) {
      this.markService.invertMarkVisibility(edge.mark1);
    } else if (edge.mark2.group === Group.HELPER) {
      this.markService.invertMarkVisibility(edge.mark2);
    }
  }
}
