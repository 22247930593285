import { Sprite, SpriteMaterial, Texture } from 'three';

export const createLabel = (name): Sprite => {
  name = ' ' + name + ' ';

  const fontface = 'Arial';
  const fontsize = 16;
  const borderThickness = 2;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = 'Bold ' + fontsize + 'px ' + fontface;

  // get size data (height depends only on font size)
  const metrics = context.measureText(name);
  const textWidth = metrics.width;

  // background color
  context.fillStyle = '#ffffff';
  // border color
  context.strokeStyle = '#004a6f';

  context.lineWidth = borderThickness;

  roundRect(
    context,
    borderThickness / 2,
    borderThickness / 2,
    textWidth + borderThickness,
    fontsize * 1.4 + borderThickness, // 1.4 is extra height factor for text below baseline
    6,
  );

  // text color
  context.fillStyle = '#000000';

  context.fillText(name, borderThickness, fontsize + borderThickness);

  // canvas contents will be used for a texture
  const texture = new Texture(canvas);
  texture.needsUpdate = true;

  const spriteMaterial = new SpriteMaterial({
    map: texture,
  });
  const label = new Sprite(spriteMaterial);
  label.frustumCulled = false;
  label.name = 'label';

  label.scale.set(250, 125, 1.0);

  return label;
};

const roundRect = (ctx, x, y, w, h, r) => {
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.lineTo(x + w - r, y);
  ctx.quadraticCurveTo(x + w, y, x + w, y + r);
  ctx.lineTo(x + w, y + h - r);
  ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
  ctx.lineTo(x + r, y + h);
  ctx.quadraticCurveTo(x, y + h, x, y + h - r);
  ctx.lineTo(x, y + r);
  ctx.quadraticCurveTo(x, y, x + r, y);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
};
