import { Injectable } from '@angular/core';
import { Mark } from '../models/mark';
import { Edge } from '../models/edge';
import { marks } from '../data/marks';
import { edges } from '../data/edges';
import { Calculation } from '../models/calculation';
import { calculation } from '../data/calculation';

@Injectable({
  providedIn: 'root',
})
/**
 * a service that loads all Marks, Edges and Calculations to be used in the analysis
 */
export class StaticDataService {
  constructor() {}

  /**
   * @returns {Array<Mark>} the marks to be used in the analysis is loaded from a static source
   */
  getMarks(): Array<Mark> {
    return marks;
  }

  /**
   * @returns {Array<Edge>} the edges to be used in the analysis is loaded from a static source
   */
  getEdges(): Array<Edge> {
    return edges;
  }

  /**
   * @returns {Array<Calculation>} the calculations to be used in the analysis is loaded from a static source
   */
  getCalculation(): Array<Calculation> {
    return calculation;
  }
}
