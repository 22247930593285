import { Tuple2, execForTwo } from 'src/app/core';
import { BufferGeometry, Mesh, MeshPhongMaterial } from 'three';
import { getCenter } from '../services/helper.service';

export type JawTuple = [Mesh | undefined, Mesh | undefined];

export class Jaw {
  upper: Mesh | undefined;
  lower: Mesh | undefined;
  ascii?: true;

  constructor(upper: BufferGeometry | undefined, lower: BufferGeometry | undefined) {
    this.upper = toJawMesh(upper);
    this.lower = toJawMesh(lower);
    this.sort();
  }

  forBoth<U>(func: (element: Mesh | undefined) => U): Tuple2<U> {
    return execForTwo(this, ['upper', 'lower'], func);
  }

  /**
   * sorts the meshes into upper and lower jaw
   */
  sort(): void {
    if (this.upper === undefined) {
      const { geometry } = this.lower;
      geometry.computeBoundingBox();
      const { min, max } = geometry.boundingBox;

      if (max.y > 21 && max.y < 27 && !(min.y > -27 && min.y < -21)) {
        // guess that there is only upper jaw
        this.swap();
      }
      return;
    }
    if (this.lower === undefined) {
      const { geometry } = this.upper;
      geometry.computeBoundingBox();
      const { min, max } = geometry.boundingBox;

      if (min.y > -27 && min.y < -21 && !(max.y > 21 && max.y < 27)) {
        // guess that there is only lower jaw
        this.swap();
      }
      return;
    }
    const centerA = getCenter(this.upper);
    const centerB = getCenter(this.lower);
    if (centerA.y < centerB.y) this.swap();
    return;
  }

  swap(): void {
    const part = this.upper;
    this.upper = this.lower;
    this.lower = part;
  }
}

/**
 * converts a geometry to a mesh with the material for the jawObjects
 * @param geometry
 */
export const toJawMesh = (geometry: BufferGeometry | undefined): Mesh | undefined => {
  if (geometry === undefined) {
    return;
  }
  return new Mesh(geometry, jawMaterial);
};

const jawMaterial = new MeshPhongMaterial({
  color: 0xcccccc,
  opacity: 0.8,
  transparent: true,
});
